import React, { useEffect, useState } from 'react';
import MapWithMarker from '../../../common/MapWithMarker';
import HelperService from '../../../../services/HelperService';

interface IProp {
  geoString: string;
}
function LocationsEditorMap({ geoString }: IProp) {
  const [zoom, setZoom] = useState<number>(14);
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lon: number;
  } | null>(null);

  useEffect(() => {
    if (HelperService.isGeoStringValid(geoString, false)) {
      const geoObject = HelperService.geoObjectFromString(geoString);
      setZoom(geoObject.lat === 0 && geoObject.lon === 0 ? 3 : 14);
      setCoordinates(geoObject);
    }
  }, [geoString]);

  return (
    <div className="w-100 h-100">
      {coordinates && <MapWithMarker id="location" lon={coordinates.lon} lat={coordinates.lat} zoom={zoom} />}
    </div>
  );
}

export default LocationsEditorMap;
