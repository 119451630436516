import React from 'react';
import NavbarMain from '../Navbar/NavbarMain';
import { INavigationItem } from '../../types/navigation';

interface IWrapperProps extends INavigationItem {
  children: React.ReactNode;
}
const Wrapper = ({ children, ...props }: IWrapperProps) => {
  return (
    <>
      <NavbarMain {...props} />
      {children}
    </>
  );
};
export default Wrapper;
