import { IAdvertiserItemShort } from './advertisers';

export enum UserRole {
  Admin = 'Admin',
  Editor = 'Editor',
  Reader = 'Reader',
  AdvertisingManager = 'AdvertisingManager',
}

export interface IUser {
  id: string;
  email: string;
  name: string;
  role: UserRole;
  suspended: boolean;
  userPool: string;
  lastLoginTimestamp: number;
}

export interface IUserEditor {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  suspended: boolean;
  availableAdvertisers: IAdvertiserItemShort[];
}

export const userEditorInit: IUserEditor = {
  email: '',
  id: '',
  name: '',
  role: UserRole.Reader,
  suspended: false,
  availableAdvertisers: [],
};

export interface IUserRole {
  name: string;
  defaultPath: string;
}

export type UserRoleType = {
  [key in UserRole]: IUserRole;
};

export const userRolesStorage: UserRoleType = {
  [UserRole.Admin]: { name: 'Admin', defaultPath: '/objects' },
  [UserRole.Editor]: {
    name: 'Editor',
    defaultPath: '/objects',
  },
  [UserRole.Reader]: {
    name: 'Reader',
    defaultPath: '/objects',
  },
  [UserRole.AdvertisingManager]: {
    name: 'Advertising Manager',
    defaultPath: '/advertisers',
  },
};
