import PopoverCollections from '../components/Navbar/Search/PopoverCollections';
import PopoverObjects from '../components/Navbar/Search/PopoverObjects';
import ObjectsListActions from '../components/Objects/ObjectsListActions';
import { RootState } from 'store/reducers';
import { UserRole } from './userManagement';

export interface INavigationItem {
  search?: boolean;
  map?: boolean;
  searchInfo?: JSX.Element;
  plusNavigation?: string;
  pathname: string;
  name: string;
  roles: UserRole[];
  actions?: ((state: RootState) => JSX.Element)[];
}

export enum navigationPages {
  objects,
  collections,
  advertisers,
  userManagement,
  visibilitySchedules,
}

export type INavigation = {
  [key in navigationPages]: INavigationItem;
};
export const navigation: INavigation = {
  [navigationPages.objects]: {
    search: true,
    map: true,
    searchInfo: PopoverObjects(),
    plusNavigation: '/objects/new',
    pathname: 'objects',
    name: 'Objects',
    roles: [UserRole.Admin, UserRole.Editor, UserRole.Reader],
    actions: [ObjectsListActions],
  },
  [navigationPages.collections]: {
    search: true,
    searchInfo: PopoverCollections(),
    plusNavigation: '/collections/new',
    pathname: 'collections',
    name: 'Collections',
    roles: [UserRole.Admin, UserRole.Editor, UserRole.Reader],
  },
  [navigationPages.advertisers]: {
    search: true,
    plusNavigation: '/advertisers/new',
    pathname: 'advertisers',
    name: 'Advertisers',
    roles: [UserRole.Admin, UserRole.Editor, UserRole.Reader, UserRole.AdvertisingManager],
  },
  [navigationPages.userManagement]: {
    search: false,
    plusNavigation: '/usermanagement/new',
    pathname: 'usermanagement',
    name: 'Users',
    roles: [UserRole.Admin],
  },

  [navigationPages.visibilitySchedules]: {
    search: true,
    plusNavigation: '/visibilitySchedules/new',
    pathname: 'visibilitySchedules',
    name: 'Schedules',
    roles: [UserRole.Admin, UserRole.Editor],
  },
};
