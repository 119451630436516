import React from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import styles from '../UserManagement.module.css';
import commonStyles from '../../../styles/common.module.css';
import { IAdvertiserItemShort } from '../../../types/advertisers';

interface IProp {
  adList: IAdvertiserItemShort[];
  selected: IAdvertiserItemShort[];
  onSelectChange: Function;
  errored?: boolean;
}
function AdvertiserMultiselect({ adList, selected, onSelectChange, errored }: IProp) {
  const itemClick = (e: any, ad: IAdvertiserItemShort) => {
    e.preventDefault();
    const index = selected.findIndex((selAd) => selAd.id === ad.id);
    index !== -1 ? selected.splice(index, 1) : selected.push(ad);
    onSelectChange([...selected]);
  };

  const isChecked = (id: string) => -1 !== selected.findIndex((selAd) => selAd.id === id);

  const renderItem = (ad: IAdvertiserItemShort) => (
    <Form.Check key={ad.id} id={`ad-${ad.id}`} className="mb-2 mt-2 pe-none">
      <Form.Check.Input checked={isChecked(ad.id)} readOnly />
      <Form.Check.Label>{ad.name}</Form.Check.Label>
    </Form.Check>
  );

  return (
    <Form.Group className="mb-3">
      <Form.Label>Advertisers</Form.Label>
      <DropdownButton
        autoClose="outside"
        data-test-id="user-form-ad"
        variant="light"
        title="Select advertisers"
        className={`${styles.dropdownRole} ${errored && commonStyles.errorBorderDropDown}`}
      >
        {adList.map((ad) => (
          <Dropdown.Item className={commonStyles.pointer} as={'div'} key={ad.id} onClick={(e) => itemClick(e, ad)}>
            {renderItem(ad)}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </Form.Group>
  );
}

export default AdvertiserMultiselect;
