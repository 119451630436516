import React, { useState } from 'react';
import CSLogo from '../../images/cs-logo.png';
import LoginOtp from './LoginOtp';
import LoginWithPassword from './LoginWithPassword';
import commonStyles from '../../styles/common.module.css';
import { Button } from 'react-bootstrap';
import { UserRole } from '../../types/userManagement';
import { loginUser } from 'store/action-creators/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function Login() {
  const [otpLogin, setOtpLogin] = useState<boolean>(true);
  const emailState = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLoginRole = (role: UserRole) => {
    dispatch(loginUser(role));
    navigate('/');
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <img alt="logo" src={CSLogo} />
      </div>
      <div className="d-flex justify-content-center">
        <div>
          {otpLogin ? (
            <LoginOtp emailState={emailState} handleLoginRole={handleLoginRole} />
          ) : (
            <LoginWithPassword emailState={emailState} handleLoginRole={handleLoginRole} />
          )}
          <Button
            data-test-id="sign-in-switch"
            variant="link"
            className={`${commonStyles.noUnderline} p-0 mt-4`}
            size="sm"
            onClick={() => setOtpLogin(!otpLogin)}
          >
            {otpLogin ? 'Sign in with static password' : 'Sign in with One-time password'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
