// @ts-ignore
import BootstrapTimePicker from 'react-bootstrap-time-picker';

interface TimePickerProps {
  onChange?: (seconds: number) => void;
  className?: string;
  value?: string;
}

// Component documentation: https://github.com/yury-dymov/react-bootstrap-time-picker
export function TimePicker({ onChange, className, value }: TimePickerProps) {
  return <BootstrapTimePicker onChange={onChange} className={className} value={value} />;
}
