import { Route } from 'react-router-dom';
import PrivateRoute from '../common/PrivateRoute';
import Wrapper from '../common/Wrapper';
import { navigation, navigationPages } from '../../types/navigation';
import React from 'react';
import UserManagement from '../UserManagement/UserManagement';
import { UserRole } from '../../types/userManagement';
import UserManagementEditor from '../UserManagement/UserManagementEditor/UserManagementEditor';

const UserManagementRoutes = [
  <Route
    key="/usermanagement"
    path="/usermanagement"
    element={
      <PrivateRoute roles={[UserRole.Admin]}>
        <Wrapper {...navigation[navigationPages.userManagement]}>
          <UserManagement />
        </Wrapper>
      </PrivateRoute>
    }
  />,
  <Route
    key="/usermanagement/new"
    path="/usermanagement/new"
    element={
      <PrivateRoute roles={[UserRole.Admin]}>
        <UserManagementEditor />
      </PrivateRoute>
    }
  />,
  <Route
    key="/usermanagement/:userId"
    path="/usermanagement/:userId"
    element={
      <PrivateRoute roles={[UserRole.Admin]}>
        <UserManagementEditor />
      </PrivateRoute>
    }
  />,
];

export default UserManagementRoutes;
