import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { IStatus } from 'types/common';
import LoadingWrapper from 'components/common/LoadingWrapper';
import styles from '../Object.module.css';

interface IEditorLayoutProps {
  children: ReactNode;
  loadingStatus: IStatus;
  onSaveClick: () => void;
  onCancelClick: () => void;
  isSaveDisabled?: boolean;
}

export const EditorLayout = ({
  children,
  loadingStatus,
  onSaveClick,
  onCancelClick,
  isSaveDisabled = false,
}: IEditorLayoutProps) => (
  <div className={styles.editorContainer}>
    <div className={`d-flex justify-content-center ${styles.editorContent}`}>
      <LoadingWrapper status={loadingStatus}>
        <div className={styles.adminEditorContainer}>{children}</div>
      </LoadingWrapper>
    </div>
    <div className={`${styles.buttonContainer} d-flex justify-content-between`}>
      <Button variant="light" size="sm" onClick={onCancelClick}>
        Cancel
      </Button>
      <Button variant="primary" size="sm" onClick={isSaveDisabled ? undefined : onSaveClick} disabled={isSaveDisabled}>
        Save
      </Button>
    </div>
  </div>
);
