import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import AuthService from '../../services/AuthService';
import { UserRole } from '../../types/userManagement';

interface IProps {
  emailState: [string, Dispatch<SetStateAction<string>>];
  handleLoginRole: (v: UserRole) => void;
}

function LoginWithPassword({ emailState, handleLoginRole }: IProps) {
  const [password, setPassword] = useState<any>('');
  const [error, setError] = useState<boolean>(false);

  const onEmailChange = (e: any) => {
    setError(false);
    emailState[1](e.target.value);
  };

  const onPasswordChange = (e: any) => {
    setError(false);
    setPassword(e.target.value);
  };

  const validValues = () => {
    return /\S+@\S+\.\S+/.test(emailState[0]) && password;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const loginRole = await AuthService.login(emailState[0], password);
    loginRole ? handleLoginRole(loginRole) : setError(true);
  };

  return (
    <Form style={{ width: '300px' }} onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          data-test-id="login-static-email"
          isInvalid={error}
          type="text"
          value={emailState[0]}
          onChange={onEmailChange}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          data-test-id="login-static-password"
          isInvalid={error}
          type="password"
          value={password}
          onChange={onPasswordChange}
        />
      </Form.Group>
      <Button
        data-test-id="login-static-signin"
        variant="primary"
        type="submit"
        className="w-100"
        disabled={!validValues()}
      >
        Sign in
      </Button>
    </Form>
  );
}

export default LoginWithPassword;
