import React, { useState } from 'react';
import { IAdvertiserItem } from '../../../types/advertisers';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import styles from '../advertisers.module.css';
import FormInputLimited from '../../common/FormInputLimited';
import moment from 'moment-timezone';
import GeoDropdowns from '../../Object/AdminEditor/GeoDropdowns';
import ImagePicker from '../../common/ImagePicker/ImagePicker';
import CountriesLookup from '../../common/CountriesLookup';

interface IProps {
  ad: IAdvertiserItem;
  adChange: (name: string, value: any) => void;
  errorValues: string[];
}

function AdvertiserEditorForm({ ad, adChange, errorValues }: IProps) {
  const [timeZones] = useState<string[]>(moment.tz.names());

  const onFormControlChange = (event: any) => {
    adChange(event.target.name, event.target.value);
  };

  const onTZSelect = (e: any, tz: string | null) => {
    e.preventDefault();
    adChange('displayTimeZone', tz);
  };

  const switcherChange = (event: any) => {
    if (!event.target.checked) {
      adChange(event.target.name, event.target.checked);
    } else if (
      window.confirm(
        'Please, use with caution: when selected, all existing users as well as future users will be automatically subscribed to this sponsors.',
      ) === true
    ) {
      adChange(event.target.name, event.target.checked);
    }
  };

  const archivedSwitcherChange = (event: any) => {
    adChange(event.target.name, !event.target.checked);
  };

  const handleGeoChange = (values: { name: string; value: any }[]) => {
    for (const value of values) {
      adChange(value.name, value.value);
    }
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Row>
            <Col>
              <FormInputLimited
                isInvalid={errorValues.includes('name')}
                data-test-id="advertiser-form-name"
                label="Title"
                onChange={onFormControlChange}
                lengthLimit={80}
                value={ad.name}
                type="text"
                name="name"
              />
            </Col>
            <Col>
              <ImagePicker
                caption="Upload advertiser icon"
                hasError={errorValues.includes('iconUrl')}
                imageUrl={ad.iconUrl}
                height={128}
                width={256}
                imageResize={true}
                imageRecommendedHeight={128}
                imageRecommendedWidth={128}
                supportJpg={true}
                supportPng={true}
                name="iconUrl"
                valueChange={adChange}
              />
            </Col>
          </Row>
        </Form.Group>

        <FormInputLimited
          isInvalid={errorValues.includes('description')}
          data-test-id="advertiser-form-description"
          label="Description"
          onChange={onFormControlChange}
          lengthLimit={2000}
          value={ad.description || ''}
          type="text"
          as="textarea"
          name="description"
        />

        <FormInputLimited
          isInvalid={errorValues.includes('websiteUrl')}
          data-test-id="advertiser-form-websiteUrl"
          label="Website Url"
          onChange={onFormControlChange}
          lengthLimit={200}
          value={ad.websiteUrl || ''}
          type="text"
          name="websiteUrl"
        />

        <Form.Group>
          <Form.Label className="text-muted">Location</Form.Label>
          <GeoDropdowns country={ad.country} region={ad.region} place={ad.place} geoChange={handleGeoChange} />
        </Form.Group>

        <Form.Group>
          <Form.Label>Time zone</Form.Label>
          <DropdownButton
            data-test-id="advertiser-form-displayTimeZone"
            variant="light"
            title={ad.displayTimeZone ? ad.displayTimeZone : 'Not specified'}
            className={styles.dropdownGeoContainer}
          >
            <Dropdown.Item as={'button'} onClick={(e) => onTZSelect(e, null)}>
              ---
            </Dropdown.Item>
            {timeZones.map((tz) => (
              <Dropdown.Item as={'button'} key={tz} onClick={(e) => onTZSelect(e, tz)}>
                {tz}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <div>
            <span className="text-muted">
              Changing advertizer time zone will not automatically adjust dates and times of deals and coupons.
            </span>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label className="mt-4 text-muted">Automatically subscribe users to this sponsor</Form.Label>
          <Form.Check type="switch" id="active-switcher">
            <Form.Check.Label className={ad.isDefault ? 'fw-bold' : ''}>All users</Form.Check.Label>
            <Form.Check.Input
              data-test-id="advertiser-form-isDefault"
              name="isDefault"
              checked={ad.isDefault}
              onChange={switcherChange}
            />
          </Form.Check>
          <Form.Label>Users from selected countries</Form.Label>
          <CountriesLookup
            name="defaultCountries"
            value={ad.defaultCountries}
            disabled={ad.isDefault}
            onChange={onFormControlChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className="mt-4 text-muted">Extra images</Form.Label>
          <div className="d-flex justify-content-start">
            {[1, 2, 3].map((i) => {
              const key = `extraImageUrl${i}` as keyof IAdvertiserItem;
              return (
                <div className="pe-3" key={key}>
                  <ImagePicker
                    caption={`Upload extra image ${i}`}
                    hasError={errorValues.includes(key)}
                    imageUrl={ad[key] as string | null}
                    height={220}
                    width={220}
                    imageResize={true}
                    imageRecommendedHeight={1024}
                    imageRecommendedWidth={1024}
                    supportJpg={true}
                    supportPng={true}
                    name={key}
                    valueChange={adChange}
                  />
                </div>
              );
            })}
          </div>
        </Form.Group>

        <Form.Group>
          {ad.id && (
            <Col className="d-flex align-items-center mt-4">
              <Form.Group>
                <Form.Check type="switch" id="active-switcher">
                  <Form.Check.Label className={ad.isArchived ? '' : 'fw-bold'}>Published</Form.Check.Label>
                  <Form.Check.Input
                    data-test-id="advertiser-form-isArchived"
                    name="isArchived"
                    checked={!ad.isArchived}
                    onChange={archivedSwitcherChange}
                  />
                </Form.Check>
              </Form.Group>
            </Col>
          )}
        </Form.Group>
      </Form>
    </div>
  );
}

export default AdvertiserEditorForm;
