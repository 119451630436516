import React from 'react';
import styles from './AddNewButton.module.css';
import PlusButton from '../../../images/plus-button.png';
import { useNavigate } from 'react-router-dom';

interface IProps {
  plusNavigation: string | Function;
  dataTestId?: string;
  disabled?: boolean;
}

function AddNewButton({ plusNavigation, disabled = false, dataTestId }: IProps) {
  const navigate = useNavigate();

  const onPlusClick = () => {
    typeof plusNavigation === 'string' ? navigate(plusNavigation) : plusNavigation();
  };

  return (
    <img
      alt="plus-button"
      className={disabled ? styles.imageButtonDisabled : styles.imageButton}
      data-test-id={dataTestId}
      src={PlusButton}
      onClick={disabled ? undefined : onPlusClick}
    />
  );
}

export default AddNewButton;
