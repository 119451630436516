import { ITableColumn } from '../../../../../types/common';

function Columns(): ITableColumn[] {
  return [
    {
      id: 'name',
      displayName: 'User name',
      width: '30vw',
    },
    {
      id: 'email',
      displayName: 'Email',
      width: '30vw',
    },
  ];
}

export default Columns;
