import React, { useState } from 'react';
import styles from './ClickableImage.module.css';
import { BiTrash } from 'react-icons/bi';
import * as ReactDOM from 'react-dom';
import CSImage from '../CSImage';

interface IProps {
  deleteClick?: any;
  [propName: string]: any;
}

function ClickableImage({ deleteClick, ...props }: IProps) {
  const [showFull, setShowFull] = useState<boolean>(false);
  return (
    <>
      <div className={styles.imgContainer}>
        <CSImage {...props} className={deleteClick && styles.imgWithDelete} onClick={() => setShowFull(true)} />
        {deleteClick && (
          <div
            className={styles.darkContainer}
            style={props?.style?.borderRadius ? { borderRadius: props.style.borderRadius } : {}}
            onClick={() => setShowFull(true)}
          >
            <div className={styles.delContainer} onClick={deleteClick}>
              <BiTrash />
            </div>
          </div>
        )}
      </div>

      {showFull &&
        ReactDOM.createPortal(
          <div
            onClick={() => setShowFull(false)}
            className={`${styles.fsContainer} d-flex justify-content-center align-items-center m-0`}
          >
            <CSImage alt="full-screen" src={props.src} />
          </div>,
          document.body,
        )}
    </>
  );
}

export default ClickableImage;
