import { ColObjectsActionTypes, ICollectionState, IColObjectItem } from '../../types/collections';

export function setColStatus(status: ICollectionState) {
  return { type: ColObjectsActionTypes.SET_STATUS, payload: status };
}

export function setColId(id: string) {
  return { type: ColObjectsActionTypes.SET_ID, payload: id };
}

export function clearColStore() {
  return { type: ColObjectsActionTypes.CLEAR_COL_STORE };
}

export function setColObjects(objects: IColObjectItem[], wasChanged: boolean = false) {
  return {
    type: ColObjectsActionTypes.SET_OBJECTS,
    payload: { objects, wasChanged },
  };
}

export function removeColObject(id: string, affectChange: boolean = true) {
  return {
    type: ColObjectsActionTypes.REMOVE_OBJECT,
    payload: { id, affectChange },
  };
}
