import axios from 'axios';
import Model3DLib from '../../lib/model3DLib';
import CommonService from '../CommonService';
import HelperService from '../HelperService';
import GeonamesService from '../GeonamesService';
import constants from '../../constants';
import { IAttachmentType, IMimeType } from '../../types/common';
import { IObjectModel, IObjectVersionForm } from '../../types/objects';

class ObjectEditorService {
  cloneVersion = (versionId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/CloneVersionAsDraft`;

    const clonePayload = {
      versionId,
    };

    return axios.post(url, clonePayload).then((res) => res.data.id);
  };

  updateDraft = (data: any) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/UpdateDraft`;

    return axios.put(url, data).then((res) => res);
  };

  editorSaveVersion = async (
    id: string,
    formData: IObjectVersionForm,
    modelData: IObjectModel,
    modelAuthorId: string,
    newVersion: boolean = true,
  ) => {
    try {
      const getUploadUrl = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/getFileUploadUrl`;
      const versionId = newVersion ? await this.cloneVersion(id) : id;
      let modelUpdate = false;

      const extraPhotos: string[] = [];
      for (const image of formData.extraPhotos) {
        if (image.file) {
          const uploadResult = await CommonService.uploadFile(getUploadUrl, image.file, {
            targetId: versionId,
            attachmentType: IAttachmentType.PublicPhoto,
            mimeType: image.file.type,
          });
          extraPhotos.push(uploadResult.fileName);
        } else if (image.name) {
          extraPhotos.push(image.name);
        }
      }

      if (!newVersion) {
        if (modelData.model && !modelData.model.url) {
          await this.deleteModelFile('model.glb', versionId);
          modelUpdate = true;
        }
        if (modelData.iosModel && !modelData.iosModel.url) {
          await this.deleteModelFile('model.usdz', versionId);
          modelUpdate = true;
        }
        if (modelData.image && !modelData.image.url) {
          await this.deleteModelFile('image.png', versionId);
          modelUpdate = true;
        }
        if (modelData.icon && !modelData.icon.url) {
          await this.deleteModelFile('icon.png', versionId);
          modelUpdate = true;
        }
      }

      if (modelData.model) {
        let modelFile;
        if (modelData.rotation && modelData.rotation !== 0) {
          modelFile = await this.applyRotationToModel(modelData.model.url, modelData.rotation);
        } else {
          modelFile = modelData.model.file;
        }
        if (modelFile) {
          await CommonService.uploadFile(getUploadUrl, modelFile, {
            targetId: versionId,
            attachmentType: IAttachmentType.Model,
            mimeType: modelFile.type,
          });
          modelUpdate = true;
        }
      }
      if (modelData.iosModel) {
        let iOSModelFile = modelData.iosModel.file;

        if (iOSModelFile) {
          await CommonService.uploadFile(getUploadUrl, iOSModelFile, {
            targetId: versionId,
            attachmentType: IAttachmentType.IOSModel,
            mimeType: iOSModelFile.type,
          });
          modelUpdate = true;
        }
      }
      if (modelData.image?.url?.startsWith('data:')) {
        const file: File = await HelperService.dataUrlToFile(modelData.image.url, 'image.png', IMimeType.imagePng);
        await CommonService.uploadFile(getUploadUrl, file, {
          targetId: versionId,
          attachmentType: IAttachmentType.ModelImage,
          mimeType: file.type,
        });
        modelUpdate = true;
      }
      if (modelData.icon?.url?.startsWith('data:')) {
        const file: File = await HelperService.dataUrlToFile(modelData.icon.url, 'image.png', IMimeType.imagePng);
        await CommonService.uploadFile(getUploadUrl, file, {
          targetId: versionId,
          attachmentType: IAttachmentType.ModelIcon,
          mimeType: file.type,
        });
        modelUpdate = true;
      }

      const updateData = {
        id: versionId,
        localizedName: formData.localizedName,
        localizedDetails: formData.localizedDetails,
        localizedLink: formData.localizedLink,
        summary: '',
        location: HelperService.geoObjectFromString(formData.coordinates),
        extraPhotos,
        animated: modelData.animated,
        modelAuthorId: modelAuthorId || null,
        modelAuthorNote: formData.modelAuthorNote,
      };
      await this.updateDraft(updateData);

      if (modelUpdate) await HelperService.delay(3000);

      return true;
    } catch {
      return false;
    }
  };

  applyRotationToModel = async (url: string, rotation: number): Promise<File | undefined> => {
    const newFileUrl = await Model3DLib.applyRotationToFileUrl(url, rotation);
    return await HelperService.dataUrlToFile(newFileUrl ? newFileUrl : url, 'model.glb', IMimeType.model);
  };

  deleteModelFile = async (fileName: string, targetId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/DeleteFile`;

    return axios.post(url, { fileName, targetId }).then();
  };

  createObject = (data: any) => {
    const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/CreateObject`;

    return axios.post(url, data).then((res) => res.data);
  };

  editorCreateObject = async (formData: IObjectVersionForm, modelData: IObjectModel, modelAuthorId: string) => {
    const geoObject = HelperService.geoObjectFromString(formData.coordinates);

    const { countryId, regionId, placeId } = await GeonamesService.getNearestPlace(geoObject.lat, geoObject.lon);

    const newData = {
      localizedName: formData.localizedName,
      localizedDetails: formData.localizedDetails,
      localizedLink: formData.localizedLink,
      location: HelperService.geoObjectFromString(formData.coordinates),
      summary: '',
      draftOnly: true,
      tags: [],
      countryId,
      regionId,
      placeId,
      adminNotes: '',
      adminLinks: [],
      modelAuthorId: modelAuthorId || null,
      modelAuthorNote: formData.modelAuthorNote,
    };

    try {
      const { id, objectVersionId } = await this.createObject(newData);
      await this.editorSaveVersion(objectVersionId, formData, modelData, modelAuthorId, false);
      return id;
    } catch {
      return false;
    }
  };
}
export default new ObjectEditorService();
