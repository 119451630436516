import { ICollectionEditor } from 'types/collections';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Images from 'components/common/ObjectCollection/Images';
import { IFileWithUrl, ILanguageCode, ILink, ILocalizedContent } from 'types/common';
import GeoDropdowns from '../../Object/AdminEditor/GeoDropdowns';
import FormInputLimited from 'components/common/FormInputLimited';
import ObjectCollectionTags from 'components/common/Tags/ObjectCollectionTags';
import MarkdownSupportComment from 'components/common/MarkdownSupportComment';
import CouponInfo from 'components/Advertisers/AdvertiserDetails/Coupons/CouponInfo';
import ModalInfoService from 'store/services/ModalInfoService';
import TranslationService from 'services/TranslationService';
import { LocalizationTabs } from 'components/common/LocalizationTabs';

interface IProps {
  collection: ICollectionEditor;
  collectionChange: (values: { name: string; value: any }[]) => void;
  errorValues: string[];
}
function CollectionEditorForm({ collection, collectionChange, errorValues }: IProps) {
  const onFormControlChange = (event: any) => {
    collectionChange([{ name: event.target.name, value: event.target.value }]);
  };

  const onLocalizedFormControlChange = (event: any) => {
    const fieldData = event.target.name.split('-');
    const fieldName = fieldData[0];
    const languageCode = fieldData[1];

    const field = { ...collection }[fieldName] as ILocalizedContent<string>;
    field[languageCode] = event.target.value;

    collectionChange([{ name: fieldName, value: field }]);
  };

  const onLocalizedLinkChange = (event: any) => {
    const fieldData = event.target.name.split('-');
    const fieldName = fieldData[0];
    const propertyName = fieldData[1];
    const languageCode = fieldData[2];

    let field = { ...collection }[fieldName] as ILocalizedContent<ILink>;

    if (!field || !field[languageCode]) {
      field = {
        en: { url: '', title: '' },
        ja: { url: '', title: '' },
        es: { url: '', title: '' },
        ru: { url: '', title: '' },
      };
    }

    field[languageCode][propertyName] = event.target.value;

    collectionChange([{ name: fieldName, value: field }]);
  };

  const handleDeleteImage = (index: number, field: 'extraPhotos' | 'adminPictures') => {
    collection[field].splice(index, 1);
    collectionChange([{ name: 'extraPhotos', value: collection.extraPhotos }]);
  };

  const handleAddImage = (fileUrls: IFileWithUrl[], field: 'extraPhotos' | 'adminPictures') => {
    collectionChange([
      {
        name: field,
        value: collection[field].concat(fileUrls),
      },
    ]);
  };

  const handleGeoChange = (values: { name: string; value: any }[]) => {
    collectionChange(values);
  };

  const handleDeleteTag = (tag: string, index: number) => {
    collection.tags.splice(index, 1);
    collectionChange([{ name: 'tags', value: [...collection.tags] }]);
  };

  const handleAddTag = (tag: string) => {
    if (!collection.tags.includes(tag)) {
      collectionChange([{ name: 'tags', value: [...collection.tags, tag] }]);
    }
  };

  const handleAutoTranslate = async (event: any) => {
    const fieldData = event.target.name.split('-');
    const sourceLanguage = fieldData[1];

    const titleContent = collection.localizedName[sourceLanguage] as string;
    const descriptionContent = collection.localizedDetails[sourceLanguage] as string;

    if (!titleContent) {
      ModalInfoService.showModalInfoError(`Title is empty`);
      return;
    }

    if (!descriptionContent) {
      ModalInfoService.showModalInfoError(`Description is empty`);
      return;
    }

    const linkContent = collection.localizedLink?.[sourceLanguage] as ILink;

    if (linkContent) {
      if (linkContent.title && !linkContent.url) {
        ModalInfoService.showModalInfoError(
          `Link URL is empty. Please add it or remove the title to use auto-translation.`,
        );
        return;
      }
      if (!linkContent.title && linkContent.url) {
        ModalInfoService.showModalInfoError(
          `Link Title is empty. Please add it or remove the URL to use auto-translation.`,
        );
        return;
      }
    }

    const titleField = { ...collection }.localizedName as ILocalizedContent<string>;
    const descriptionField = { ...collection }.localizedDetails as ILocalizedContent<string>;
    const linkField = ({ ...collection }.localizedLink as ILocalizedContent<ILink>) ?? {};

    let nonEmptyLanguages = [];

    Object.entries(ILanguageCode)
      .filter(([_, languageCode]) => languageCode !== sourceLanguage)
      .forEach(([label, languageCode]) => {
        if (titleField[languageCode] || descriptionField[languageCode] || linkField[languageCode]) {
          nonEmptyLanguages.push(label);
        }
      });

    if (
      nonEmptyLanguages.length === 0 ||
      window.confirm(
        `Please, use with caution: content on ${nonEmptyLanguages.join(
          ', ',
        )} languages will be replaced with auto-translated version.`,
      ) === true
    ) {
      ModalInfoService.showModalInfoLoading();

      const translationPromises = Object.entries(ILanguageCode)
        .filter(([_, languageCode]) => languageCode !== sourceLanguage)
        .map(async ([_, languageCode]) => {
          const translatedTitle = await TranslationService.translateText(titleContent, sourceLanguage, languageCode);
          const translatedDescription = await TranslationService.translateText(
            descriptionContent,
            sourceLanguage,
            languageCode,
          );

          if (translatedTitle) {
            titleField[languageCode] = translatedTitle;
          }

          if (translatedDescription) {
            descriptionField[languageCode] = translatedDescription;
          }

          if (linkContent && linkContent.title) {
            const translatedLinkTitle = await TranslationService.translateText(
              linkContent.title,
              sourceLanguage,
              languageCode,
            );

            if (translatedLinkTitle) {
              linkField[languageCode].title = translatedLinkTitle;
              linkField[languageCode].url = linkContent.url;
            }
          }
        });

      await Promise.all(translationPromises);

      collectionChange([
        { name: 'localizedName', value: titleField },
        { name: 'localizedDetails', value: descriptionField },
        { name: 'localizedLink', value: linkField },
      ]);

      ModalInfoService.closeModalInfo();
    }
  };

  return (
    <div style={{ marginBottom: 150 }}>
      <h3 className="mb-2">Localized content</h3>
      <LocalizationTabs
        hasBorder
        renderTabContent={(languageCode, label) => (
          <div className="p-3">
            <Form>
              <FormInputLimited
                isInvalid={errorValues.includes(`${label}-title`)}
                data-test-id={`col-editor-name-${languageCode}`}
                label="Title"
                onChange={onLocalizedFormControlChange}
                lengthLimit={80}
                value={collection.localizedName[languageCode] ?? ''}
                type="text"
                name={`localizedName-${languageCode}`}
              />
              <FormInputLimited
                isInvalid={errorValues.includes(`${label}-description`)}
                data-test-id={`col-editor-details-${languageCode}`}
                label="Description"
                onChange={onLocalizedFormControlChange}
                lengthLimit={2000}
                value={collection.localizedDetails[languageCode] ?? ''}
                name={`localizedDetails-${languageCode}`}
                as="textarea"
                rows={5}
                comment={MarkdownSupportComment()}
              />
              <Row className="m-0">
                <Col className="pb-0">
                  <FormInputLimited
                    isInvalid={errorValues.includes(`${label}-link-title`)}
                    data-test-id={`col-editor-localizedLinkTitle-${languageCode}`}
                    label="Title link"
                    onChange={onLocalizedLinkChange}
                    lengthLimit={50}
                    value={collection.localizedLink?.[languageCode]?.title ?? ''}
                    type="text"
                    name={`localizedLink-title-${languageCode}`}
                  />
                </Col>
                <Col className="pb-0">
                  <Form.Group className="mb-3">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      isInvalid={errorValues.includes(`${label}-link-url`)}
                      data-test-id={`col-editor-localizedLinkUrl-${languageCode}`}
                      onChange={onLocalizedLinkChange}
                      name={`localizedLink-url-${languageCode}`}
                      type="text"
                      value={collection.localizedLink?.[languageCode]?.url ?? ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button
                variant="outline-primary"
                className="pt-0 pb-0"
                name={`translate-${languageCode}`}
                onClick={handleAutoTranslate}
              >
                Auto translate to other languages
              </Button>
            </Form>
          </div>
        )}
      />
      <Images
        id="extraPhotos"
        images={collection.extraPhotos}
        onAddImage={(images) => handleAddImage(images, 'extraPhotos')}
        onDeleteImage={(index) => handleDeleteImage(index, 'extraPhotos')}
      />
      <h3 className="mt-5 mb-3">Admin info</h3>
      <GeoDropdowns
        country={collection.country}
        region={collection.region}
        place={collection.place}
        geoChange={handleGeoChange}
      />
      <Form className="mt-3" style={{ maxWidth: '632px' }}>
        <ObjectCollectionTags tags={collection.tags} onDeleteTag={handleDeleteTag} onAddTag={handleAddTag} />

        <FormInputLimited
          data-test-id="col-editor-adminNotes"
          label="Notes"
          onChange={onFormControlChange}
          lengthLimit={2000}
          value={collection.adminNotes}
          name="adminNotes"
          as="textarea"
          rows={6}
        />
      </Form>
      <Images
        id="adminPictures"
        images={collection.adminPictures}
        onAddImage={(images) => handleAddImage(images, 'adminPictures')}
        onDeleteImage={(index) => handleDeleteImage(index, 'adminPictures')}
      />

      {collection.couponCampaignIds?.length > 0 && (
        <div>
          <h6>Coupons</h6>
          {collection.couponCampaignIds.map((campaignId, index) => {
            return <CouponInfo key={campaignId} couponCampaignId={campaignId} />;
          })}
        </div>
      )}
    </div>
  );
}

export default CollectionEditorForm;
