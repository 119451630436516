import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ILocation } from '../../../../types/advertisers';
import HelperService from '../../../../services/HelperService';
import FormInputLimited from '../../../common/FormInputLimited';

interface IProp {
  location: ILocation;
  locationChange: (name: keyof ILocation, value: any) => void;
  geoSet: () => void;
  errorValues: string[];
}
function LocationEditorForm({ location, locationChange, geoSet, errorValues }: IProp) {
  const [validCoordinates, setValidCoordinates] = useState<boolean>(true);

  useEffect(() => {
    setValidCoordinates(HelperService.isGeoStringValid(location.coordinates, false));
  }, [location.coordinates]);

  const onFormControlChange = (event: any) => {
    locationChange(event.target.name, event.target.value);
  };

  return (
    <div data-test-id="location-editor-form">
      <Form>
        <FormInputLimited
          isInvalid={errorValues.includes('name')}
          data-test-id="location-form-name"
          label="Customer facing name"
          onChange={onFormControlChange}
          lengthLimit={80}
          value={location.name}
          type="text"
          name="name"
        />
        <FormInputLimited
          isInvalid={errorValues.includes('address')}
          data-test-id="location-form-address"
          label="Customer facing address"
          onChange={onFormControlChange}
          lengthLimit={120}
          value={location.address}
          type="text"
          name="address"
        />
        <FormInputLimited
          data-test-id="location-form-collectorNotes"
          label="Customer facing note"
          onChange={onFormControlChange}
          lengthLimit={300}
          value={location.collectorNotes}
          name="collectorNotes"
          as="textarea"
          rows={2}
        />
        <FormInputLimited
          isInvalid={errorValues.includes('link')}
          data-test-id="location-form-link"
          label="Customer facing web link"
          onChange={onFormControlChange}
          lengthLimit={120}
          value={location.link}
          name="link"
          type="text"
        />
        <div className="d-flex align-items-center">
          <Form.Group className="mb-3" style={{ width: '300px' }}>
            <Form.Label>Coordinates</Form.Label>
            <Form.Control
              isInvalid={errorValues.includes('coordinates')}
              data-test-id="location-form-coordinates"
              onChange={onFormControlChange}
              name="coordinates"
              type="text"
              value={location.coordinates}
            />
          </Form.Group>
          <Button
            className="mt-3 ms-3"
            data-test-id="location-show-map"
            disabled={!location.coordinates || !validCoordinates}
            onClick={geoSet}
            size="sm"
            variant="light"
          >
            Show on map
          </Button>
        </div>
        <FormInputLimited
          data-test-id="location-form-adminNotes"
          label="Admin Comment"
          onChange={onFormControlChange}
          lengthLimit={2000}
          value={location.adminNotes}
          name="adminNotes"
          as="textarea"
          rows={5}
        />
      </Form>
    </div>
  );
}

export default LocationEditorForm;
