import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { IObjectAdmin, IObjectVersionForm } from 'types/objects';
import Images from 'components/common/ObjectCollection/Images';
import { IFileWithUrl, ILanguageCode, ILink, ILocalizedContent } from 'types/common';
import FormInputLimited from 'components/common/FormInputLimited';
import MarkdownSupportComment from 'components/common/MarkdownSupportComment';
import GeoDropdowns from '../AdminEditor/GeoDropdowns';
import HelperService from 'services/HelperService';
import GeonamesService from 'services/GeonamesService';
import ModalInfoService from 'store/services/ModalInfoService';
import TranslationService from 'services/TranslationService';
import { LocalizationTabs } from 'components/common/LocalizationTabs';

interface IProps {
  version: IObjectVersionForm;
  versionChange: (values: { name: string; value: any }[]) => void;
  objectChange: (values: { name: string; value: any }[]) => void;
  object: IObjectAdmin;
  errorValues: string[];
}
function DraftForm({ version, versionChange, object, objectChange, errorValues }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFormControlChange = (event: any) => {
    versionChange([{ name: event.target.name, value: event.target.value }]);
  };

  const handleDeleteImage = (index: number) => {
    version.extraPhotos.splice(index, 1);
    versionChange([{ name: 'extraPhotos', value: version.extraPhotos }]);
  };

  const handleAddImage = (fileUrls: IFileWithUrl[]) => {
    versionChange([{ name: 'extraPhotos', value: version.extraPhotos.concat(fileUrls) }]);
  };

  const handleLocationChange = (event: any) => {
    if (object && HelperService.isGeoStringValid(event.target.value)) {
      setIsLoading(true);
      const geoObject = HelperService.geoObjectFromString(event.target.value);

      GeonamesService.getNearestPlace(geoObject.lat, geoObject.lon)
        .then((value) => {
          const { countryId, countryName, regionId, regionName, placeId, placeName } = value;

          handleGeoChange([
            {
              name: 'country',
              value: countryId ? { id: countryId, displayName: countryName } : null,
            },
            {
              name: 'region',
              value: regionId ? { id: regionId, displayName: regionName } : null,
            },
            {
              name: 'place',
              value: placeId ? { id: placeId, displayName: placeName } : null,
            },
          ]);

          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
    versionChange([{ name: event.target.name, value: event.target.value }]);
  };

  const handleGeoChange = (values: { name: string; value: any }[]) => {
    objectChange(values);
  };

  const onLocalizedFormControlChange = (event: any) => {
    const fieldData = event.target.name.split('-');
    const fieldName = fieldData[0];
    const languageCode = fieldData[1];

    const field = { ...version }[fieldName] as ILocalizedContent<string>;
    field[languageCode] = event.target.value;

    versionChange([{ name: fieldName, value: field }]);
  };

  const onLocalizedLinkChange = (event: any) => {
    const fieldData = event.target.name.split('-');
    const fieldName = fieldData[0];
    const propertyName = fieldData[1];
    const languageCode = fieldData[2];

    let field = { ...version }[fieldName] as ILocalizedContent<ILink>;

    if (!field || !field[languageCode]) {
      field = {
        en: { url: '', title: '' },
        ja: { url: '', title: '' },
        es: { url: '', title: '' },
        ru: { url: '', title: '' },
      };
    }

    field[languageCode][propertyName] = event.target.value;

    versionChange([{ name: fieldName, value: field }]);
  };

  const handleAutoTranslate = async (event: any) => {
    const fieldData = event.target.name.split('-');
    const sourceLanguage = fieldData[1];

    const titleContent = version.localizedName[sourceLanguage] as string;
    const descriptionContent = version.localizedDetails[sourceLanguage] as string;

    if (!titleContent) {
      ModalInfoService.showModalInfoError(`Title is empty`);
      return;
    }

    if (!descriptionContent) {
      ModalInfoService.showModalInfoError(`Description is empty`);
      return;
    }

    const linkContent = version.localizedLink?.[sourceLanguage] as ILink;

    if (linkContent) {
      if (linkContent.title && !linkContent.url) {
        ModalInfoService.showModalInfoError(
          `Link URL is empty. Please add it or remove the title to use auto-translation.`,
        );
        return;
      }
      if (!linkContent.title && linkContent.url) {
        ModalInfoService.showModalInfoError(
          `Link Title is empty. Please add it or remove the URL to use auto-translation.`,
        );
        return;
      }
    }

    const titleField = { ...version }.localizedName as ILocalizedContent<string>;
    const descriptionField = { ...version }.localizedDetails as ILocalizedContent<string>;
    const linkField = ({ ...version }.localizedLink as ILocalizedContent<ILink>) ?? {};

    let nonEmptyLanguages = [];

    Object.entries(ILanguageCode)
      .filter(([_, languageCode]) => languageCode !== sourceLanguage)
      .forEach(([label, languageCode]) => {
        if (titleField[languageCode] || descriptionField[languageCode] || linkField[languageCode]) {
          nonEmptyLanguages.push(label);
        }
      });

    if (
      nonEmptyLanguages.length === 0 ||
      window.confirm(
        `Please, use with caution: content on ${nonEmptyLanguages.join(
          ', ',
        )} languages will be replaced with auto-translated version.`,
      ) === true
    ) {
      ModalInfoService.showModalInfoLoading();

      const translationPromises = Object.entries(ILanguageCode)
        .filter(([_, languageCode]) => languageCode !== sourceLanguage)
        .map(async ([_, languageCode]) => {
          const translatedTitle = await TranslationService.translateText(titleContent, sourceLanguage, languageCode);
          const translatedDescription = await TranslationService.translateText(
            descriptionContent,
            sourceLanguage,
            languageCode,
          );

          if (translatedTitle) {
            titleField[languageCode] = translatedTitle;
          }

          if (translatedDescription) {
            descriptionField[languageCode] = translatedDescription;
          }

          if (linkContent && linkContent.title) {
            const translatedLinkTitle = await TranslationService.translateText(
              linkContent.title,
              sourceLanguage,
              languageCode,
            );

            if (translatedLinkTitle) {
              linkField[languageCode].title = translatedLinkTitle;
              linkField[languageCode].url = linkContent.url;
            }
          }
        });

      await Promise.all(translationPromises);

      versionChange([
        { name: 'localizedName', value: titleField },
        { name: 'localizedDetails', value: descriptionField },
        { name: 'localizedLink', value: linkField },
      ]);

      ModalInfoService.closeModalInfo();
    }
  };

  return (
    <div>
      <LocalizationTabs
        hasBorder
        renderTabContent={(languageCode, label) => (
          <div className="p-3">
            <Form>
              <FormInputLimited
                isInvalid={errorValues.includes(`${label}-title`)}
                data-test-id={`obj-editor-name-${languageCode}`}
                label="Title"
                onChange={onLocalizedFormControlChange}
                lengthLimit={80}
                value={version.localizedName[languageCode] ?? ''}
                type="text"
                name={`localizedName-${languageCode}`}
              />
              <FormInputLimited
                isInvalid={errorValues.includes(`${label}-description`)}
                data-test-id={`obj-editor-details-${languageCode}`}
                label="Description"
                onChange={onLocalizedFormControlChange}
                lengthLimit={2000}
                value={version.localizedDetails[languageCode] ?? ''}
                name={`localizedDetails-${languageCode}`}
                as="textarea"
                rows={5}
                comment={MarkdownSupportComment()}
              />
              <Row className="m-0">
                <Col className="pb-0">
                  <FormInputLimited
                    isInvalid={errorValues.includes(`${label}-link-title`)}
                    data-test-id={`obj-editor-localizedLinkTitle-${languageCode}`}
                    label="Title link"
                    onChange={onLocalizedLinkChange}
                    lengthLimit={50}
                    value={version.localizedLink?.[languageCode]?.title ?? ''}
                    type="text"
                    name={`localizedLink-title-${languageCode}`}
                  />
                </Col>
                <Col className="pb-0">
                  <Form.Group className="mb-3">
                    <Form.Label>Link</Form.Label>
                    <Form.Control
                      isInvalid={errorValues.includes(`${label}-link-url`)}
                      data-test-id={`obj-editorr-localizedLinkUrl-${languageCode}`}
                      onChange={onLocalizedLinkChange}
                      name={`localizedLink-url-${languageCode}`}
                      type="text"
                      value={version.localizedLink?.[languageCode]?.url ?? ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Button
                variant="outline-primary"
                className="pt-0 pb-0"
                name={`translate-${languageCode}`}
                onClick={handleAutoTranslate}
              >
                Auto translate to other languages
              </Button>
            </Form>
          </div>
        )}
      />
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Coordinates</Form.Label>
          <Form.Control
            isInvalid={errorValues.includes('coordinates')}
            data-test-id="obj-editor-coordinates"
            onChange={handleLocationChange}
            name="coordinates"
            type="text"
            value={version.coordinates}
          />
          {!!object && (
            <>
              <Form.Label>Geography</Form.Label>
              <GeoDropdowns
                country={object.country}
                region={object.region}
                place={object.place}
                geoChange={handleGeoChange}
                disabled={isLoading}
              />
            </>
          )}
        </Form.Group>
        <Images
          id="extraPhotos"
          images={version.extraPhotos}
          onDeleteImage={handleDeleteImage}
          onAddImage={handleAddImage}
        />
        <FormInputLimited
          data-test-id="obj-editor-modelAuthor"
          isInvalid={errorValues.includes('modelAuthor')}
          label="3D model author (id or email)"
          onChange={onFormControlChange}
          lengthLimit={80}
          value={version.modelAuthor}
          type="text"
          name="modelAuthor"
        />
        <FormInputLimited
          data-test-id="obj-editor-modelAuthorNote"
          isInvalid={errorValues.includes('modelAuthorNote')}
          label="3D model author personal note"
          onChange={onFormControlChange}
          lengthLimit={300}
          value={version.modelAuthorNote}
          type="text"
          name="modelAuthorNote"
          as="textarea"
          rows={5}
        />
      </Form>
    </div>
  );
}

export default DraftForm;
