import { store } from '../index';
import { closeModalInfo, showModalInfo } from '../action-creators/modalInfo';
import React from 'react';
import Loading from '../../components/common/Loading';
import ErrorContent from '../../components/common/ErrorContent';

class ModalInfoService {
  showModalInfo = (content: React.ReactNode, centered?: boolean) => {
    store.dispatch(showModalInfo(content, centered));
  };

  showModalInfoLoading = () => {
    store.dispatch(showModalInfo(Loading()));
  };

  showModalInfoError = (text?: string) => {
    store.dispatch(showModalInfo(ErrorContent({ text })));
    setTimeout(() => this.closeModalInfo(), 5000);
  };

  closeModalInfo = () => {
    store.dispatch(closeModalInfo());
  };
}

export default new ModalInfoService();
