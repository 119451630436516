import { ICollectionShort } from './collections';

export interface IObjectCollections {
  id: string;
  collections: ICollectionShort[];
  wasChanged: boolean;
}

export enum ObjColActionTypes {
  SET_OBJ_COLLECTIONS = 'SET_OBJ_COLLECTIONS',
  SET_OBJ_ID = 'SET_OBJ_ID',
  CLEAR_OBJ_STORE = 'CLEAR_OBJ_STORE',
  CLEAR_OBJ_DATA = 'CLEAR_OBJ_DATA',
}

interface ISetObjCollections {
  type: ObjColActionTypes.SET_OBJ_COLLECTIONS;
  payload: { collections: ICollectionShort[]; wasChanged: boolean };
}

interface ISetObjId {
  type: ObjColActionTypes.SET_OBJ_ID;
  payload: string;
}

interface IClearObjStore {
  type: ObjColActionTypes.CLEAR_OBJ_STORE;
}

interface IClearObjData {
  type: ObjColActionTypes.CLEAR_OBJ_DATA;
}

export type IObjCollectionsAction = ISetObjCollections | ISetObjId | IClearObjStore | IClearObjData;
