import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from '../common/PrivateRoute';
import Wrapper from '../common/Wrapper';
import { navigation, navigationPages } from '../../types/navigation';
import Collection from '../Collection/Collection';
import CollectionDetails from '../Collection/CollectionDetails/CollectionDetails';
import CollectionEditor from '../Collection/CollectionEditor/CollectionEditor';
import { UserRole } from '../../types/userManagement';

const CollectionRoutes = [
  <Route
    key="/collections"
    path="/collections"
    element={
      <PrivateRoute>
        <Wrapper {...navigation[navigationPages.collections]}>
          <Collection />
        </Wrapper>
      </PrivateRoute>
    }
  />,
  <Route
    key="collections/new"
    path="collections/new"
    element={
      <PrivateRoute roles={[UserRole.Admin, UserRole.Editor]}>
        <CollectionEditor />
      </PrivateRoute>
    }
  />,
  <Route
    key="collections/:collectionId/edit"
    path="collections/:collectionId/edit"
    element={
      <PrivateRoute roles={[UserRole.Admin, UserRole.Editor]}>
        <CollectionEditor />
      </PrivateRoute>
    }
  />,
  <Route
    key="collections/:collectionId"
    path="collections/:collectionId"
    element={
      <PrivateRoute>
        <CollectionDetails />
      </PrivateRoute>
    }
  />,
];

export default CollectionRoutes;
