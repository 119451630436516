import { IComponentCellProps, ITableColumn } from '../../../types/common';
import React from 'react';
import CSImage from '../CSImage';
import ObjectStatus from '../ObjectStatus';

function Columns(extraWidth: number = 0): ITableColumn[] {
  return [
    {
      id: 'iconUrl',
      displayName: '',
      width: '24px',
      component: ImageCell,
    },
    {
      id: 'internalName',
      displayName: 'Title',
      width: `calc(100vw - 950px - ${extraWidth}px)`,
      minWidth: '150px',
    },
    {
      id: 'country',
      displayName: 'Country',
      width: '150px',
      pattern: (v) => (v ? v.displayName : ''),
    },
    {
      id: 'region',
      displayName: 'Region',
      width: '150px',
      pattern: (v) => (v ? v.displayName : ''),
    },
    {
      id: 'place',
      displayName: 'Place',
      width: '150px',
      pattern: (v) => (v ? v.displayName : ''),
    },
    {
      id: 'tags',
      displayName: 'Tags',
      width: '150px',
      pattern: (v) => v.join(', '),
    },
    {
      id: 'status',
      displayName: 'Status',
      width: '100px',
      component: ObjectStatus,
    },
    {
      id: 'draft',
      displayName: 'Draft',
      component: ObjectStatus,
      width: '60px',
    },
  ];
}

function ImageCell(props: IComponentCellProps) {
  return <CSImage alt="object" width="24px" src={props.value} />;
}

export default Columns;
