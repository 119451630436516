import React from 'react';
import ObjectsTable from './ObjectsTable/ObjectsTable';
import ObjectsMap from './ObjectsMap/ObjectsMap';
import { useTypedSelector } from '../../hooks/useTypedSelector';

function Objects() {
  const showMap = useTypedSelector((state) => state.navbar.map);

  return (
    <>
      {showMap && <ObjectsMap />}
      <ObjectsTable />
    </>
  );
}

export default Objects;
