interface INavbar {
  searchString: string;
  map: boolean;
}

interface INavbarAction {
  type: string;
  payload?: string;
}

const initialState: INavbar = {
  searchString: '',
  map: false,
};

export const navbarReducer = (state = initialState, action: INavbarAction): INavbar => {
  switch (action.type) {
    case 'SET_SEARCH': {
      return { ...state, searchString: action.payload || '' };
    }
    case 'SHOW_MAP': {
      return { ...state, map: true };
    }
    case 'HIDE_MAP': {
      return { ...state, map: false };
    }
    default:
      return state;
  }
};
