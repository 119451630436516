import React from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import AlertItem from './AlertItem';

function Alerts() {
  const alerts = useTypedSelector((state) => state.alerts.alerts);

  return (
    <div className="position-absolute top-0 end-0 mt-3" style={{ zIndex: 9999 }}>
      {alerts.map((alert) => (
        <AlertItem {...alert} key={alert.id} />
      ))}
    </div>
  );
}

export default Alerts;
