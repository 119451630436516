import { ITableColumn } from '../../../../types/common';

function Columns(): ITableColumn[] {
  return [
    {
      id: 'name',
      displayName: 'Customer facing name',
      width: '30vw',
    },
    {
      id: 'address',
      displayName: 'Customer facing address',
      width: '30vw',
    },
    {
      id: 'adminNotes',
      displayName: 'Admin comment',
      width: '30vw',
      pattern: (v) => v || '',
    },
  ];
}

export default Columns;
