import React, { useCallback, useEffect, useState } from 'react';
import styles from 'styles/details.module.css';
import LoadingWrapper from 'components/common/LoadingWrapper';
import { Button } from 'react-bootstrap';
import { IStatus } from 'types/common';
import { useNavigate, useParams } from 'react-router-dom';
import UserManagementForm from './UserManagementForm';
import { IUserEditor, userEditorInit, UserRole } from 'types/userManagement';
import { AlertType } from 'types/alerts';
import AlertsService from 'store/services/AlertsService';
import ModalInfoService from 'store/services/ModalInfoService';
import UserManagementService from 'services/UserManagement/UserManagementService';
import { useExitConfirmation } from 'hooks/useExitConfirmation';

function UserManagementEditor() {
  const { userId } = useParams<{ userId: string }>();
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [user, setUser] = useState<IUserEditor>(userEditorInit);
  const [errorValues, setErrorValues] = useState<string[]>([]);

  const navigate = useNavigate();

  const handleUserChange = useCallback((name: string, value: any) => {
    setHasChanges(true);
    setErrorValues((prevState) => (prevState.includes(name) ? prevState.filter((er) => er !== name) : prevState));
    setUser((prevState) => {
      const newUser = { ...prevState, [name]: value };
      if (name === 'role') {
        newUser.availableAdvertisers = [];
      }
      return newUser;
    });
  }, []);

  useEffect(() => {
    const loadUserData = async (id: string) => {
      try {
        return await UserManagementService.getUser(id);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    if (userId) {
      loadUserData(userId).then((res) => {
        if (res) {
          setUser(res);
          setStatus(IStatus.Success);
        }
      });
    } else {
      setStatus(IStatus.Success);
    }
  }, [userId]);

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const onSaveClick = () => {
    setErrorValues([]);
    let possible = true;
    if (!user.name) {
      setErrorValues((prevState) => [...prevState, 'name']);
      AlertsService.addAlert('Name field is empty', AlertType.WARNING);
      possible = false;
    }
    if (!user.email) {
      setErrorValues((prevState) => [...prevState, 'email']);
      AlertsService.addAlert('Email field is empty', AlertType.WARNING);
      possible = false;
    } else if (!isValidEmail(user.email)) {
      setErrorValues((prevState) => [...prevState, 'email']);
      AlertsService.addAlert('Email is not valid', AlertType.WARNING);
      possible = false;
    }
    if (user.role === UserRole.AdvertisingManager && !user.availableAdvertisers.length) {
      setErrorValues((prevState) => [...prevState, 'availableAdvertisers']);
      AlertsService.addAlert('At least one advertiser should be selected', AlertType.WARNING);
      possible = false;
    }
    if (!possible) return;
    ModalInfoService.showModalInfoLoading();
    (userId ? UserManagementService.updateUser(user) : UserManagementService.createUser(user)).then((res) => {
      if (res) {
        ModalInfoService.closeModalInfo();
        AlertsService.addAlert('User was successfully ' + (userId ? 'updated' : 'created'), AlertType.SUCCESS);
        handleBackToList();
      } else {
        ModalInfoService.showModalInfoError('We have problems saving user');
      }
    });
  };

  const handleBackToList = () => {
    navigate(`/usermanagement`);
  };

  const onCancelClick = useExitConfirmation({ shouldConfirm: hasChanges, onExit: handleBackToList });

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <div className={styles.w100EditorContainer}>
            <UserManagementForm user={user} userChange={handleUserChange} errorValues={errorValues} />
          </div>
        </LoadingWrapper>
      </div>
      <div className={`${styles.buttonContainer} d-flex justify-content-between`}>
        <Button data-test-id="user-editor-cancel" variant="light" size="sm" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button data-test-id="user-editor-save" variant="primary" size="sm" onClick={onSaveClick}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default UserManagementEditor;
