import React from 'react';

function PopoverObjects() {
  return (
    <div data-test-id="search-popover-objects">
      <div className="mb-3">Case insensitive, search everywhere, not only in title</div>
      <div className="mb-3">
        It is also possible to narrow down to specific fields by using field names. Supported field names:
        <ul>
          <li>title</li>
          <li>description</li>
          <li>country</li>
          <li>region</li>
          <li>place</li>
          <li>tags</li>
          <li>coupon</li>
          <li>
            status
            <ul>
              <li>allowed values draft, published, archived</li>
              <li>all other values are ignored</li>
              <li>
                this criteria matches <strong>all objects with at least one version</strong> in a requested status
              </li>
            </ul>
          </li>
          <li>
            location (latitude, longitude, radius)
            <ul>
              <li>third parameter is optional, default is 500m</li>
              <li>distance calculation is approximate, but sufficient for visual control</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="mb-3">Multiple search criteria should be separated by ampersand &</div>

      <hr />
      <h6>Examples</h6>
      <ul>
        <li>"test" - will find all objects with word "test" anywhere</li>
        <li>"title:test" - will find all objects containing "test" in title</li>
        <li>
          "title:test & country:India & tags: building" - will find all objects containing "test" in title located in
          India with tag building
        </li>
        <li>
          "location:(45.12363443, 64.24323632, 700)" - will find all objects within a 700 meter radius from the point
          with 45.12363443 latitude and 64.24323632 longitude
        </li>
      </ul>
    </div>
  );
}

export default PopoverObjects;
