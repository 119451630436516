import { IObjectState } from './objects';
import { IDisplayObject, IFileWithUrl, ILink, ILocalizedContent } from './common';

export interface ICollection {
  id: string;
  localizedName: ILocalizedContent<string>;
  iconUrl: string;
  country: IDisplayObject | null;
  region: IDisplayObject | null;
  place: IDisplayObject | null;
  tags: string[];
  status: ICollectionState;
  objectsCount: number;
  couponCampaignIds: string[];
}

export interface ICollectionShort {
  id: string;
  localizedName: ILocalizedContent<string>;
  iconUrl: string;
  saved?: boolean;
}
export enum ICollectionState {
  Archived = 'Archived',
  Published = 'Published',
  Draft = 'Draft',
}

export interface ICollectionDetails extends ICollection {
  localizedDetails: ILocalizedContent<string>;
  localizedLink: ILocalizedContent<ILink> | null;
  adminNotes: string;
  extraPhotos: string[];
  assetsBaseUrl: string;
  imageFile: string;
  iconFile: string;
  adminPictures: string[];
  statistic: {
    started: number;
    completed: number;
  } | null;
  sharingUrl?: string;
}

export interface ICollectionEditor {
  id: string;

  localizedName: ILocalizedContent<string>;
  localizedDetails: ILocalizedContent<string>;
  localizedLink: ILocalizedContent<ILink> | null;

  extraPhotos: IFileWithUrl[];
  country: IDisplayObject | null;
  region: IDisplayObject | null;
  place: IDisplayObject | null;
  tags: string[];
  adminNotes: string;
  adminPictures: IFileWithUrl[];
  imageFile: IFileWithUrl | null;
  status: ICollectionState;
  couponCampaignIds: string[];
}

export const initColItem: ICollectionEditor = {
  adminNotes: '',
  adminPictures: [],
  country: null,
  extraPhotos: [],
  id: '',
  imageFile: null,
  place: null,
  region: null,
  status: ICollectionState.Draft,
  tags: [],
  couponCampaignIds: [],
  localizedName: {
    en: '',
    ja: '',
    es: '',
    ru: '',
  },
  localizedDetails: {
    en: '',
    ja: '',
    es: '',
    ru: '',
  },
  localizedLink: null,
};

export interface IColObjects {
  id: string;
  status: ICollectionState;
  objects: IColObjectItem[];
  wasChanged: boolean;
}

export interface IColObjectItem {
  id: string;
  internalName: string;
  iconUrl: string;
  status: IObjectState;
}

export enum ColObjectsActionTypes {
  SET_OBJECTS = 'SET_OBJECTS',
  SET_STATUS = 'SET_STATUS',
  SET_ID = 'SET_ID',
  REMOVE_OBJECT = 'REMOVE_OBJECT',
  CLEAR_COL_STORE = 'CLEAR_COL_STORE',
}

interface ISetObjectsAction {
  type: ColObjectsActionTypes.SET_OBJECTS;
  payload: { objects: IColObjectItem[]; wasChanged: boolean };
}

interface ISetStatusAction {
  type: ColObjectsActionTypes.SET_STATUS;
  payload: ICollectionState;
}

interface ISetIdAction {
  type: ColObjectsActionTypes.SET_ID;
  payload: string;
}

interface IClearColStoreAction {
  type: ColObjectsActionTypes.CLEAR_COL_STORE;
}

interface IRemoveObjectAction {
  type: ColObjectsActionTypes.REMOVE_OBJECT;
  payload: { id: string; affectChange: boolean };
}

export type IColObjectsAction =
  | ISetObjectsAction
  | IRemoveObjectAction
  | ISetStatusAction
  | ISetIdAction
  | IClearColStoreAction;
