import { LocalizationTabs } from 'components/common/LocalizationTabs';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { ModalActionTypes } from 'types/modal';
import PrivateAccess from 'components/common/PrivateAccess';
import { UserRole } from 'types/userManagement';
import { IFeaturedData } from 'types/objects';
import ObjectAdminService from 'services/Objects/ObjectAdminService';
import AlertsService from 'store/services/AlertsService';
import styles from 'components/common/ObjectCollection/ObjectCollection.module.css';
import commonStyles from 'styles/common.module.css';
import { AlertType } from '../../../../types/alerts';

interface IFeaturedInfoProps {
  objectId?: string;
  featuredData: IFeaturedData | null;
}

export const FeaturedInfo = ({ objectId, featuredData }: IFeaturedInfoProps) => {
  const dispatch = useDispatch();

  if (!objectId) {
    return null;
  }

  const isActive = Boolean(featuredData?.isActive);

  return (
    <div className="align-items-center ps-4">
      <h3>Featured Trophy</h3>
      <p className="py-3">
        Featured:{' '}
        {isActive ? (
          <>
            <span>Yes</span>
            <PrivateAccess roles={[UserRole.Admin, UserRole.Editor]}>
              <Button
                size="sm"
                className="ms-3"
                onClick={() =>
                  dispatch({
                    type: ModalActionTypes.SHOW_MODAL,
                    payload: {
                      title: 'Please confirm',
                      text: 'This trophy will be shown to all CyberTrophy users in featured trophy section right away',
                      primaryText: 'Yes',
                      secondaryText: 'No',
                      primaryAction: async () => {
                        try {
                          await ObjectAdminService.pushObjectAsFeatured(objectId);
                          AlertsService.addAlert('Trophy has been successfully pushed as featured.', AlertType.SUCCESS);
                        } catch (error) {
                          AlertsService.addAlert(
                            'An error occurred while pushing the trophy as featured.',
                            AlertType.ERROR,
                          );
                        }
                      },
                    },
                  })
                }
              >
                Push as featured now
              </Button>
            </PrivateAccess>
          </>
        ) : (
          'No'
        )}
      </p>
      <LocalizationTabs
        renderTabContent={(languageCode, label) => (
          <div className="py-3 pe-3">
            <div className={styles.textBlock}>
              <h6>Fact title</h6>
              <div>{featuredData?.localizedFeaturedTitle?.[languageCode] || ''}</div>
            </div>
            <div className={styles.textBlock}>
              <h6>Fact description</h6>
              <div className={commonStyles.whiteSpacePreLine}>
                <ReactMarkdown skipHtml>
                  {featuredData?.localizedFeaturedDescription?.[languageCode] || ''}
                </ReactMarkdown>
              </div>
              <div></div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
