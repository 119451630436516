import axios from 'axios';
import constants from '../../constants';
import { IObject, IObjectState, ITrophyExportRecord } from '../../types/objects';

interface IGetObjParams {
  skip?: number;
  limit?: number;
  text?: string;
  collectionId?: string | null;
  scheduleId?: string | null;
}

let controller: AbortController | null;

let mapController: AbortController | null;

class ObjectsService {
  getTrophyCollectionHistory(search: string): Promise<ITrophyExportRecord[]> {
    let url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/ListCollectionsHistory`;
    return axios
      .get(url, {
        params: {
          search: search || ' ',
        },
      })
      .then((res) => {
        return res.data.map(
          (d: any): ITrophyExportRecord => ({
            ...d,
            timestamp: `${new Date(d.timestamp).toDateString()} ${new Date(d.timestamp).toTimeString()}`,
          }),
        );
      });
  }
  getObjects = (
    skip: number,
    limit: number,
    text: string,
    collectionId: string | null = null,
    scheduleId: string | null = null,
  ) => {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/GetObjectsList`;

    const params: IGetObjParams = { skip, limit, collectionId, scheduleId };
    if (text.length) {
      params.text = text;
    }

    const getStatus = (published: boolean, archived: boolean): IObjectState => {
      if (published) {
        return IObjectState.Published;
      }
      if (archived) {
        return IObjectState.Archived;
      }
      return IObjectState.New;
    };

    return axios.get(url, { params, signal: controller.signal }).then((res) => {
      let data: IObject[];
      data =
        res?.data.map((d: any): IObject[] => ({
          ...d,
          status: getStatus(d.hasPublished, d.hasArchived),
          draft: d.hasDraft ? IObjectState.Draft : null,
        })) ?? [];
      controller = null;
      return data;
    });
  };

  getByIdList = (ids: string[]) => {
    const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/GetByIdList`;

    return axios.post(url, { ids }).then((res) => res.data);
  };

  getMapObjects = (text: string, collectionId: string | null = null, scheduleId: string | null = null) => {
    mapController = new AbortController();
    const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/GetObjectsListForMap`;

    const params: IGetObjParams = { text, collectionId, scheduleId };

    return axios.get(url, { params, signal: mapController.signal }).then((res) => {
      mapController = null;
      return res.data;
    });
  };

  abortGetMapObjects = () => {
    if (mapController) {
      mapController.abort();
    }
  };
}

export default new ObjectsService();
