import React, { useCallback } from 'react';
import CSTable from '../../../common/CSTable/CSTable';
import Columns from './SponsorshipTableColumns';
import { useParams } from 'react-router-dom';
import SponsorshipService from '../../../../services/Advertisers/SponsorshipService';

function SponsorshipList() {
  let { adId } = useParams<{ adId: string }>();
  const columns = Columns();

  const getData = useCallback(() => SponsorshipService.getSponsorships(adId), [adId]);

  const onRowClick = (id: string) => {
    //navigate(`${id}`);
    alert('Editing is not supported, instead you can remove the sponsorship and create a new one');
  };

  const onDeleteSponsorship = async (sponsorshipId: string) => {
    if (adId) await SponsorshipService.deleteSponsorship(sponsorshipId);
  };

  const emptyTable = () => {
    return (
      <div className="d-flex justify-content-center m-5">
        <div>This advertiser does not have any sponsorships yet</div>
      </div>
    );
  };

  return (
    <CSTable
      dataTestId="coupons-list"
      columns={columns}
      loadDataFunction={getData}
      onRowClick={onRowClick}
      onDeleteClick={onDeleteSponsorship}
      height="calc(100vh - 80px)"
      emptyComponent={emptyTable()}
    />
  );
}

export default SponsorshipList;
