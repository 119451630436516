export interface IPaging {
  skip: number;
  limit: number;
}

export interface ITableColumn {
  id: string;
  displayName: string;
  width?: string;
  minWidth?: string;
  sort?: boolean;
  filter?: any;
  component?: (props: any) => JSX.Element;
  pattern?: (v: any, row: any) => {};
}

export interface IComponentCellProps {
  value: any;
  row?: any;
}

export enum IStatus {
  Success,
  Error,
  Loading,
}

export enum IAttachmentType {
  Model = 'Model',
  IOSModel = 'IOSModel',
  ModelImage = 'ModelImage',
  ModelIcon = 'ModelIcon',
  PublicPhoto = 'PublicPhoto',
  AdminPhoto = 'AdminPhoto',
}

export enum IMimeType {
  model = 'model/gltf-binary',
  iOSModel = 'model/vnd.usdz+zip',
  imagePng = 'image/png',
  imageJpeg = 'image/jpeg',
}

export enum ILanguageCode {
  English = 'en',
  Japanese = 'ja',
  Spanish = 'es',
  Russian = 'ru',
}

export interface IGetFileUploadUrl {
  targetId: string;
  attachmentType: IAttachmentType;
  mimeType: IMimeType;
}

export interface IReceiveFileUploadUrl {
  presignedUploadUrl: string;
  permanentUrl: string;
  fileName: string;
}

export interface IFileWithUrl {
  url: string;
  name?: string;
  file?: File;
}

export interface IDictionaryName {
  id: string;
  name: string;
}

export interface IDisplayObject {
  id: string;
  displayName: string;
}

export interface ILocalizedObject {
  id: string;
  localizedName: ILocalizedContent<string>;
}

export class ILocalizedContent<T> {
  en: T;
  ja: T;
  es: T;
  ru: T;
}

export interface ILink {
  url: string;
  title: string;
}
