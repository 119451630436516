import { ReactNode, forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import commonStyles from '../../styles/common.module.css';

interface IProp {
  label?: string;
  onChange: any;
  lengthLimit: number;
  value: string;
  comment?: ReactNode;
  required?: boolean;
  [propName: string]: any;
}
const FormInputLimited = forwardRef<HTMLInputElement, IProp>(
  ({ label, onChange, lengthLimit, value, required, ...props }: IProp, ref) => {
    const onInputChange = (e: any) => {
      e.target.value = e.target.value.slice(0, lengthLimit);
      onChange(e);
    };

    const renderRequiredMarker = () => {
      if (required) return <span className="text-danger fst-italic">(required)</span>;
      return <span />;
    };

    return (
      <Form.Group>
        <div>
          {label && (
            <Form.Label>
              {label} {renderRequiredMarker()}
            </Form.Label>
          )}
        </div>

        <Form.Control
          {...props}
          className={commonStyles.whiteSpacePreLine}
          onChange={onInputChange}
          value={value}
          ref={ref}
        />

        <div className="d-flex justify-content-between align-items-center">
          <Form.Text className="text-muted">{props.comment}</Form.Text>
          <Form.Label className="text-muted mb-0">
            <small>
              {' '}
              {value.length}/{lengthLimit}
            </small>
          </Form.Label>
        </div>
      </Form.Group>
    );
  },
);

export default FormInputLimited;
