import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from '../common/PrivateRoute';
import Wrapper from '../common/Wrapper';
import Objects from '../Objects/Objects';
import Object from '../Object/Object';
import { ObjectPageState } from '../../types/objects';
import { navigation, navigationPages } from '../../types/navigation';
import { UserRole } from '../../types/userManagement';

const ObjectRoutes = [
  <Route
    key="/objects"
    path="/objects"
    element={
      <PrivateRoute>
        <Wrapper {...navigation[navigationPages.objects]}>
          <Objects />
        </Wrapper>
      </PrivateRoute>
    }
  />,
  <Route
    key="objects/:objectId"
    path="objects/:objectId"
    element={
      <PrivateRoute>
        <Object />
      </PrivateRoute>
    }
  />,
  <Route
    key="objects/new"
    path="objects/new"
    element={
      <PrivateRoute roles={[UserRole.Admin, UserRole.Editor]}>
        <Object state={ObjectPageState.newObject} />
      </PrivateRoute>
    }
  />,
];

export default ObjectRoutes;
