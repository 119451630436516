import React from 'react';
import { Popover } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styles from './Search.module.css';

interface IProps {
  popoverBody: JSX.Element;
}

function SearchInfo({ popoverBody }: IProps) {
  const popover = (
    <Popover style={{ maxWidth: '440px' }} className="shadow">
      <Popover.Header as="h3">Search</Popover.Header>
      <Popover.Body> {popoverBody}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose={true}>
      <div data-test-id="nav-search-info" className={styles.questionIcon}>
        ?
      </div>
    </OverlayTrigger>
  );
}

export default SearchInfo;
