import React, { useCallback, useEffect, useState } from 'react';
import { IAlertItem } from '../../../types/alerts';
import { Alert } from 'react-bootstrap';
import AlertsService from 'store/services/AlertsService';
import commonStyles from '../../../styles/common.module.css';

function AlertItem(alert: IAlertItem) {
  const [handleShow, setHandleShow] = useState<boolean>(false);
  const deleteAlert = useCallback((id: string) => {
    AlertsService.deleteAlert(id);
  }, []);

  useEffect(() => {
    if (!handleShow) {
      setTimeout(() => deleteAlert(alert.id), alert.delay ?? 10000);
      setHandleShow(true);
    }
  }, [alert.delay, alert.id, deleteAlert, handleShow]);

  return (
    <Alert
      style={{ width: '350px' }}
      variant={alert.type || 'secondary'}
      onClose={() => deleteAlert(alert.id)}
      dismissible
    >
      <div className={commonStyles.whiteSpacePreLine}>{alert.text}</div>
    </Alert>
  );
}

export default AlertItem;
