import React, { useEffect, useState } from 'react';
import { ICollectionConditions } from '../../../types/objects';

import ObjectAdminService from '../../../services/Objects/ObjectAdminService';

const CollectionConditionsDisplay = (props: ICollectionConditions) => {
  const [requiredObjectName, setRequiredObjectName] = useState<string>('');
  useEffect(() => {
    if (props.requiredObjectId) {
      ObjectAdminService.getObjectAdmin(props.requiredObjectId).then((res) => {
        if (res) {
          setRequiredObjectName(res.internalName);
        }
      });
    }
  }, [props]);

  return (
    <div>
      <h6>Collection conditions</h6>
      <div data-test-id="collection-conditions">
        {props.maxCollections ? (
          <div className="mb-3">
            Max times a trophy can be collected: <strong>{props.maxCollections}</strong>
          </div>
        ) : (
          <div className="mb-3">No collecting limits</div>
        )}
        {props.answer ? (
          <div className="mb-3">
            <span>User should answer a question to collect this object</span>
            <div>Question: {props.question || 'Not set (app will display default question)'}</div>
            <div>
              Answer: <strong>{props.answer}</strong>
            </div>
          </div>
        ) : (
          <div className="mb-3">No Q&A condition</div>
        )}
        {props.requiredObjectId ? (
          <div className="mb-3">
            Before collecting this object, a user should collect{' '}
            <strong>
              <a rel="noreferrer" target="_blank" href={'/objects/' + props.requiredObjectId}>
                {requiredObjectName}
              </a>
            </strong>
          </div>
        ) : (
          <div className="mb-3">No trophy condition</div>
        )}
      </div>
    </div>
  );
};

// Prop Types for component validation

export default CollectionConditionsDisplay;
