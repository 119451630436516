import axios from 'axios';
import constants from '../../constants';
import { IAdUserDisplayItem, IAdUserForm } from '../../types/advertisers';

class AdUsersService {
  createUser = async (user: IAdUserForm) => {
    try {
      const url = `${constants.API_ADMIN_AD_USERS}/CreateUser`;

      var createData = {
        advertiserId: user.advertiserId,
        name: user.name,
        email: user.email,
        languageCode: user.languageCode,
      };

      const newId = await axios.post(url, createData).then((res) => res.data.id);

      return newId;
    } catch {
      return false;
    }
  };

  getUsers = (advertiserId: any) => {
    const url = `${constants.API_ADMIN_AD_USERS}/List?advertiserId=${advertiserId}`;

    return axios.get(url).then((res): IAdUserDisplayItem[] => res.data);
  };

  deleteUser = async (id: string, advertiserId: string) => {
    const url = `${constants.API_ADMIN_AD_USERS}/DeleteUser?userId=${id}&advertiserId=${advertiserId}`;
    try {
      await axios.delete(url).then();
      return true;
    } catch {
      return false;
    }
  };
}

export default new AdUsersService();
