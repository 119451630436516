import constants from '../../constants';
import axios from 'axios';
import { ICollectionShort, IColObjectItem } from '../../types/collections';
import { IObjectState } from '../../types/objects';
import HelperService from '../HelperService';

class CollectionObjectsService {
  getCollectionsByObject = (objectId: string): Promise<ICollectionShort[]> => {
    const url = `${constants.API_ADMIN_COLLECTIONS}/getCollections?objectId=${objectId}`;
    return axios.get(url).then((res) =>
      res.data.map(
        (d: any) =>
          ({
            id: d.id,
            localizedName: d.localizedName,
            iconUrl: HelperService.getAssetUrl(d.assetsBaseUrl, d.iconFile),
            saved: true,
          } as ICollectionShort),
      ),
    );
  };
  getObjects = (collectionId: string) => {
    const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/GetObjectsList`;
    const params = { collectionId };

    const getStatus = (published: boolean, archived: boolean): IObjectState => {
      if (published) {
        return IObjectState.Published;
      }
      if (archived) {
        return IObjectState.Archived;
      }
      return IObjectState.New;
    };

    return axios.get(url, { params }).then((res) => {
      return res.data.map(
        (d: any): IColObjectItem => ({
          id: d.id,
          internalName: d.internalName,
          iconUrl: d.iconUrl,
          status: getStatus(d.hasPublished, d.hasArchived),
        }),
      );
    });
  };

  updateCollectionObjects = async (collectionId: string, objectIds: string[], rewrite: boolean = true) => {
    const url = `${constants.API_ADMIN_COLLECTIONS}/AssignObjects`;
    try {
      await axios
        .post(url, {
          collectionId,
          objectIds,
          updateMode: rewrite ? 'Rewrite' : 'Merge',
        })
        .then();
      return true;
    } catch {
      return false;
    }
  };
}

export default new CollectionObjectsService();
