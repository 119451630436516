import React from 'react';
import { IScheduleRecord } from '../../../types/visibilitySchedule';
import { Form, ButtonGroup, Button, Stack } from 'react-bootstrap';
import { TimePicker } from '../../common/TimePicker/TimePicker';
import { BsTrash } from 'react-icons/bs';
import moment from 'moment';

interface IProps {
  record: IScheduleRecord;
  timezone: string;
  recordChanged: (name: string, value: any) => void;
  recordRemoved: () => void;
}
function VisibilityScheduleEditorFormRecord({ record, timezone, recordChanged, recordRemoved }: IProps) {
  const date = moment(record.startOn).tz(timezone).format('yyyy-MM-DD');
  const time = moment(record.startOn).tz(timezone).format('HH:mm');

  const onVisibilityButtonClick = (visible: boolean, collectable: boolean) => {
    recordChanged('visible', visible);
    recordChanged('collectable', collectable);
  };

  const onDateChanged = (evt: any) => {
    // create new value out of new date and previous time
    const newDate = moment(evt.target.value).tz(timezone);
    const newTime = moment(record.startOn).tz(timezone);
    const newDateTime = newDate.set({
      hour: newTime.hour(),
      minute: newTime.minute(),
    });
    recordChanged('startOn', newDateTime.valueOf());
  };

  const onTimeChaged = (seconds: number) => {
    // create new value out of new date and previous time
    const newDate = moment(record.startOn).tz(timezone);
    const newDateTime = newDate
      .set({
        hour: 0,
        minute: 0,
      })
      .add(seconds, 'seconds');
    recordChanged('startOn', newDateTime.valueOf());
  };

  return (
    <div>
      <Stack direction="horizontal" gap={3} style={{ minHeight: '45px' }}>
        <Form.Control
          value={date}
          onChange={onDateChanged}
          // className={styles.visibilityScheduleDatePicker}
          type="date"
        />

        <TimePicker
          onChange={onTimeChaged}
          value={time}

          //className={styles.visibilityScheduleTimePicker}
        />

        <ButtonGroup>
          <Button
            onClick={() => onVisibilityButtonClick(false, false)}
            variant={!record.visible && !record.collectable ? 'primary' : 'outline-secondary'}
          >
            Hidden
          </Button>
          <Button
            onClick={() => onVisibilityButtonClick(true, false)}
            variant={record.visible && !record.collectable ? 'primary' : 'outline-secondary'}
          >
            Visible
          </Button>
          <Button
            onClick={() => onVisibilityButtonClick(true, true)}
            variant={record.visible && record.collectable ? 'primary' : 'outline-secondary'}
          >
            Collectable
          </Button>
        </ButtonGroup>

        <Button variant="danger" onClick={recordRemoved}>
          <BsTrash />
        </Button>
      </Stack>
    </div>
  );
}

export default VisibilityScheduleEditorFormRecord;
