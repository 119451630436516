import { ObjColActionTypes } from '../../types/objectRedux';
import { ICollectionShort } from '../../types/collections';

export function setObjId(id: string) {
  return { type: ObjColActionTypes.SET_OBJ_ID, payload: id };
}

export function setObjCollections(collections: ICollectionShort[], wasChanged: boolean = false) {
  return {
    type: ObjColActionTypes.SET_OBJ_COLLECTIONS,
    payload: { collections, wasChanged },
  };
}

export function clearObjStore() {
  return { type: ObjColActionTypes.CLEAR_OBJ_STORE };
}

export function clearObjData() {
  return { type: ObjColActionTypes.CLEAR_OBJ_DATA };
}
