import React, { useEffect, useState } from 'react';
import styles from '../Object.module.css';
import { Button } from 'react-bootstrap';
import DraftForm from './DraftForm';
import ObjectPublicService from 'services/Objects/ObjectPublicService';
import { ILanguageCode, IStatus } from 'types/common';
import Loading from 'components/common/Loading';
import { initObjectVersionForm, IObjectAdmin, IObjectModel, IObjectVersionForm } from 'types/objects';
import DraftModel from './DraftModel';
import { AlertType } from 'types/alerts';
import { useNavigate } from 'react-router-dom';
import HelperService from 'services/HelperService';
import ObjectEditorService from 'services/Objects/ObjectEditorService';
import AlertsService from 'store/services/AlertsService';
import LoadingWrapper from 'components/common/LoadingWrapper';
import ModalInfoService from 'store/services/ModalInfoService';
import CollectorsService from 'services/CollectorsService';
import ObjectAdminService from 'services/Objects/ObjectAdminService';
import { useExitConfirmation } from 'hooks/useExitConfirmation';

interface IProps {
  isNewVersion?: boolean;
  versionId?: string | null;
  backToInfo: () => void;
  backToList?: () => void;
}

function Draft({ versionId, backToInfo, backToList = () => {}, isNewVersion = true }: IProps) {
  const [status, setStatus] = useState<IStatus>(versionId ? IStatus.Loading : IStatus.Success);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [version, setVersion] = useState<IObjectVersionForm>(initObjectVersionForm);
  const [object, setObject] = useState<IObjectAdmin>(null);
  const [model, setModel] = useState<IObjectModel>({});
  const [modelIsReady, setModelIsReady] = useState<boolean>(false);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadVersionData = async (id: string) => {
      try {
        const res = await ObjectPublicService.getVersion(id);
        setStatus(IStatus.Success);
        return res;
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };

    const loadObjectData = async (id: string) => {
      try {
        return await ObjectAdminService.getObjectAdmin(id);
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };

    if (versionId) {
      loadVersionData(versionId).then((res) => {
        if (res) {
          setVersion(res.version);
          setModelFiles(res.versionFiles, res.version.animated);

          if (res.version.objectId) {
            loadObjectData(res.version.objectId).then((res) => {
              if (res) {
                setObject(res);
              }
            });
          }
        }
      });
    } else {
      setModelIsReady(true);
    }
  }, [versionId]);

  const setModelFiles = async (versionFiles: any, animated: boolean) => {
    const { model, iosModel, image, icon } = versionFiles;
    const modelObj: IObjectModel = { rotation: 0 };
    if (await HelperService.isFileExist(model)) modelObj.model = { url: model };
    if (await HelperService.isFileExist(iosModel)) modelObj.iosModel = { url: iosModel };
    if (await HelperService.isFileExist(image)) modelObj.image = { url: image };
    if (await HelperService.isFileExist(icon)) modelObj.icon = { url: icon };
    modelObj.animated = animated;
    setModel(modelObj);
    setModelIsReady(true);
  };

  const handleVersionChange = (values: { name: string; value: any }[]) => {
    setHasChanges(true);
    const newVersion = { ...version };
    values.forEach((v) => {
      newVersion[v.name] = v.value;
      errorValues.includes(v.name) && setErrorValues((prevState) => prevState.filter((er) => er !== v.name));
    });
    setVersion(newVersion);
  };

  const handleModelChange = (values: { name: string; value: any }[]) => {
    setHasChanges(true);
    const newModel = { ...model };
    values.forEach((v) => (newModel[v.name] = v.value));
    setModel(newModel);
  };

  const handleObjectChange = (values: { name: string; value: any }[]) => {
    setHasChanges(true);

    var newObject = {};
    values.forEach((v) => {
      var tmp = { [v.name]: v.value };
      newObject = { ...newObject, ...tmp };
    });

    setObject({ ...object, ...newObject });
  };

  const onSaveClick = async () => {
    ModalInfoService.showModalInfoLoading();
    const { possible, modelAuthorId } = await possibleToSave(version);
    if (possible) {
      versionId ? handleSaveVersion(versionId, modelAuthorId) : handleCreateObject(modelAuthorId);
    } else {
      ModalInfoService.closeModalInfo();
    }
  };

  const possibleToSave = async (version: IObjectVersionForm) => {
    setErrorValues([]);
    let possible = true;
    let modelAuthorId = '';

    Object.entries(ILanguageCode).forEach(([label, languageCode]) => {
      if (!version.localizedName[languageCode]) {
        setErrorValues((prevState) => [...prevState, `${label}-title`]);
        possible = false;
        AlertsService.addAlert(`${label} title is empty`, AlertType.WARNING);
      }
    });

    if (!HelperService.isGeoStringValid(version.coordinates)) {
      setErrorValues((prevState) => [...prevState, 'coordinates']);
      AlertsService.addAlert('Coordinates are not valid', AlertType.WARNING);
      possible = false;
    }
    if (model && model.model && !model.iosModel) {
      setErrorValues((prevState) => [...prevState, 'model']);
      AlertsService.addAlert('iOS model is empty', AlertType.WARNING);
      possible = false;
    }
    if (version.modelAuthor) {
      await (version.modelAuthor.includes('@')
        ? CollectorsService.getCollectorByEmail(version.modelAuthor)
        : CollectorsService.getCollectorById(version.modelAuthor)
      ).then((res) => {
        if (res) {
          modelAuthorId = res.id;
        } else {
          setErrorValues((prevState) => [...prevState, 'modelAuthor']);
          AlertsService.addAlert('Model author not found', AlertType.WARNING);
          possible = false;
        }
      });
    }
    if (version.modelAuthorNote && !version.modelAuthor) {
      setErrorValues((prevState) => [...prevState, 'modelAuthorNote', 'modelAuthor']);
      AlertsService.addAlert("Author personal note won't be saved without author", AlertType.WARNING);
      possible = false;
    }
    return { possible, modelAuthorId };
  };

  const handleSaveVersion = (id: string, modelAuthorId: string) => {
    if (object && version.objectId) {
      ModalInfoService.showModalInfoLoading();

      ObjectAdminService.editorSaveObject(version.objectId, object)
        .then((res) => {
          if (res) {
            ObjectEditorService.editorSaveVersion(id, version, model, modelAuthorId, isNewVersion)
              .then((res) => {
                if (res) {
                  ModalInfoService.closeModalInfo();
                  backToInfo();
                } else {
                  ModalInfoService.showModalInfoError('We have problems saving version');
                }
              })
              .catch(() => ModalInfoService.closeModalInfo());
          } else {
            ModalInfoService.showModalInfoError('We have problems saving object data');
          }
        })
        .catch(() => ModalInfoService.closeModalInfo());
    }
  };

  const handleCreateObject = (modelAuthorId: string) => {
    ObjectEditorService.editorCreateObject(version, model, modelAuthorId)
      .then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          navigate(`/objects/${res}`);
          backToInfo();
        } else {
          ModalInfoService.showModalInfoError('We have problems saving version');
        }
      })
      .catch(() => ModalInfoService.closeModalInfo());
  };

  const onCancelClick = useExitConfirmation({ shouldConfirm: hasChanges, onExit: versionId ? backToInfo : backToList });

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <>
            <div className={styles.formContainer}>
              <DraftForm
                errorValues={errorValues}
                versionChange={handleVersionChange}
                version={version}
                object={object}
                objectChange={handleObjectChange}
              />
            </div>
            <div className={styles.fileContainer}>
              {modelIsReady ? <DraftModel model={model} modelChange={handleModelChange} /> : <Loading />}
            </div>
          </>
        </LoadingWrapper>
      </div>
      <div className={`${styles.buttonContainer} d-flex justify-content-between`}>
        <Button variant="light" size="sm" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={onSaveClick}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default Draft;
