import React from 'react';
import CSTable from '../../common/CSTable/CSTable';
import AdvertiserService from '../../../services/Advertisers/AdvertiserService';
import Columns from './AdvertisersTableColumns';
import { useNavigate } from 'react-router-dom';

function AdvertisersTable() {
  const navigate = useNavigate();
  const columns = Columns();

  const onRowClick = (id: string) => {
    navigate(`/advertisers/${id}/coupons`);
  };

  return (
    <CSTable
      dataTestId="advertisers-list"
      columns={columns}
      loadDataFunction={AdvertiserService.getAdvertisers}
      onRowClick={onRowClick}
    />
  );
}

export default AdvertisersTable;
