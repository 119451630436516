import { useEffect, MouseEventHandler, useMemo } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { Controller, useForm, useWatch, SetValueConfig } from 'react-hook-form';
import AlertsService from 'store/services/AlertsService';
import { AlertType } from 'types/alerts';
import { ILanguageCode, ILocalizedContent } from 'types/common';
import { IFeaturedData } from 'types/objects';
import { LocalizationTabs } from 'components/common/LocalizationTabs';
import FormInputLimited from 'components/common/FormInputLimited';
import MarkdownSupportComment from 'components/common/MarkdownSupportComment';
import ModalInfoService from 'store/services/ModalInfoService';
import TranslationService from 'services/TranslationService';
import { useExitConfirmation } from 'hooks/useExitConfirmation';
import { EditorLayout } from './EditorLayout';
import { useObjectEditor, saveObject } from './useObjectEditor';

interface IFeaturedInfoEditorProps {
  backToInfo: () => void;
}

const validateFactTitle = (value: string, languageLabel: string) => {
  if (!value || !value.trim().length) {
    return `${languageLabel} fact title is empty`;
  }

  if (value.length > 80) {
    return `${languageLabel} fact title is too long`;
  }

  return true;
};

const validateFactDescription = (value: string, languageLabel: string) => {
  if (!value || !value.trim().length) {
    return `${languageLabel} fact description is empty`;
  }

  if (value.length > 2000) {
    return `${languageLabel} fact description is too long`;
  }

  return true;
};

const setTranslatedValueOptions: SetValueConfig = { shouldDirty: true, shouldTouch: true, shouldValidate: true };
const getEmptyLocalizedValue = () =>
  Object.values(ILanguageCode).reduce((acc, code) => ({ ...acc, [code]: '' }), {}) as ILocalizedContent<string>;

export const FeaturedInfoEditor = ({ backToInfo }: IFeaturedInfoEditorProps) => {
  const { status, object } = useObjectEditor(backToInfo);
  const values = useMemo<IFeaturedData>(
    () => ({
      isActive: Boolean(object?.featuredData?.isActive),
      isForceShow: Boolean(object.featuredData?.isForceShow),
      localizedFeaturedTitle: object.featuredData?.localizedFeaturedTitle || getEmptyLocalizedValue(),
      localizedFeaturedDescription: object.featuredData?.localizedFeaturedDescription || getEmptyLocalizedValue(),
    }),
    [object],
  );
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
    clearErrors,
    getValues,
    setValue,
  } = useForm<IFeaturedData>({
    mode: 'onTouched',
    values,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const isActive = useWatch({
    control,
    name: 'isActive',
  });

  useEffect(() => {
    if (!isActive) {
      clearErrors();
    }
  }, [isActive, clearErrors]);

  const onCancelClick = useExitConfirmation({ shouldConfirm: isDirty, onExit: backToInfo });

  // TODO: Refactor this
  const handleAutoTranslate: MouseEventHandler<HTMLButtonElement> = async (event) => {
    const sourceLanguageCode = (event.target as HTMLButtonElement).dataset.language as ILanguageCode;
    const sourceLanguageLabel = (event.target as HTMLButtonElement).dataset.label as string;
    if (!sourceLanguageCode) {
      AlertsService.addAlert('Source language is not specified.', AlertType.WARNING);
      return;
    }

    const formValues = getValues();
    const sourceTitle = formValues.localizedFeaturedTitle[sourceLanguageCode];
    const sourceDescription = formValues.localizedFeaturedDescription[sourceLanguageCode];

    const errors = [
      validateFactTitle(sourceTitle, sourceLanguageLabel),
      validateFactDescription(sourceDescription, sourceLanguageLabel),
    ].filter((error) => error !== true);
    if (errors.length) {
      errors.forEach((error) => AlertsService.addAlert(error as string, AlertType.WARNING));
      AlertsService.addAlert('Please fix errors before translating.', AlertType.WARNING);
      return;
    }

    const nonEmptyTargetLanguages = [];
    for (const [languageLabel, languageCode] of Object.entries(ILanguageCode)) {
      if (
        languageCode !== sourceLanguageCode &&
        (formValues.localizedFeaturedTitle[languageCode] || formValues.localizedFeaturedDescription[languageCode])
      ) {
        nonEmptyTargetLanguages.push(languageLabel);
      }
    }

    if (
      !nonEmptyTargetLanguages.length ||
      window.confirm(
        `Please, use with caution: content on ${nonEmptyTargetLanguages.join(
          ', ',
        )} languages will be replaced with auto-translated version.`,
      ) === true
    ) {
      ModalInfoService.showModalInfoLoading();

      const translationPromises = Object.values(ILanguageCode)
        .filter((languageCode) => languageCode !== sourceLanguageCode)
        .map(async (targetLanguageCode) => {
          const translatedTitle = await TranslationService.translateText(
            sourceTitle,
            sourceLanguageCode,
            targetLanguageCode,
          );
          const translatedDescription = await TranslationService.translateText(
            sourceDescription,
            sourceLanguageCode,
            targetLanguageCode,
          );

          if (translatedTitle) {
            setValue(
              `localizedFeaturedTitle.${targetLanguageCode}`,
              translatedTitle as never,
              setTranslatedValueOptions,
            );
          }

          if (translatedDescription) {
            setValue(
              `localizedFeaturedDescription.${targetLanguageCode}`,
              translatedDescription as never,
              setTranslatedValueOptions,
            );
          }
        });

      await Promise.all(translationPromises);

      ModalInfoService.closeModalInfo();
    }
  };

  return (
    <EditorLayout
      loadingStatus={status}
      onSaveClick={() => {
        handleSubmit(
          (data) => saveObject({ ...object, featuredData: data }, backToInfo),
          (errors) =>
            Object.values(errors).forEach((localizedFieldErrors) =>
              Object.values(localizedFieldErrors).forEach((error) => {
                AlertsService.addAlert(error.message, AlertType.WARNING);
              }),
            ),
        )();
      }}
      onCancelClick={onCancelClick}
      isSaveDisabled={!isDirty}
    >
      <Container fluid className="mb-3">
        <h2>Editing featured trophy info</h2>
        <Form className="mb-3 w-50">
          <Form.Check
            type="switch"
            id="is-featured-switch"
            label="Featured"
            className="my-3"
            {...register('isActive')}
          />
          <LocalizationTabs
            renderTabContent={(languageCode, label) => (
              <div className="py-3 pe-3">
                <Controller
                  name={`localizedFeaturedTitle.${languageCode}`}
                  control={control}
                  render={({ field }) => (
                    <FormInputLimited
                      label="Fact title"
                      lengthLimit={80}
                      type="text"
                      isInvalid={Boolean(errors.localizedFeaturedTitle?.[languageCode])}
                      {...field}
                    />
                  )}
                  rules={{
                    validate: (value, formValues) => !formValues.isActive || validateFactTitle(value, label),
                  }}
                />
                <Controller
                  name={`localizedFeaturedDescription.${languageCode}`}
                  control={control}
                  render={({ field }) => (
                    <FormInputLimited
                      label="Fact description"
                      onChange={() => {}}
                      lengthLimit={2000}
                      as="textarea"
                      rows={5}
                      comment={MarkdownSupportComment()}
                      isInvalid={Boolean(errors.localizedFeaturedDescription?.[languageCode])}
                      {...field}
                    />
                  )}
                  rules={{
                    validate: (value, formValues) => !formValues.isActive || validateFactDescription(value, label),
                  }}
                />
                <Button
                  variant="outline-primary"
                  className="pt-0 pb-0 mt-3"
                  data-language={languageCode}
                  data-label={label}
                  onClick={handleAutoTranslate}
                >
                  Auto translate to other languages
                </Button>
              </div>
            )}
          />
        </Form>
      </Container>
    </EditorLayout>
  );
};
