import axios from 'axios';
import HelperService from '../HelperService';
import constants from '../../constants';
import { IObjectVersion } from '../../types/objects';
import { ILanguageCode } from '../../types/common';

class ObjectPublicService {
  getVersions = (objectId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/GetVersions?objectId=${objectId}`;

    return axios.get(url).then((res) => res.data);
  };

  getVersion = (versionId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/GetVersion?id=${versionId}`;

    return axios.get(url).then((res: { data: IObjectVersion }) => ({
      version: {
        id: res.data.id,
        objectId: res.data.objectId,
        coordinates: HelperService.getCoordinatesString(res.data.location.lat, res.data.location.lon),
        localizedName: res.data.localizedName,
        localizedDetails: res.data.localizedDetails,
        extraPhotos: res.data.extraPhotos.map((image: string) => ({
          url: HelperService.getAssetUrl(res.data.assetsBaseUrl, image),
          name: image,
        })),
        localizedLink: res.data.localizedLink,
        animated: res.data.animated,
        modelAuthor: res.data.modelAuthor?.id || '',
        modelAuthorNote: res.data.modelAuthorNote || '',
      },
      versionFiles: {
        model: HelperService.getAssetUrl(res.data.assetsBaseUrl, res.data.modelFile, true),
        iosModel: HelperService.getAssetUrl(res.data.assetsBaseUrl, res.data.iosModelFile, true),
        image: HelperService.getAssetUrl(res.data.assetsBaseUrl, res.data.imageFile, true),
        icon: HelperService.getAssetUrl(res.data.assetsBaseUrl, res.data.iconFile, true),
      },
    }));
  };

  publishDraft = async (draft: IObjectVersion): Promise<boolean> => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/PublishVersion`;
    const publishPayload = {
      versionId: draft.id,
    };

    try {
      await axios.post(url, publishPayload).then();
      return true;
    } catch {
      return false;
    }
  };

  cloneObject = async (objectToClone: IObjectVersion): Promise<string | null> => {
    const url = `${constants.API_ADMIN_OBJECTS_ENDPOINT}/CloneObject`;
    const publishPayload = {
      id: objectToClone.objectId,
      objectVersionId: objectToClone.id,
    };

    try {
      const { id } = await axios.post(url, publishPayload).then((res) => res.data);
      return id;
    } catch {
      return null;
    }
  };

  possibleToPublish = async (draft: IObjectVersion) => {
    let problems = [];
    let possible = true;

    Object.entries(ILanguageCode).forEach(([label, languageCode]) => {
      if (!draft.localizedName[languageCode]) {
        problems.push(`${label} title is empty`);
        possible = false;
      }

      if (!draft.localizedDetails[languageCode]) {
        problems.push(`${label} description is empty`);
        possible = false;
      }

      if (
        draft.localizedLink &&
        Object.values(draft.localizedLink).some((entry) => entry.url.trim() !== '') &&
        (!draft.localizedLink[languageCode] || !draft.localizedLink[languageCode].url)
      ) {
        problems.push(`${label} link URL is empty. Please add it or remove links for other languages`);
        possible = false;
      }

      if (
        draft.localizedLink &&
        Object.values(draft.localizedLink).some((entry) => entry.title.trim() !== '') &&
        (!draft.localizedLink[languageCode] || !draft.localizedLink[languageCode].title)
      ) {
        problems.push(`${label} link Title is empty. Please add it or remove links for other languages`);
        possible = false;
      }
    });

    if (!(draft.location.lon || draft.location.lat)) {
      problems.push('Coordinates are not set');
      possible = false;
    }
    if (!(await HelperService.isFileExist(HelperService.getAssetUrl(draft.assetsBaseUrl, draft.modelFile, true)))) {
      problems.push("Model file doesn't exist");
      possible = false;
    }
    if (!(await HelperService.isFileExist(HelperService.getAssetUrl(draft.assetsBaseUrl, draft.imageFile, true)))) {
      problems.push("Model image doesn't exist");
      possible = false;
    }
    if (!(await HelperService.isFileExist(HelperService.getAssetUrl(draft.assetsBaseUrl, draft.iconFile, true)))) {
      problems.push("Model icon doesn't exist");
      possible = false;
    }
    return { possible, problems };
  };

  archiveVersion = async (versionId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/ArchiveVersion`;

    const archivePayload = {
      versionId,
    };

    try {
      await axios.post(url, archivePayload).then();
      return true;
    } catch {
      return false;
    }
  };

  deleteDraft = async (versionId: string) => {
    const url = `${constants.API_ADMIN_OBJECT_VERSIONS_ENDPOINT}/DeleteDraft?versionId=${versionId}`;

    try {
      await axios.delete(url).then();
      return true;
    } catch {
      return false;
    }
  };
}

export default new ObjectPublicService();
