import { useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../store/action-creators/modal';

interface IExitConfirmationProps {
  message?: string;
  shouldConfirm: boolean;
  onExit: () => void;
  onConfirm?: () => void;
}

export const useExitConfirmation = ({
  shouldConfirm,
  onExit,
  onConfirm = onExit,
  message = 'Go back without saving?',
}: IExitConfirmationProps) => {
  const props = useRef<IExitConfirmationProps>({ shouldConfirm, onExit, onConfirm, message });
  const dispatch = useDispatch();

  useEffect(() => {
    props.current = { shouldConfirm, onExit, onConfirm, message };
  }, [shouldConfirm, onExit, onConfirm, message]);

  return useCallback(() => {
    const { shouldConfirm, onExit, onConfirm, message } = props.current;

    if (shouldConfirm) {
      dispatch(
        showModal({
          text: message,
          primaryAction: onConfirm,
        }),
      );
      return;
    }

    onExit();
  }, [dispatch]);
};
