import React, { useCallback, useEffect, useState } from 'react';
import { IPaging, ITableColumn } from '../../../types/common';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import commonStyles from '../../../styles/common.module.css';
import styles from '../../../styles/table.module.css';
import Loading from '../Loading';
import { Form, Table } from 'react-bootstrap';
import { BiTrash } from 'react-icons/bi';

enum ITableStatus {
  Success,
  Error,
  Loading,
  FullData,
}

interface IProps {
  columns: ITableColumn[];
  loadDataFunction: Function;
  transformDataFunction?: Function;
  onRowClick: Function | { action: Function; key?: string };
  height?: string;
  emptyComponent?: React.ReactNode | JSX.Element;
  dataTestId?: string;
  showHeader?: boolean;
  onDeleteClick?: Function;
  selectionKey?: string;
  selected?: any[];
  onSelectClick?: (v: any) => void;
  inactiveRow?: (v: any) => boolean;
}

function CSTable({
  columns,
  loadDataFunction,
  transformDataFunction,
  onRowClick,
  height = 'calc(100vh - 52px)',
  emptyComponent,
  dataTestId,
  showHeader = true,
  onDeleteClick,
  selectionKey = 'id',
  selected,
  onSelectClick,
  inactiveRow,
}: IProps) {
  const [data, setData] = useState<any[]>([]);
  const [paging, setPaging] = useState<IPaging>({ skip: 0, limit: 30 });
  const [status, setStatus] = useState<ITableStatus>(ITableStatus.Loading);
  const search = useTypedSelector((state) => state.navbar.searchString);

  const getData = useCallback(
    (skip: number, limit: number = paging.limit) => {
      const loadData = async (): Promise<any[] | null> => {
        setStatus(ITableStatus.Loading);
        try {
          const res = await loadDataFunction(skip, limit, search);
          setStatus(res.length === paging.limit ? ITableStatus.Success : ITableStatus.FullData);
          return res;
        } catch (e: Error | any) {
          if (e.code !== 'ERR_CANCELED') {
            setStatus(ITableStatus.Error);
          }
          return null;
        }
      };
      loadData().then((res) => {
        if (res) {
          const resTransformed = transformDataFunction ? res.map((x) => transformDataFunction(x)) : res;
          setData((prevState) => prevState.concat(resTransformed));
        }
      });
    },
    [paging.limit, search, loadDataFunction, transformDataFunction],
  );

  useEffect(() => {
    setPaging((prevState) => ({ ...prevState, skip: 0 }));
    setData([]);
    getData(0);
  }, [getData]);

  const handleRowClick = (row: any, evt: React.MouseEvent) => {
    // if click on link, do not handle row click
    const tgt = evt.target as HTMLElement;
    if (tgt.nodeName.toLowerCase() === 'a') {
      return;
    }

    if (typeof onRowClick === 'function') {
      onRowClick(row.id);
    } else {
      onRowClick.action(onRowClick.key ? row[onRowClick.key] : row);
    }
  };

  const handleDeleteClick = (e: any, id: string) => {
    e.stopPropagation();
    onDeleteClick && onDeleteClick(id);
    hideItem(id);
  };

  const hideItem = (id: string) => {
    setData((prevState) => prevState.filter((v) => v.id !== id));
  };

  const onTableScroll = (event: any) => {
    if (status !== ITableStatus.Success) {
      return;
    }
    const target = event.nativeEvent.target;
    if (target.scrollTop + 10 >= target.scrollHeight - target.clientHeight) {
      setPaging({ ...paging, skip: paging.skip + paging.limit });
      getData(paging.skip + paging.limit);
    }
  };

  return (
    <div className="overflow-auto" style={{ height: height }} onScroll={onTableScroll}>
      <Table hover bordered data-test-id={dataTestId}>
        {showHeader && (
          <thead className="sticky-top">
            <tr className={styles.trHead}>
              {selected && <th />}
              {columns.map((col, i, { length }) => (
                <th style={onDeleteClick && i + 1 === length ? { borderWidth: 0 } : {}} key={col.id}>
                  {col.displayName}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className={styles.tbodyWithCenteredTd}>
          {data.map((row) => (
            <tr
              key={row.id}
              className={`${commonStyles.pointer} ${inactiveRow && inactiveRow(row) ? 'opacity-25' : ''}`}
              onClick={(evt) => handleRowClick(row, evt)}
            >
              {selected && (
                <td>
                  <Form.Check>
                    <Form.Check.Input
                      className={commonStyles.pointer}
                      onChange={onSelectClick ? onSelectClick : () => {}}
                      checked={selected.includes(row[selectionKey as keyof typeof row])}
                    />
                  </Form.Check>
                </td>
              )}
              {columns.map((col, i, { length }) => {
                const val = row[col.id as keyof typeof row];
                let display = <span>{val + ''}</span>;
                if (col.component) {
                  display = col.component({ value: val, row: row });
                }
                if (col.pattern) {
                  display = <span>{col.pattern(val, row) + ''}</span>;
                }
                return (
                  <td
                    style={onDeleteClick && i + 1 === length ? { borderWidth: 0 } : {}}
                    key={row.id + col.id}
                    width={col.width}
                  >
                    <div style={{ width: col.width, minWidth: col.minWidth }} className={styles.tdContent}>
                      {display}
                    </div>
                  </td>
                );
              })}
              {onDeleteClick && (
                <td style={{ borderWidth: 0, width: '34px' }}>
                  <BiTrash
                    onClick={(e: any) => handleDeleteClick(e, row.id)}
                    className={styles.showOnRowHover}
                    color={'#DC3545'}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      {(status === ITableStatus.FullData || status === ITableStatus.Success) &&
        data.length === 0 &&
        emptyComponent &&
        emptyComponent}
      {status === ITableStatus.Loading && <Loading />}
      {status === ITableStatus.Error && (
        <div className="d-flex justify-content-center m-5">
          <div>We have problems loading data ...</div>
        </div>
      )}
    </div>
  );
}

export default CSTable;
