import React, { useLayoutEffect, useRef, useState } from 'react';
import { Button, Form, Badge } from 'react-bootstrap';
import { BiReset, BiTrash } from 'react-icons/bi';
import commonStyles from '../../../styles/common.module.css';
import styles from '../Object.module.css';
import ModelCanvas from './ModelCanvas';
import ClickableImage from '../../common/ClickableImage/ClickableImage';
import HelperService from '../../../services/HelperService';
import Model3DLib from '../../../lib/model3DLib';
import { IMimeType } from '../../../types/common';
import CSTooltip from '../../common/CSTooltip';
import { AlertType } from '../../../types/alerts';
import ObjectModelControls from './ObjectModelControls';
import { envPresetType } from '../../../types/objects';
import AlertsService from 'store/services/AlertsService';
import '@google/model-viewer';

interface IProps {
  model: any;
  modelChange: (values: { name: string; value: any }[]) => void;
}

function DraftModel({ model, modelChange }: IProps) {
  const modelContainer = useRef<HTMLDivElement | null>(null);
  const [canvasSize, setCanvasSize] = useState<number>(0);
  const [verticalRotation, setVerticalRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);
  const [exposure, setExposure] = useState<number>(0.8);
  const [preset, setPreset] = useState<envPresetType>(undefined);
  const [isAnimated, setIsAnimated] = useState<boolean>(!!model.animated);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const discardChanges = () => {
    setZoom(1);
    setExposure(0.8);
    setVerticalRotation(0);
    setIsAnimated(false);
  };

  const deleteModel = () => {
    modelChange([
      { name: 'model', value: {} },
      { name: 'iosModel', value: {} },
      { name: 'image', value: {} },
      { name: 'icon', value: {} },
      { name: 'rotation', value: 0 },
      { name: 'animated', value: false },
    ]);
    discardChanges();
  };
  const deleteImage = () => {
    modelChange([
      { name: 'image', value: {} },
      { name: 'icon', value: {} },
    ]);
  };

  const ResetTransformation = () => {
    modelChange([{ name: 'rotation', value: 0 }]);
    discardChanges();
  };

  const onRotationClick = (v = 1) => {
    modelChange([{ name: 'rotation', value: (model.rotation || 0) + v * 10 }]);
  };

  const onVerticalRotationClick = (v = 1) => {
    setVerticalRotation((prevState) => prevState + v * 10);
  };

  const handleZoomChange = (e: any) => {
    setZoom(e.target.value);
  };

  const handleExposureChange = (e: any) => {
    setExposure(e.target.value);
  };

  const handlePresetChange = (e: any) => {
    setPreset(e.target.value === 'bridge' ? undefined : e.target.value);
  };

  const takeScreenshot = async () => {
    if (canvasRef.current) {
      const defUrl = canvasRef.current.toDataURL('image/png');
      await processPictureUpdate(defUrl);
    }
  };

  const handleInputFileChange = async (event: any) => {
    let changes = [];
    const maxFileSize = 20 * 1024 * 1024;
    const recommendedFileSize = 8 * 1024 * 1024;
    if (event.target.files[0].size <= maxFileSize) {
      if (event.target.files[0].size >= recommendedFileSize) {
        AlertsService.addAlert(
          'The uploaded file exceeds the recommended size of 8MB. Please consider compressing the model.',
          AlertType.WARNING,
          60000,
        );
      }
      const validationResult = await Model3DLib.validateModel(event.target.files[0]);

      if (validationResult.errors.length > 0) {
        AlertsService.addAlert(validationResult.errors.join(','), AlertType.ERROR, 120000);
        return;
      }

      if (validationResult.isAnimated !== isAnimated) {
        setIsAnimated(validationResult.isAnimated);
        modelChange([
          {
            name: 'animated',
            value: validationResult.isAnimated,
          },
        ]);
      }

      const modelUrl = await Model3DLib.handleLoadModelFile(event.target.files[0], validationResult.isAnimated);

      if (modelUrl) {
        changes.push(
          {
            name: 'model',
            value: {
              url: modelUrl,
              file: await HelperService.dataUrlToFile(modelUrl, 'model.glb', IMimeType.model),
            },
          },
          { name: 'rotation', value: 0 },
        );
      }

      if (!validationResult.isAnimated) {
        const iOSModelUrl = await Model3DLib.handleLoadIOSModelFile(event.target.files[0]);
        if (iOSModelUrl) {
          changes.push({
            name: 'iosModel',
            value: {
              url: iOSModelUrl,
              file: await HelperService.dataUrlToFile(iOSModelUrl, 'model.usdz', IMimeType.iOSModel),
            },
          });
        }
      }

      modelChange(changes);
    } else {
      AlertsService.addAlert('File is too big. Max file size is 20 Mb.', AlertType.ERROR, 120000);
    }
  };

  const handleIOSAnimatedInputFileChange = async (event: any) => {
    const maxFileSize = 20 * 1024 * 1024;
    const recommendedFileSize = 8 * 1024 * 1024;
    if (event.target.files[0].size <= maxFileSize) {
      if (event.target.files[0].size >= recommendedFileSize) {
        AlertsService.addAlert(
          'The uploaded file exceeds the recommended size of 8MB. Please consider compressing the model.',
          AlertType.WARNING,
          60000,
        );
      }
      const iOSModelUrl = URL.createObjectURL(event.target.files[0]);
      let changes = [];

      if (iOSModelUrl) {
        changes.push({
          name: 'iosModel',
          value: {
            url: iOSModelUrl,
            file: await HelperService.dataUrlToFile(iOSModelUrl, 'model.usdz', IMimeType.iOSModel),
          },
        });
      }

      modelChange(changes);
    } else {
      AlertsService.addAlert('File is too big. Max file size is 20 Mb.', AlertType.ERROR, 120000);
    }
  };

  const processPictureUpdate = async (url: string) => {
    const image = await HelperService.resizeDataURL(url, 800, 800);
    const icon = await HelperService.resizeDataURL(url, 150, 150);
    modelChange([
      { name: 'image', value: { url: image } },
      { name: 'icon', value: { url: icon } },
    ]);
  };

  const handleInputPicture = async (event: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async () => {
      if (reader.result) {
        const img = new Image();
        img.src = reader.result.toString();
        img.onload = async () => {
          if (img.width < 800 || img.height < 800) {
            AlertsService.addAlert("Image can't be less then 800x800 pix", AlertType.WARNING, 60000);
          } else {
            await processPictureUpdate(img.src);
          }
        };
      }
    };
  };

  useLayoutEffect(() => {
    if (modelContainer.current) {
      setCanvasSize(
        Math.min(
          modelContainer.current.offsetWidth - (isAnimated ? 50 : 100),
          modelContainer.current.offsetHeight - (isAnimated ? 70 : 150),
        ),
      );
    }
  }, [isAnimated]);

  return (
    <>
      <div
        data-test-id="mdl-container"
        ref={modelContainer}
        className={`w-100 h-75 position-relative d-flex align-items-center justify-content-center ${styles.modelContainer}`}
      >
        {model.model && model.model.url ? (
          <>
            <div style={{ width: canvasSize, height: canvasSize }} className={styles.modelViewerOuterBorder}>
              {isAnimated ? ( // Use model-viewer for animated models and ModelCanvas with controls for standard
                <model-viewer
                  src={model.model.url}
                  autoplay
                  ref={canvasRef}
                  interaction-prompt="none"
                  environment-image={preset ? `/${preset}-map.hdr` : '/environment-map.hdr'}
                  camera-controls
                  camera-orbit={`10deg 90deg 2.2m`}
                  touch-action="pan-y"
                />
              ) : (
                <>
                  <ModelCanvas
                    model={model.model.url}
                    width={canvasSize}
                    exposure={exposure}
                    rotation={model.rotation || 0}
                    verticalRotation={verticalRotation}
                    preset={preset}
                    canvasRef={canvasRef}
                    zoom={zoom}
                  />
                  <div className={styles.modelViewerInnerBorder}></div>
                </>
              )}
            </div>

            <div className="position-absolute" style={{ left: 0, top: 0 }} data-test-id="mdl-img-container">
              {model.image && model.image.url ? (
                <ClickableImage
                  deleteClick={deleteImage}
                  style={{ objectFit: 'cover' }}
                  src={model.image.url}
                  width={64}
                  height={64}
                  rounded
                />
              ) : (
                <>
                  <Button data-test-id="mdl-img-take-pic" variant="light" size="sm" onClick={takeScreenshot}>
                    Take picture
                  </Button>
                  <Form.Group controlId="pictureInput" className="d-inline" data-test-id="mdl-img-upload-pic">
                    <Button variant="link" size="sm">
                      <Form.Label className={`${commonStyles.pointer} mb-0`}>Upload picture</Form.Label>
                    </Button>
                    <Form.Control
                      type="file"
                      className="d-none"
                      accept=".png,.jpeg,.jpg"
                      onChange={handleInputPicture}
                    />
                  </Form.Group>
                </>
              )}
            </div>
            {!isAnimated && (
              <>
                <ObjectModelControls
                  canvasWidth={canvasSize}
                  zoom={[zoom, handleZoomChange]}
                  exposure={[exposure, handleExposureChange]}
                  preset={[preset, handlePresetChange]}
                  onRotation={onRotationClick}
                  onVerticalRotation={onVerticalRotationClick}
                />
                <CSTooltip text="Reset transformation" placement="bottom">
                  <Button
                    data-test-id="mdl-reset"
                    variant="light"
                    size="sm"
                    className="position-absolute"
                    style={{ right: 40, top: 0 }}
                    onClick={ResetTransformation}
                  >
                    <BiReset />
                  </Button>
                </CSTooltip>
              </>
            )}
            <CSTooltip text="Delete model" placement="bottom">
              <Button
                data-test-id="mdl-delete"
                variant="danger"
                size="sm"
                className="position-absolute"
                style={{ right: 0, top: 0 }}
                onClick={deleteModel}
              >
                <BiTrash />
              </Button>
            </CSTooltip>
          </>
        ) : (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <Form.Group controlId="modelInput" data-test-id="mdl-upload">
              <Button variant="link" className="pb-0">
                <Form.Label className={`${commonStyles.pointer} mb-0`}>Upload 3D model</Form.Label>
              </Button>
              <Form.Control type="file" className="d-none" accept=".glb,.gltf" onChange={handleInputFileChange} />
            </Form.Group>
            <div>
              <small>
                <i>GLB, GLTF format. Max file size: 20 Mb</i>
              </small>
            </div>
          </div>
        )}
      </div>
      {isAnimated && (
        <Form.Group controlId="iOSModelInput" className="mt-4" data-test-id="ios-mdl-upload">
          <Form.Label>Upload animated iOS model (USDZ)</Form.Label>
          {!!model.iosModel && !!model.iosModel.url ? (
            <p>
              <Badge pill bg="success">
                IOS model Selected
              </Badge>{' '}
              Remove main model to update
            </p>
          ) : (
            <Form.Control type="file" accept=".usdz" onChange={handleIOSAnimatedInputFileChange} />
          )}
        </Form.Group>
      )}
    </>
  );
}

export default DraftModel;
