import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Object.module.css';
import ObjectPublic from './ObjectPublic/ObjectPublic';
import ObjectAdmin, { ActiveTab } from './ObjectAdmin/ObjectAdmin';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Draft from './Draft/Draft';
import AdminEditor from './AdminEditor/AdminEditor';
import { ObjectPageState } from '../../types/objects';
import GoBack from '../common/GoBack';
import { showModal } from 'store/action-creators/modal';
import { useDispatch } from 'react-redux';
import { clearObjStore } from 'store/action-creators/object';
import ConditionsEditor from './AdminEditor/ConditionsEditor';
import Model from './Model/Model';
import { FeaturedInfoEditor } from './AdminEditor/FeaturedInfoEditor';

interface IProps {
  state?: ObjectPageState;
}

function Object({ state }: IProps) {
  const [pageState, setPageState] = useState<ObjectPageState>(state || ObjectPageState.info);
  const [activeTabState, setActiveTabState] = useState<ActiveTab>(ActiveTab.Admin);
  const [editorVersionId, setEditorVersionId] = useState<string | null>(null);
  const navigate = useNavigate();
  const search = useTypedSelector((state) => state.navbar.searchString);
  const objWasChanged = useTypedSelector((state) => state.object.wasChanged);
  const dispatch = useDispatch();

  const onLeavePage = (action: Function) => {
    if (objWasChanged) {
      dispatch(
        showModal({
          title: 'Unsaved changes',
          text: 'There are unsaved changes in collections list.\nPlease discard or save them before leaving the page.',
          primaryAction: () => {},
          primaryText: 'Ok',
          oneActionMode: true,
        }),
      );
    } else {
      action();
      dispatch(clearObjStore());
    }
  };
  const onGoBackClick = () => {
    onLeavePage(handleGoBack);
  };

  const handleGoBack = () => {
    navigate(search.length ? '/objects?search=' + search : '/objects');
  };

  const handleDuplicateClick = (versionId: string) => {
    onLeavePage(() => {
      setEditorVersionId(versionId);
      setPageState(ObjectPageState.versionEditor);
    });
  };

  const handleGenerateModelClick = (versionId: string) => {
    onLeavePage(() => {
      setEditorVersionId(versionId);
      setPageState(ObjectPageState.modelGenerator);
    });
  };

  const handleEditPublicClick = (draftId: string) => {
    onLeavePage(() => {
      setEditorVersionId(draftId);
      setPageState(ObjectPageState.draftEditor);
    });
  };

  const handleEditInfoClick = (activeTab: ActiveTab) => {
    switch (activeTab) {
      case ActiveTab.Admin:
        setPageState(ObjectPageState.adminEditor);
        break;

      case ActiveTab.Conditions:
        setPageState(ObjectPageState.conditionsEditor);
        break;

      case ActiveTab.FeaturedInfo:
        setPageState(ObjectPageState.featuredInfoEditor);
        break;

      default:
        throw new Error(`Unable to handle edit for tab ${activeTab}`);
    }
  };

  const handleInfoClick = useCallback((activeTab?: ActiveTab) => {
    setActiveTabState(activeTab ?? ActiveTab.Admin);
    setPageState(ObjectPageState.info);
  }, []);

  return (
    <>
      {pageState === ObjectPageState.info && (
        <>
          <GoBack handleGoBack={onGoBackClick} text={'Back to Objects list'} />
          <div className={`d-flex align-items-stretch ${styles.container}`}>
            <ObjectPublic
              duplicateClick={handleDuplicateClick}
              editClick={handleEditPublicClick}
              backToList={handleGoBack}
              generateModelClick={handleGenerateModelClick}
            />
            <ObjectAdmin activeTab={activeTabState} onEditInfoClick={handleEditInfoClick} />
          </div>
        </>
      )}
      {pageState === ObjectPageState.versionEditor && (
        <Draft versionId={editorVersionId} backToInfo={handleInfoClick} />
      )}
      {pageState === ObjectPageState.modelGenerator && (
        <Model versionId={editorVersionId} backToInfo={handleInfoClick} />
      )}
      {pageState === ObjectPageState.draftEditor && (
        <Draft versionId={editorVersionId} backToInfo={handleInfoClick} isNewVersion={false} />
      )}
      {pageState === ObjectPageState.adminEditor && <AdminEditor backToInfo={handleInfoClick} />}
      {pageState === ObjectPageState.conditionsEditor && (
        <ConditionsEditor backToInfo={() => handleInfoClick(ActiveTab.Conditions)} />
      )}
      {pageState === ObjectPageState.newObject && <Draft backToInfo={handleInfoClick} backToList={handleGoBack} />}
      {pageState === ObjectPageState.featuredInfoEditor && (
        <FeaturedInfoEditor backToInfo={() => handleInfoClick(ActiveTab.FeaturedInfo)} />
      )}
    </>
  );
}

export default Object;
