import { IComponentCellProps } from '../../types/common';
import { IObjectState } from '../../types/objects';
import styles from '../../styles/common.module.css';
import React from 'react';

function ObjectStatus(props: IComponentCellProps) {
  let color = '#E9ECEF';
  let textColor = '#212529';
  if (!props.value) {
    return <span></span>;
  }
  switch (props.value) {
    case IObjectState.Published: {
      color = '#28A745';
      textColor = '#FFFFFF';
      break;
    }
    case IObjectState.New: {
      color = '#FFC107';
      textColor = '#FFFFFF';
      break;
    }
    case IObjectState.Draft: {
      color = '#6B7AFF';
      textColor = '#FFFFFF';
      break;
    }
    default:
      break;
  }
  return (
    <div className={styles.tag} style={{ backgroundColor: color, color: textColor }}>
      {props.value}
    </div>
  );
}

export default ObjectStatus;
