import React, { useEffect, useState } from 'react';
import { IStatus } from '../../../../types/common';
import LoadingWrapper from '../../../common/LoadingWrapper';
import ObjectsService from '../../../../services/Objects/ObjectsService';
import { IObject } from '../../../../types/objects';
import { ICollection } from '../../../../types/collections';
import CollectionsService from '../../../../services/Collections/CollectionsService';
import CouponsService from '../../../../services/Advertisers/CouponsService';
import ObjectsListWithAddRemove from '../../../Objects/ObjectsListWithAddRemove/ObjectsListWithAddRemove';
import CollectionsListWithAddRemove from '../../../Collection/CollectionListWithAddRemove/CollectionsListWithAddRemove';
import { ICouponCollectionConditions, ICouponObjectConditions } from '../../../../types/coupons';
import { Button, ButtonGroup } from 'react-bootstrap';

interface IProp {
  couponCampaignId: string | null;
}

export enum ActiveTab {
  Objects = 0,
  Collections = 1,
}

function CouponRelatedObjectsList({ couponCampaignId }: IProp) {
  const [activeTabState, setActiveTabState] = useState<ActiveTab>(ActiveTab.Objects);

  const [objects, setObjects] = useState<IObject[]>([]);
  const [objectConditions, setObjectConditions] = useState<ICouponObjectConditions[]>([]);
  const [objectsLoadingState, setObjectsLoadingState] = useState<IStatus>(IStatus.Loading);

  const [collections, setCollections] = useState<ICollection[]>([]);
  const [collectionConditions, setCollectionConditions] = useState<ICouponCollectionConditions[]>([]);
  const [collectionsLoadingState, setCollectionsLoadingState] = useState<IStatus>(IStatus.Loading);

  const reloadObjects = (campaignId: string) => {
    ObjectsService.getObjects(0, 200, 'coupon: ' + campaignId, null, null)
      .then((objects) => {
        setObjects(objects);

        CouponsService.getObjectConditions(
          campaignId,
          objects.map((o) => o.id),
        )
          .then((conditions) => {
            setObjectConditions(conditions);
            setObjectsLoadingState(IStatus.Success);
          })
          .catch((err) => {
            setObjectsLoadingState(IStatus.Error);
          });
      })
      .catch((err) => {
        setObjectsLoadingState(IStatus.Error);
      });
  };

  const reloadCollections = (campaignId: string) => {
    CollectionsService.getCollections(0, 200, 'coupon: ' + campaignId)
      .then((collections) => {
        setCollections(collections);

        CouponsService.getCollectionConditions(
          campaignId,
          collections.map((c) => c.id),
        )
          .then((conditions) => {
            setCollectionConditions(conditions);
            setCollectionsLoadingState(IStatus.Success);
          })
          .catch((err) => {
            setCollectionsLoadingState(IStatus.Error);
          });
      })
      .catch((err) => {
        setCollectionsLoadingState(IStatus.Error);
      });
  };

  useEffect(() => {
    if (couponCampaignId) {
      reloadObjects(couponCampaignId);
      reloadCollections(couponCampaignId);
    }
  }, [couponCampaignId]);

  const assignObject = async (objectId: string, numRequiredCollects: number) => {
    if (couponCampaignId) {
      await CouponsService.assignObject(couponCampaignId, objectId, numRequiredCollects);
      reloadObjects(couponCampaignId);
    }
  };

  const unassignObject = async (objectId: string) => {
    if (couponCampaignId) {
      await CouponsService.unassignObject(couponCampaignId, objectId);
      reloadObjects(couponCampaignId);
    }
  };

  const assignCollection = async (
    collectionId: string,
    isFullCompletionRequired: boolean,
    requiredObjectIds: string[],
    numRequiredCollects: number,
  ) => {
    if (couponCampaignId) {
      await CouponsService.assignCollection(
        couponCampaignId,
        collectionId,
        isFullCompletionRequired,
        requiredObjectIds,
        numRequiredCollects,
      );
      reloadCollections(couponCampaignId);
    }
  };

  const unassignCollection = async (collectionId: string) => {
    if (couponCampaignId) {
      await CouponsService.unassignCollection(couponCampaignId, collectionId);
      reloadCollections(couponCampaignId);
    }
  };

  if (couponCampaignId === null) return <></>;

  const renderButtonGroup = () => {
    const onButtonClick = (activeTab: ActiveTab = ActiveTab.Objects) => {
      setActiveTabState(activeTab);
    };

    return (
      <ButtonGroup size="sm" className="pb-3">
        <Button
          variant="light"
          active={activeTabState === ActiveTab.Objects}
          data-test-id="obj-nav-objects"
          onClick={() => onButtonClick(ActiveTab.Objects)}
        >
          Assigned Objects{' '}
          <span className={`badge rounded-pill ${objects.length > 0 ? 'bg-primary' : 'bg-light text-dark'}`}>
            {objects.length}
          </span>
        </Button>
        <Button
          variant="light"
          active={activeTabState === ActiveTab.Collections}
          data-test-id="obj-nav-collections"
          onClick={() => onButtonClick(ActiveTab.Collections)}
        >
          Assigned Collections{' '}
          <span className={`badge rounded-pill ${collections.length > 0 ? 'bg-primary' : 'bg-light text-dark'}`}>
            {collections.length}
          </span>
        </Button>
      </ButtonGroup>
    );
  };

  switch (activeTabState) {
    case ActiveTab.Objects:
      return (
        <>
          <div className="h-100 pt-2">
            {renderButtonGroup()}
            <LoadingWrapper status={objectsLoadingState}>
              <ObjectsListWithAddRemove
                objects={objects}
                objectConditions={objectConditions}
                emptyPlaceholder="No objects assigned"
                onObjectAdded={assignObject}
                onObjectRemoved={unassignObject}
              />
            </LoadingWrapper>
          </div>
        </>
      );
    case ActiveTab.Collections:
      return (
        <>
          <div className="h-100 pt-2">
            {renderButtonGroup()}
            <LoadingWrapper status={collectionsLoadingState}>
              <CollectionsListWithAddRemove
                collections={collections}
                collectionConditions={collectionConditions}
                emptyPlaceholder="No collections assigned"
                onCollectionAdded={assignCollection}
                onCollectionRemoved={unassignCollection}
              />
            </LoadingWrapper>
          </div>
        </>
      );
  }
}

export default CouponRelatedObjectsList;
