import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import AdUsersService from '../../../../../services/Advertisers/AdUsersService';
import CSTable from '../../../../common/CSTable/CSTable';
import Columns from './AdUsersTableColumns';

function AdvertiserUsersList() {
  let { adId } = useParams<{ adId: string }>();
  const columns = Columns();

  const getData = useCallback(() => AdUsersService.getUsers(adId), [adId]);

  const emptyTable = () => {
    return (
      <div className="d-flex justify-content-center m-5">
        <div>This advertiser does not have any users yet</div>
      </div>
    );
  };

  const onRowClick = (id: string) => {
    //navigate(`${id}`);
    alert('Editing is not supported, instead you can remove the user and create a new one');
  };

  const onDelete = async (userId: string) => {
    if (adId) await AdUsersService.deleteUser(userId, adId);
  };

  return (
    <CSTable
      dataTestId="ad-users-list"
      columns={columns}
      loadDataFunction={getData}
      onRowClick={onRowClick}
      onDeleteClick={onDelete}
      height="calc(100vh - 80px)"
      emptyComponent={emptyTable()}
    />
  );
}

export default AdvertiserUsersList;
