import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import MapWithMarker from 'components/common/MapWithMarker';
import ClickableImage from 'components/common/ClickableImage/ClickableImage';
import HelperService from 'services/HelperService';
import constants from 'constants/index';
import { ILanguageCode, ILink, ILocalizedContent } from 'types/common';
import { LocalizationTabs } from 'components/common/LocalizationTabs';

import styles from './ObjectCollection.module.css';
import commonStyles from 'styles/common.module.css';

interface IProps {
  data: {
    assetsBaseUrl: string;
    imageFile: string;
    extraPhotos: string[];
    location?: { lon: number; lat: number };
    id?: string;
    localizedName: ILocalizedContent<string>;
    localizedDetails: ILocalizedContent<string>;
    localizedLink?: ILocalizedContent<ILink> | null;
    modelAuthor?: { id: string; displayName: string };
    modelAuthorNote?: string;
  };
}

function PublicInfo({ data }: IProps) {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [extraImageSrc, setExtraImageSrc] = useState<string[]>([]);

  const coordinatesHref = data?.location
    ? constants.GOOGLE_MAP_URL_SEARCH + data.location.lat + ',' + data.location.lon
    : '';

  useEffect(() => {
    setImageSrc(HelperService.getAssetUrl(data.assetsBaseUrl, data.imageFile, true));
    setExtraImageSrc(data.extraPhotos.map((image) => HelperService.getAssetUrl(data.assetsBaseUrl, image)));
  }, [data]);

  return (
    <div data-test-id="obj-p-version">
      <div className={`d-inline-flex flex-wrap gap-3 align-items-center ${styles.publicTopContainer}`}>
        <ClickableImage fluid src={imageSrc} width={160} />
        {data.location && (
          <>
            <div className={styles.mapContainer} data-test-id="obj-p-map">
              <MapWithMarker id={data.id || 'marker'} lon={data.location.lon} lat={data.location.lat} />
            </div>
            <a data-test-id="public-location" href={coordinatesHref} target="_blank" rel="noreferrer">
              {data.location.lat.toFixed(6)}&deg;, {data.location.lon.toFixed(6)}&deg;
            </a>
          </>
        )}
      </div>
      <LocalizationTabs
        renderTabContent={(languageCode: ILanguageCode) => (
          <div className="pt-3">
            <h4 data-test-id={`public-name-${languageCode}`}>{data.localizedName[languageCode] ?? ''}</h4>
            <div data-test-id={`public-details-${languageCode}`} className={commonStyles.whiteSpacePreLine}>
              <ReactMarkdown children={data.localizedDetails[languageCode] ?? ''} skipHtml={true} />
            </div>
            {!!data.localizedLink && data.localizedLink[languageCode] && (
              <div className="mt-3">
                <a
                  data-test-id={`public-link-${languageCode}`}
                  href={data.localizedLink[languageCode].url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.localizedLink[languageCode].title}
                </a>
              </div>
            )}
          </div>
        )}
      />
      <div className={styles.imagesContainer} data-test-id="obj-p-images">
        {extraImageSrc.map((image, index) => (
          <ClickableImage fluid key={index + image} src={image} width={200} rounded />
        ))}
      </div>
      {data.modelAuthor && (
        <div className={`mt-5 ${styles.textBlock}`}>
          <h6>3D model author</h6>
          <div data-test-id="public-modelAuthor" className={commonStyles.whiteSpacePreLine}>
            {data.modelAuthor.displayName} ({data.modelAuthor.id})
          </div>
        </div>
      )}
      {data.modelAuthorNote && (
        <div className={styles.textBlock}>
          <h6>3D model author personal note</h6>
          <div data-test-id="public-modelAuthorNote" className={commonStyles.whiteSpacePreLine}>
            {data.modelAuthorNote}
          </div>
        </div>
      )}
    </div>
  );
}

export default PublicInfo;
