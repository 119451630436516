import constants from '../../constants';
import axios from 'axios';
import HelperService from '../HelperService';
import { IMimeType } from '../../types/common';
import CommonService from '../CommonService';
import moment from 'moment-timezone';
import {
  ICouponCollectionConditions,
  ICouponCampaign,
  ICouponCampaignDisplay,
  ICouponEditForm,
  ICouponObjectConditions,
} from '../../types/coupons';

class CouponsService {
  createCoupon = async (coupon: ICouponEditForm, tz: string | null) => {
    try {
      const url = `${constants.API_ADMIN_COUPONS}/CreateCampaign`;

      var createData = {
        advertiserId: coupon.advertiserId,
        name: coupon.name,
        startOn: coupon.startOn,
        endOn: coupon.endOn,
        teaserTitle: coupon.teaserTitle,
        teaserDescription: coupon.teaserDescription,
        teaserButton: coupon.teaserButton,

        couponTitle: coupon.couponTitle,
        couponDescription: coupon.couponDescription,
        couponButton: coupon.couponButton,

        isActive: coupon.isActive,
      };

      const newId = await axios.post(url, createData).then((res) => res.data.id);

      if (coupon.couponImagePath != null || coupon.teaserImagePath != null) {
        var updateData = { ...coupon, id: newId };
        await this.updateCoupon(updateData, tz);
      }

      return newId;
    } catch {
      return false;
    }
  };
  updateCoupon = async (coupon: ICouponEditForm, tz: string | null) => {
    try {
      const url = `${constants.API_ADMIN_COUPONS}/UpdateCampaign`;

      let imageUpdate = false;
      var { couponImagePath, teaserImagePath } = coupon;
      if (couponImagePath != null && couponImagePath.startsWith('data:')) {
        couponImagePath = await this.uploadCouponImage(couponImagePath, coupon.id);
        imageUpdate = true;
      }

      if (teaserImagePath != null && teaserImagePath.startsWith('data:')) {
        teaserImagePath = await this.uploadCouponImage(teaserImagePath, coupon.id);
        imageUpdate = true;
      }

      const updateData = {
        id: coupon.id,
        name: coupon.name,
        startOn: coupon.startOn,
        endOn: coupon.endOn,
        teaserTitle: coupon.teaserTitle,
        teaserDescription: coupon.teaserDescription,
        teaserButton: coupon.teaserButton,

        couponTitle: coupon.couponTitle,
        couponDescription: coupon.couponDescription,
        couponButton: coupon.couponButton,

        isActive: coupon.isActive,
        teaserImagePath,
        couponImagePath,
      };

      await axios.post(url, updateData).then();
      if (imageUpdate) await HelperService.delay(5000);

      return true;
    } catch {
      return false;
    }
  };
  getCouponStatus = (isActive: boolean, startOn: number, endOn: number): string => {
    if (!isActive) return 'Draft';
    const now = new Date().getTime();
    if (endOn < now) return 'Expired';
    if (startOn > now) return 'Scheduled';
    return 'Active';
  };

  getCoupons = (advertiserId: any, tz: string | null = null) => {
    const url = `${constants.API_ADMIN_COUPONS}/GetList?advertiserId=${advertiserId}`;
    const timeZone = tz ? tz : moment.tz.guess();

    return axios.get(url).then((res): ICouponCampaignDisplay[] =>
      res.data.map((x: ICouponCampaign) => ({
        ...x,
        status: this.getCouponStatus(x.isActive, x.startOn, x.endOn),
        startDisplayOn: moment.tz(x.startOn, timeZone).format('ll'),
        endDisplayOn: moment.tz(x.endOn, timeZone).format('ll'),
      })),
    );
  };

  getCoupon = (couponId: string, tz: string | null = null) => {
    const url = `${constants.API_ADMIN_COUPONS}/Get?id=${couponId}`;
    const timeZone = tz ? tz : moment.tz.guess();

    return axios.get(url).then(
      (x): ICouponCampaignDisplay => ({
        ...x.data,
        status: this.getCouponStatus(x.data.isActive, x.data.startOn, x.data.endOn),
        startDisplayOn: moment.tz(x.data.startOn, timeZone).format('ll'),
        endDisplayOn: moment.tz(x.data.endOn, timeZone).format('ll'),
      }),
    );
  };

  uploadCouponImage = async (imageUrl: string, campaignId: string) => {
    const getUploadUrl = `${constants.API_ADMIN_COUPONS}/GetImageUploadUrl`;
    const file: File = await HelperService.dataUrlToFile(imageUrl, 'image.jpg', IMimeType.imageJpeg);
    const uploadResult = await CommonService.uploadFile(getUploadUrl, file, { campaignId });
    return uploadResult.permanentUrl;
  };

  deleteCoupon = async (id: string) => {
    const url = `${constants.API_ADMIN_COUPONS}/DeleteCampaign?id=${id}`;
    try {
      await axios.delete(url).then();
      return true;
    } catch {
      return false;
    }
  };

  getObjectConditions = (couponId: string, objectIds: string[]) => {
    const url = `${constants.API_ADMIN_COUPONS}/ObjectConditions`;

    return axios.post(url, { couponId, objectIds }).then((x): ICouponObjectConditions[] => x.data);
  };

  getCollectionConditions = (couponId: string, collectionIds: string[]) => {
    const url = `${constants.API_ADMIN_COUPONS}/CollectionConditions`;

    return axios.post(url, { couponId, collectionIds }).then((x): ICouponCollectionConditions[] => x.data);
  };

  assignObject = async (campaignId: string, mapObjectId: string, numRequiredCollects: number = 1) => {
    const url = `${constants.API_ADMIN_COUPONS}/ConnectMapObject`;
    try {
      await axios.post(url, { campaignId, mapObjectId, numRequiredCollects }).then();
      return true;
    } catch {
      return false;
    }
  };

  unassignObject = async (campaignId: string, mapObjectId: string) => {
    const url = `${constants.API_ADMIN_COUPONS}/DisconnectMapObject`;
    try {
      await axios.post(url, { campaignId, mapObjectId }).then();
      return true;
    } catch {
      return false;
    }
  };

  assignCollection = async (
    campaignId: string,
    collectionId: string,
    isFullCompletionRequired: boolean = false,
    requiredObjectIds: string[] = [],
    numRequiredCollects: number = 1,
  ) => {
    const url = `${constants.API_ADMIN_COUPONS}/ConnectCollection`;
    try {
      await axios
        .post(url, { campaignId, collectionId, isFullCompletionRequired, requiredObjectIds, numRequiredCollects })
        .then();
      return true;
    } catch {
      return false;
    }
  };

  unassignCollection = async (campaignId: string, collectionId: string) => {
    const url = `${constants.API_ADMIN_COUPONS}/DisconnectCollection`;
    try {
      await axios.post(url, { campaignId, collectionId }).then();
      return true;
    } catch {
      return false;
    }
  };
}

export default new CouponsService();
