import React from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { UserRole } from '../../types/userManagement';

interface IProps {
  roles: UserRole[];
  children: any;
}
function PrivateAccess({ children, roles }: IProps) {
  const userRole = useTypedSelector((store) => store.auth.role);

  return userRole && roles.includes(userRole) ? children : <></>;
}

export default PrivateAccess;
