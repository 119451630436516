import React, { Ref } from 'react';
import Scene from './Scene';
import { Canvas } from '@react-three/fiber';
import { Environment } from '@react-three/drei/core/Environment';
import * as THREE from 'three';
import { envPresetType } from '../../../types/objects';

interface IProps {
  model: string;
  width: number;
  zoom: number;
  exposure: number;
  preset: envPresetType;
  rotation: number;
  verticalRotation: number;
  canvasRef: Ref<HTMLCanvasElement>;
}

function ModelCanvas({ model, width, zoom, exposure, preset, rotation, verticalRotation, canvasRef }: IProps) {
  var envMap = preset ? `/${preset}-map.hdr` : '/environment-map.hdr';
  return (
    <Canvas
      ref={canvasRef}
      gl={{
        preserveDrawingBuffer: true,
        toneMapping: THREE.ACESFilmicToneMapping,
        toneMappingExposure: exposure,
      }}
      camera={{
        fov: 75,
        near: 0.1,
        far: 1000,
        position: [0, 0, width / 100],
        zoom: width / 100,
      }}
    >
      <Scene model={model} rotation={rotation} verticalRotation={verticalRotation} scale={zoom} />
      <Environment files={envMap} />

      <directionalLight color="white" position={[5, 5, 10]} intensity={1} />
    </Canvas>
  );
}

export default ModelCanvas;
