import React, { Suspense } from 'react';
import { useGLTF } from '@react-three/drei/core/Gltf';

interface IProps {
  scale: number;
  rotation: number;
  verticalRotation: number;
  model: string;
}

const deg2rad = (degrees: number) => degrees * (Math.PI / 180);

function Scene({ model, rotation, verticalRotation, scale }: IProps) {
  const { scene } = useGLTF(model);

  return (
    <Suspense fallback={null}>
      <mesh rotation={[deg2rad(verticalRotation), deg2rad(rotation), 0]}>
        <primitive object={scene} scale={scale} />
      </mesh>
    </Suspense>
  );
}

export default Scene;
