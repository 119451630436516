import ConditionsEditorForm from './ConditionsEditorForm';
import { EditorLayout } from './EditorLayout';
import { useObjectEditor } from './useObjectEditor';

interface IProps {
  backToInfo: () => void;
}

function ConditionsEditor({ backToInfo }: IProps) {
  const { status, onSaveClick, onCancelClick, object, handleObjectChange } = useObjectEditor(backToInfo);

  return (
    <EditorLayout loadingStatus={status} onSaveClick={onSaveClick} onCancelClick={onCancelClick}>
      <ConditionsEditorForm object={object} objectChange={handleObjectChange} />
    </EditorLayout>
  );
}

export default ConditionsEditor;
