import { IDisplayObject, IFileWithUrl, ILink, ILocalizedContent } from './common';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'model-viewer': MyElementAttributes;
    }
    interface MyElementAttributes {
      src: string;
      'interaction-prompt': string;
      'environment-image': string;
      children?: never[];
      alt?: string;
      ar?: boolean;
      autoplay?: boolean;
      'disable-zoom'?: boolean;
      'camera-controls'?: boolean;
      'camera-orbit'?: string;
      'touch-action'?: string;
      ref?: React.MutableRefObject<any>;
    }
  }
}

export enum IObjectState {
  Published = 'Published',
  Active = 'Active',
  Archived = 'Archived',
  New = 'New',
  Draft = 'Draft',
}

export enum ObjectPageState {
  info,
  versionEditor,
  draftEditor,
  adminEditor,
  conditionsEditor,
  newObject,
  modelGenerator,
  featuredInfoEditor,
}

export interface IObject {
  id: string;
  internalName: string;
  iconUrl: string;
  country: IDisplayObject;
  region: IDisplayObject;
  place: IDisplayObject;
  tags: string[];
  status: IObjectState;
  draft: IObjectState | null;
}

export interface IObjectMap {
  id: string;
  internalName: string;
  location: {
    lat: number;
    lon: number;
  };
}

export interface ITrophyExportRecord {
  ownerId: string;
  ownerEmail: string;
  objectId: string;
  objectName: string;
  timestamp: number | string;
}

export enum CollectionDistance {
  Minimum = 'Minimum',
  ExtraClose = 'ExtraClose',
  Close = 'Close',
  Default = 'Default',
  AboveMedium = 'AboveMedium',
  Far = 'Far',
  ExtraFar = 'ExtraFar',
}
export interface ICollectionConditions {
  maxCollections?: number | null;
  question?: string | null;
  answer?: string | null;
  requiredObjectId?: string | null;
}
export interface IFeaturedData {
  isActive: boolean;
  isForceShow: boolean;
  localizedFeaturedTitle: ILocalizedContent<string>;
  localizedFeaturedDescription: ILocalizedContent<string>;
}
export interface IObjectAdmin {
  id: string;
  internalName: string;
  country: IDisplayObject | null;
  region: IDisplayObject | null;
  place: IDisplayObject | null;
  tags: string[];
  notes: string;
  adminPictures: IFileWithUrl[];
  sharingUrl?: string;
  scheduleId: string | null;
  sendAnnouncements: boolean;
  collectionDistance: CollectionDistance;
  collectionConditions: ICollectionConditions;
  featuredData?: IFeaturedData | null;
}

export const initObjectAdmin: IObjectAdmin = {
  id: '',
  internalName: '',
  country: null,
  region: null,
  place: null,
  tags: [],
  notes: '',
  adminPictures: [],
  scheduleId: null,
  sendAnnouncements: true,
  collectionDistance: CollectionDistance.Default,
  collectionConditions: {},
  featuredData: null,
};

export interface IObjectVersion {
  id: string;
  objectId?: string;
  versionName: string;
  localizedName: ILocalizedContent<string>;
  localizedDetails: ILocalizedContent<string>;
  localizedLink: ILocalizedContent<ILink> | null;
  imageFile: string;
  iconFile: string;
  modelFile: string;
  iosModelFile: string;
  animated: boolean;
  location: {
    lat: number;
    lon: number;
  };
  extraPhotos: string[];
  assetsBaseUrl: string;
  state: IObjectState;
  modelAuthor: { id: string; displayName: string };
  modelAuthorNote: string;
}

export interface IObjectVersionForm {
  id: string;
  objectId: string;
  localizedName: ILocalizedContent<string>;
  localizedDetails: ILocalizedContent<string>;
  localizedLink: ILocalizedContent<ILink> | null;
  coordinates: string;
  extraPhotos: IFileWithUrl[];
  animated: boolean;
  modelAuthor: string;
  modelAuthorNote: string;
}

export const initObjectVersionForm: IObjectVersionForm = {
  id: '',
  objectId: '',
  localizedName: {
    en: '',
    ja: '',
    es: '',
    ru: '',
  },
  localizedDetails: {
    en: '',
    ja: '',
    es: '',
    ru: '',
  },
  localizedLink: null,
  coordinates: '',
  extraPhotos: [],
  animated: false,
  modelAuthor: '',
  modelAuthorNote: '',
};

export interface IObjectModel {
  model?: IFileWithUrl;
  iosModel?: IFileWithUrl;
  image?: IFileWithUrl;
  icon?: IFileWithUrl;
  rotation?: number;
  animated?: boolean;
}

export const getCollectionDistanceLabel = (distance: CollectionDistance) => {
  switch (distance) {
    case CollectionDistance.Minimum:
      return 'Minimum range (15m)';
    case CollectionDistance.ExtraClose:
      return 'Close range (50m)';
    case CollectionDistance.Close:
      return 'Below normal (75m)';
    case CollectionDistance.Default:
      return 'Normal (100m)';
    case CollectionDistance.AboveMedium:
      return 'Above normal (200m)';
    case CollectionDistance.Far:
      return 'Distant (300m)';
    case CollectionDistance.ExtraFar:
      return 'Most distant (500m)';
    default:
      return distance;
  }
};

export const envPresets = [
  undefined,
  'sunset',
  'dawn',
  'night',
  'warehouse',
  'forest',
  'apartment',
  'studio',
  'city',
  'park',
  'lobby',
] as const;
export type envPresetType = typeof envPresets[number];
