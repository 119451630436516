import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from 'styles/details.module.css';
import { IStatus } from 'types/common';
import { Button } from 'react-bootstrap';
import { IDeal, IDealForm, IDealStatus, initDealForm } from 'types/advertisers';
import { showModal } from 'store/action-creators/modal';
import { useDispatch } from 'react-redux';
import DealsService from 'services/Advertisers/DealsService';
import DealEditorForm from './DealEditorForm';
import moment from 'moment';
import DealsEditorLocations from './DealsEditorLocations';
import HelperService from 'services/HelperService';
import DealStatus from '../../DealStatus';
import { AlertType } from 'types/alerts';
import constants from 'constants/index';
import PublishSwitcher from '../Common/PublishSwitcher';
import AlertsService from 'store/services/AlertsService';
import LoadingWrapper from 'components/common/LoadingWrapper';
import ModalInfoService from 'store/services/ModalInfoService';
import PrivateAccess from 'components/common/PrivateAccess';
import { UserRole } from 'types/userManagement';
import { useTypedSelector } from 'hooks/useTypedSelector';
import DealDisplayFormInfo from './DealDisplayFormInfo';
import DealEditorLocationsInfo from './DealEditorLocationsInfo';
import AdvertiserService from 'services/Advertisers/AdvertiserService';
import { useExitConfirmation } from 'hooks/useExitConfirmation';

function DealEditor() {
  const userRole = useTypedSelector((store) => store.auth.role);
  const [publish, setPublish] = useState<boolean>(false);
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [dealForm, setDealForm] = useState<IDealForm | null>(null);
  const [locations, setLocations] = useState<string[]>([]);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  const [tz, setTz] = useState<string | null>(null);
  let { adId } = useParams<{ adId: string }>();
  let { dealId } = useParams<{ dealId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadAdData = async (id: string) => {
      try {
        return await AdvertiserService.getAdvertiser(id);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    const loadDealData = async (id: string, tz: string | null) => {
      try {
        return await DealsService.getDeal(id, tz);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    if (adId) {
      loadAdData(adId).then((resAd) => {
        if (resAd === undefined) return;
        setTz(resAd.displayTimeZone);
        if (dealId) {
          loadDealData(dealId, resAd.displayTimeZone).then((res) => {
            if (res) {
              generateDealForm(res).then();
              setPublish(res.status !== IDealStatus.Draft);
            }
          });
        } else {
          if (adId)
            setDealForm({
              ...initDealForm,
              advertiserId: adId,
            });
          setStatus(IStatus.Success);
        }
      });
    }
  }, [dealId, adId]);

  const generateDealForm = async (deal: IDeal) => {
    setDealForm({
      ...deal,
      imageUrl: (await HelperService.isFileExist(deal.imageUrl)) ? deal.imageUrl + '?ts=' + Date.now() : '',
      link: deal.link?.url || '',
      linkTitle: deal.link?.title || '',
      endDisplayOn: moment(deal.endDisplayOn).format('YYYY-MM-DD'),
      startDisplayOn: moment(deal.startDisplayOn).format('YYYY-MM-DD'),
    });
    setLocations(deal.locations.map((l) => l.id));
    setStatus(IStatus.Success);
  };

  const handleDealChange = (name: string, value: any) => {
    setHasChanges(true);
    setDealForm((prevState) => (prevState ? { ...prevState, [name]: value } : null));
    let errorId = name;
    if (name === 'startDisplayOn' || name === 'endDisplayOn') errorId = 'dates';
    errorValues.includes(errorId) && setErrorValues((prevState) => prevState.filter((er) => er !== errorId));
  };

  const handleLocationsChange = (locations: string[]) => {
    setHasChanges(true);
    setLocations(locations);
  };

  const onSaveClick = async () => {
    if (dealForm && (await isDealFormValid(dealForm, 'save', dealForm.status !== IDealStatus.Draft))) {
      ModalInfoService.showModalInfoLoading();
      (dealId
        ? DealsService.updateDeal(dealForm, locations, tz)
        : DealsService.createDeal(dealForm, locations, tz)
      ).then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          handleBackToAdInfo();
        } else {
          ModalInfoService.showModalInfoError('We have problems saving deal');
        }
      });
    }
  };

  const isDealFormValid = async (deal: IDealForm, action: string, deepCheck = true): Promise<boolean> => {
    let possible: boolean = true;
    setErrorValues([]);
    if (!deal.name) {
      saveProblemAlert(action, 'Admin deal identifier is empty');
      setErrorValues((prevState) => [...prevState, 'name']);
      possible = false;
    }
    if (deal.link && !deal.link.match(constants.URL_REGEXP)) {
      saveProblemAlert(action, 'Link is not valid');
      setErrorValues((prevState) => [...prevState, 'link']);
      possible = false;
    }
    if (deepCheck) {
      if (Date.parse(deal.startDisplayOn) >= Date.parse(deal.endDisplayOn)) {
        saveProblemAlert(action, 'Dates are incorrect');
        setErrorValues((prevState) => [...prevState, 'dates']);
        possible = false;
      }
      if (locations.length < 1) {
        saveProblemAlert(action, 'At least one location should be selected');
        setErrorValues((prevState) => [...prevState, 'locations']);
        possible = false;
      }
      if (!(deal.imageUrl && (await HelperService.isFileExist(deal.imageUrl)))) {
        saveProblemAlert(action, 'Deal image should be set');
        setErrorValues((prevState) => [...prevState, 'imageUrl']);
        possible = false;
      }
    }
    return possible;
  };

  const saveProblemAlert = (action: string, text: string) => {
    AlertsService.addAlert(text, AlertType.WARNING);
  };

  const handleBackToAdInfo = () => {
    navigate(`/advertisers/${adId}/deals`);
  };

  const onCancelClick = useExitConfirmation({ shouldConfirm: hasChanges, onExit: handleBackToAdInfo });

  const handlePublishSwitch = async () => {
    const published = publish;
    setPublish(!published);
    if (published) {
      handleDealChange('status', IDealStatus.Draft);
    } else {
      if (dealForm && (await isDealFormValid(dealForm, 'publish'))) {
        handleDealChange(
          'status',
          DealsService.getDealStatus(Date.parse(dealForm.startDisplayOn), Date.parse(dealForm.endDisplayOn)),
        );
      } else {
        setTimeout(() => setPublish(false), 300);
      }
    }
  };

  const onDeleteClick = async () => {
    dispatch(
      showModal({
        title: 'Delete',
        text: 'This will delete the deal. Proceed?',
        primaryAction: handleDelete,
        primaryText: 'Delete deal',
        secondaryText: 'Cancel',
      }),
    );
  };

  const handleDelete = async () => {
    if (dealId && (await DealsService.deleteDeal(dealId))) handleBackToAdInfo();
  };

  const onDuplicateClick = async () => {
    if (dealForm && (await isDealFormValid(dealForm, 'duplicate', false))) {
      ModalInfoService.showModalInfoLoading();
      const newDealId = await DealsService.duplicateDeal(dealForm, locations);
      ModalInfoService.closeModalInfo();
      if (newDealId) {
        setStatus(IStatus.Loading);
        navigate(`/advertisers/${adId}/deals/${newDealId}`);
        AlertsService.addAlert('New deal was created \n You’re editing a duplicate now', AlertType.SUCCESS);
      }
    }
  };

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <>
            <div className={styles.w50EditorContainer}>
              {dealForm &&
                (userRole === UserRole.Reader ? (
                  <DealDisplayFormInfo deal={dealForm} />
                ) : (
                  <DealEditorForm deal={dealForm} dealChange={handleDealChange} errorValues={errorValues} />
                ))}
            </div>
            <div className={styles.w50EditorContainer}>
              {userRole === UserRole.Reader ? (
                <DealEditorLocationsInfo locations={dealForm ? dealForm.locations : []} />
              ) : (
                <DealsEditorLocations selected={locations} onSelectChange={handleLocationsChange} />
              )}
            </div>
          </>
        </LoadingWrapper>
      </div>
      <div className={`${styles.buttonContainer} d-flex justify-content-between`}>
        <div>
          <Button data-test-id="deal-editor-cancel" variant="light" size="sm" onClick={onCancelClick}>
            Cancel
          </Button>
          <PrivateAccess roles={[UserRole.Admin, UserRole.Editor, UserRole.AdvertisingManager]}>
            {dealId && (
              <>
                <Button data-test-id="deal-editor-duplicate" variant="primary" size="sm" onClick={onDuplicateClick}>
                  Duplicate
                </Button>
                <Button data-test-id="deal-editor-delete" variant="danger" size="sm" onClick={onDeleteClick}>
                  Delete
                </Button>
              </>
            )}
          </PrivateAccess>
        </div>
        <PrivateAccess roles={[UserRole.Admin, UserRole.Editor, UserRole.AdvertisingManager]}>
          <div className="d-flex align-items-center">
            <DealStatus value={dealForm?.status} />
            <PublishSwitcher publish={publish} publishSwitch={handlePublishSwitch} />
            <Button className="ms-4" data-test-id="deal-editor-save" variant="primary" size="sm" onClick={onSaveClick}>
              Save
            </Button>
          </div>
        </PrivateAccess>
      </div>
    </div>
  );
}

export default DealEditor;
