import React, { useEffect, useState } from 'react';
import LocationsService from '../../../../services/Advertisers/LocationsService';
import { useParams } from 'react-router-dom';
import { ILocation } from '../../../../types/advertisers';
import { Form } from 'react-bootstrap';
import styles from '../../advertisers.module.css';

interface IProps {
  selected: string[];
  onSelectChange: (selected: string[]) => void;
}
function DealsEditorLocations({ selected, onSelectChange }: IProps) {
  const [locations, setLocations] = useState<ILocation[]>([]);
  let { adId } = useParams<{ adId: string }>();

  useEffect(() => {
    const loadLocationsData = async (id: string) => {
      try {
        return await LocationsService.getLocations(id);
      } catch {}
    };
    if (adId) {
      loadLocationsData(adId).then((res) => {
        if (res) setLocations(res);
      });
    }
  }, [adId]);

  const isChecked = (item: string) => selected.includes(item);

  const listItemClick = (item: string) => {
    const index = selected.indexOf(item);
    index !== -1 ? selected.splice(index, 1) : selected.push(item);
    onSelectChange([...selected]);
  };

  const allInputRef = (input: any) => {
    if (input) {
      input.indeterminate = selected.length > 0 && selected.length !== locations.length;
    }
  };

  const allClick = () => {
    onSelectChange(selected.length === locations.length ? [] : locations.map((l) => l.id));
  };

  const renderItem = (loc: ILocation) => (
    <Form.Check key={loc.id} id={`loc-${loc.id}`} className="mb-3">
      <Form.Check.Input checked={isChecked(loc.id)} onChange={() => listItemClick(loc.id)} />
      <Form.Check.Label>
        <div>
          <div>{loc.name}</div>
          <small className={styles.lightText}>{loc.address}</small>
        </div>
      </Form.Check.Label>
    </Form.Check>
  );

  return (
    <div data-test-id="deal-editor-locations">
      <h3 className="mb-3">
        Location &nbsp; {selected.length} of {locations.length}
      </h3>
      <Form.Check id="loc-all" className="mb-3">
        <Form.Check.Input
          data-test-id="all-check"
          ref={allInputRef}
          onChange={allClick}
          checked={selected.length === locations.length}
        />
        <Form.Check.Label>
          <div>All</div>
        </Form.Check.Label>
      </Form.Check>
      <div className={styles.checkboxList} data-test-id="checks-container">
        {locations.map((loc) => renderItem(loc))}
      </div>
    </div>
  );
}

export default DealsEditorLocations;
