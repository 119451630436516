import { AuthActions, AuthActionTypes, IAuth } from '../../types/auth';

const initialState: IAuth = {
  isAuth: null,
  role: null,
  email: '',
};

export const authReducer = (state = initialState, action: AuthActions): IAuth => {
  switch (action.type) {
    case AuthActionTypes.SET_EMAIL: {
      return { ...state, email: action.payload.email };
    }
    case AuthActionTypes.LOGIN: {
      return { ...state, isAuth: true, role: action.payload.role };
    }
    case AuthActionTypes.LOGOUT: {
      return { isAuth: false, role: null, email: '' };
    }
    default:
      return state;
  }
};
