import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ObjectsService from '../../services/Objects/ObjectsService';
import { RootState } from 'store/reducers';

function ObjectsListActions(state: RootState) {
  function handleExportHistory() {
    let search = state.navbar.searchString;
    ObjectsService.getTrophyCollectionHistory(search).then((data) => downloadCSV(data, 'collect-history'));
  }

  function handleExportCollectors() {
    let search = state.navbar.searchString;
    ObjectsService.getTrophyCollectionHistory(search).then((data) => {
      let recsToExport = Array.from(new Set(data.map((x) => x.ownerEmail)));

      // Sort the array
      recsToExport.sort();
      downloadCSV(
        recsToExport.map((x) => {
          return { email: x };
        }),
        'collectors',
      );
    });
  }

  function downloadCSV(jsonData: any[], prefix = 'export') {
    if (!jsonData || jsonData.length === 0) {
      alert('No data to export');
    }
    // Convert JSON to CSV
    let csvRows = [];
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(','));

    for (const row of jsonData) {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    const csvString = csvRows.join('\n');

    // Create Blob and URL
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${prefix}-${Date.now()}.csv`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Clean up the URL
    window.URL.revokeObjectURL(url);
  }
  return (
    <DropdownButton id="dropdown-basic-button" title="Actions" variant="light" size="sm" align="end">
      <Dropdown.Item as={'button'} onClick={(e) => handleExportCollectors()}>
        Export collectors
      </Dropdown.Item>
      <Dropdown.Item as={'button'} onClick={(e) => handleExportHistory()}>
        Export collection history
      </Dropdown.Item>
    </DropdownButton>
  );
}

export default ObjectsListActions;
