import React, { useEffect } from 'react';
import { Alert, Button, Form, ListGroup, Table } from 'react-bootstrap';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { ICollection, IColObjectItem } from '../../../types/collections';
import CollectionLookup, { LookupRef } from '../../common/CollectionLookup';
import { ICouponCollectionConditions } from '../../../types/coupons';
import CollectionObjectsService from '../../../services/Collections/CollectionObjectsService';
import ObjectsService from '../../../services/Objects/ObjectsService';
import { IObject } from '../../../types/objects';
import { ILanguageCode } from '../../../types/common';

interface IProps {
  collections: ICollection[] | null;
  collectionConditions: ICouponCollectionConditions[] | null;
  emptyPlaceholder?: string;
  onCollectionAdded?: (
    collectionId: string,
    isFullCompletionRequired: boolean,
    requiredObjectIds: string[],
    numRequiredCollects: number,
  ) => void;
  onCollectionRemoved?: (collectionId: string) => void;
}
function CollectionsListWithAddRemove({
  collections,
  collectionConditions,
  emptyPlaceholder,
  onCollectionAdded,
  onCollectionRemoved,
}: IProps) {
  // TODO: use localization for admin content
  const defaultLanguage = ILanguageCode.English;

  const [collectionId, setCollectionId] = React.useState<string>('' as string);
  const [requiredObjects, setRequiredObjects] = React.useState<IObject[]>([]);
  const [isFullCompletionRequired, setIsFullCompletionRequired] = React.useState<boolean>(false);
  const [requiredObjectIds, setRequiredObjectIds] = React.useState<string[]>([]);
  const [collectionObjects, setCollectionObjects] = React.useState<IColObjectItem[]>([]);
  const [numRequiredCollects, setNumRequiredCollects] = React.useState<number>(1);
  const typeahead = React.useRef<LookupRef>(null);

  const loadRequiredObjects = (ids: string[]) => {
    const loadData = async () => {
      try {
        return await ObjectsService.getByIdList(ids);
      } catch {
        setRequiredObjects([]);
      }
    };
    loadData().then((res) => {
      if (res) {
        setRequiredObjects(res);
      } else {
        setRequiredObjects([]);
      }
    });
  };

  useEffect(() => {
    if (collectionConditions) {
      const ids: string[] = [];
      collectionConditions.forEach((c) => ids.push(...c.requiredObjectIds));

      if (ids.length) {
        loadRequiredObjects(ids);
      } else {
        setRequiredObjects([]);
      }
    } else {
      setRequiredObjects([]);
    }
  }, [collectionConditions]);

  const loadObjects = (collectionId: string) => {
    const loadData = async () => {
      try {
        return await CollectionObjectsService.getObjects(collectionId);
      } catch {
        setCollectionObjects([]);
      }
    };
    loadData().then((res) => {
      if (res) {
        setCollectionObjects(res);
      } else {
        setCollectionObjects([]);
      }
    });
  };

  useEffect(() => {
    if (collectionId) {
      loadObjects(collectionId);
    } else {
      setCollectionObjects([]);
    }
  }, [collectionId]);

  const handleAddCollection = () => {
    if (onCollectionAdded)
      onCollectionAdded(collectionId, isFullCompletionRequired, requiredObjectIds, numRequiredCollects);

    setCollectionId('');
    setNumRequiredCollects(1);
    setIsFullCompletionRequired(false);
    setRequiredObjectIds([]);
    typeahead.current?.clear();
    setCollectionObjects([]);
  };

  const handleRemoveCollection = (collectionId: string) => {
    if (onCollectionRemoved) onCollectionRemoved(collectionId);
  };
  if (!collections) return <div></div>;

  return (
    <div>
      <Alert variant="warning">Changes to this section will be applied immediately.</Alert>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Location</th>
            <th>Conditions</th>
            <th>Mandatory objects</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {collections.map((collection, index) => {
            const condition = collectionConditions?.find((c) => c.collectionId === collection.id);
            return (
              <tr key={index} style={{ verticalAlign: 'middle' }}>
                <td>
                  <img
                    src={collection.iconUrl}
                    alt={collection.localizedName[defaultLanguage]}
                    className="rounded"
                    style={{ width: '40px' }}
                  />
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href={`${window.location.origin}/collections/${collection.id}`}>
                    {collection.localizedName[defaultLanguage]}
                  </a>
                </td>
                <td>
                  {[collection.country?.displayName, collection.region?.displayName, collection.place?.displayName]
                    .filter((x) => x != null)
                    .join(', ')}
                </td>
                <td>
                  {condition && condition.isFullCompletionRequired
                    ? 'Complete full collection'
                    : `${
                        condition && condition.numRequiredCollects > 1
                          ? `Collect at least ${condition.numRequiredCollects} trophies`
                          : 'Collect any trophy'
                      } from the collection`}
                </td>
                <td>
                  <ListGroup style={{ maxHeight: '200px', overflow: 'auto', fontSize: 'small' }}>
                    {condition?.requiredObjectIds.map((object, index) => {
                      const requiredObject = requiredObjects.find((o) => o.id === object);
                      return (
                        requiredObject && (
                          <ListGroup.Item className="pt-0 pb-0 ps-0" key={object}>
                            <img
                              src={requiredObject.iconUrl}
                              alt={requiredObject.internalName}
                              className="rounded"
                              style={{ width: '30px' }}
                            />{' '}
                            {requiredObject.internalName}
                          </ListGroup.Item>
                        )
                      );
                    })}
                  </ListGroup>
                </td>
                <td width="50px">
                  <Button variant="danger" size="sm" onClick={(e) => handleRemoveCollection(collection.id)}>
                    <BiTrash className="mb-1" />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {collections.length === 0 && <div>{emptyPlaceholder}</div>}

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Collection</th>
            <th>Is full completion required?</th>
            <th>Number of required collects</th>
            <th>Mandatory objects</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ verticalAlign: 'middle' }}>
            <td width="250px">
              <CollectionLookup
                name="select-collection"
                value={collectionId || ''}
                onChange={(e) => setCollectionId(e.target.value)}
                typeaheadRef={typeahead}
                filter={(option) => !collections.some((c) => c.id === (option as any).id)}
              />
            </td>
            <td width="150px">
              <Form.Check
                type="switch"
                checked={isFullCompletionRequired}
                id="full-completion"
                onChange={(e) => {
                  setIsFullCompletionRequired(e.target.checked);
                  if (e.target.checked) {
                    setNumRequiredCollects(1);
                    setRequiredObjectIds([]);
                  }
                }}
              />
            </td>
            <td width="150px">
              <Form.Control
                type="number"
                min="1"
                max={collectionObjects.length}
                name="numRequiredCollects"
                value={numRequiredCollects}
                disabled={isFullCompletionRequired}
                onChange={(e) => setNumRequiredCollects(parseInt(e.target.value))}
              />
            </td>
            <td>
              <ListGroup style={{ maxHeight: '250px', overflow: 'auto' }}>
                {collectionObjects.map((object, index) => {
                  return (
                    <ListGroup.Item key={object.id}>
                      <Form.Check type="checkbox">
                        <Form.Check.Input
                          className="mt-2"
                          type="checkbox"
                          id={object.id}
                          disabled={isFullCompletionRequired}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setRequiredObjectIds([...requiredObjectIds, e.target.id]);
                            } else {
                              setRequiredObjectIds(requiredObjectIds.filter((id) => id !== e.target.id));
                            }
                          }}
                        />
                        <Form.Check.Label>
                          <img
                            src={object.iconUrl}
                            alt={object.internalName}
                            className="rounded"
                            style={{ width: '30px' }}
                          />
                          {object.internalName}
                        </Form.Check.Label>
                      </Form.Check>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </td>
            <td width="50px">
              <Button variant="primary" size="sm" className="me-2" onClick={handleAddCollection}>
                <BiPlus className="mb-1" />
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default CollectionsListWithAddRemove;
