import { useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import { IStatus } from 'types/common';
import { initObjectAdmin, IObjectAdmin } from 'types/objects';
import ObjectAdminService from 'services/Objects/ObjectAdminService';
import ModalInfoService from 'store/services/ModalInfoService';
import { useExitConfirmation } from 'hooks/useExitConfirmation';

function getDefaultDisplayTimezone(response: any) {
  if (response.visibilitySchedule) {
    return response.displayTimeZone ? response.displayTimeZone : moment.tz.guess();
  }

  return null;
}

export const saveObject = async (object: IObjectAdmin, onSave: () => void) => {
  if (!object?.id) {
    return;
  }

  ModalInfoService.showModalInfoLoading();

  ObjectAdminService.editorSaveObject(object.id, object).then((res) => {
    if (res) {
      ModalInfoService.closeModalInfo();
      onSave();
    } else {
      ModalInfoService.showModalInfoError('We have problems saving object data');
    }
  });
};

export const useObjectEditor = (onExit: () => void) => {
  const { objectId } = useParams<{ objectId: string }>();
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [object, setObject] = useState<IObjectAdmin>(initObjectAdmin);

  useEffect(() => {
    const loadData = async (id: string) => {
      try {
        return await ObjectAdminService.getObjectAdmin(id);
      } catch (e) {
        setStatus(IStatus.Error);
      }
    };
    if (objectId) {
      loadData(objectId).then((res) => {
        if (res) {
          setStatus(IStatus.Success);
          setObject({
            ...res,
            displayTimeZone: getDefaultDisplayTimezone(res),
            adminPictures: res.adminPictures.map((img: string) => ({
              url: img,
            })),
          });
        }
      });
    }
  }, [objectId]);

  const handleObjectChange = useCallback((values: { name: string; value: any }[]) => {
    setHasChanges(true);

    const changes = values.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    setObject((prev) => ({ ...prev, ...changes }));
  }, []);

  const onSaveClick = () => saveObject(object, onExit);
  const onCancelClick = useExitConfirmation({ shouldConfirm: hasChanges, onExit });

  return {
    status,
    object,
    hasChanges,
    handleObjectChange,
    onSaveClick,
    onCancelClick,
  };
};
