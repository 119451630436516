import { IModalInfo, ModalInfoActions, ModalInfoActionTypes } from '../../types/modal';

const initState: IModalInfo = { content: null, show: false };

export const modalInfoReducer = (state = initState, action: ModalInfoActions): IModalInfo => {
  switch (action.type) {
    case ModalInfoActionTypes.SHOW_MODAL_INFO: {
      return { ...action.payload, show: true };
    }
    case ModalInfoActionTypes.CLOSE_MODAL_INFO: {
      return initState;
    }
    default:
      return state;
  }
};
