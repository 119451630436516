import { Container, Form, Stack } from 'react-bootstrap';
import { IObjectAdmin, ICollectionConditions } from '../../../types/objects';
import styles from './AdminEditorForm.module.css';
import VisibilitySchedulesDropdown from '../../VisibilitySchedules/VisibilitySchedulesDropdown/VisibilitySchedulesDropdown';
import CollectionConditionsEditor from './CollectionConditions';

interface IProps {
  objectChange: (values: { name: string; value: any }[]) => void;
  object: IObjectAdmin;
}

function ConditionsEditorForm({ object, objectChange }: IProps) {
  const onUseScheduleUsageChanged = (event: any) => {
    if (event.target.value === 'always-visible') {
      objectChange([{ name: 'scheduleId', value: null }]);
    } else {
      objectChange([{ name: 'scheduleId', value: '' }]);
    }
  };

  const onScheduleChange = (scheduleId: string) => {
    objectChange([{ name: 'scheduleId', value: scheduleId }]);
  };

  const onCollectionConditionsChange = (data: ICollectionConditions) => {
    objectChange([{ name: 'collectionConditions', value: data }]);
  };

  return (
    <div className="mb-3">
      <Form>
        <h2>Editing '{object.internalName}'</h2>
        <Container fluid={true}>
          <div className={styles.formElementContainer}>
            <h5>Visibility schedule</h5>
            <Stack gap={3} direction="horizontal" style={{ minHeight: '38px' }}>
              <Form.Check
                type="radio"
                label="Always visible"
                value="always-visible"
                checked={object.scheduleId === null}
                onChange={onUseScheduleUsageChanged}
              />
              <Form.Check
                type="radio"
                label="Use schedule"
                value="use-schedule"
                checked={object.scheduleId !== null}
                onChange={onUseScheduleUsageChanged}
              />

              {object.scheduleId !== null && (
                <>
                  <div className="vr" />
                  <VisibilitySchedulesDropdown value={object.scheduleId} onChange={onScheduleChange} />
                </>
              )}
            </Stack>
          </div>

          <div className="w-50">
            <h5>Collecting conditions</h5>
            <CollectionConditionsEditor
              objectId={object.id}
              {...object.collectionConditions}
              onChange={onCollectionConditionsChange}
            />
          </div>
        </Container>
      </Form>
    </div>
  );
}

export default ConditionsEditorForm;
