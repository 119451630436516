import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import AdvertiserNavbar from './AdvertiserNavbar';
import { IAdvertiserContext, IAdvertiserItem, initAdItem } from '../../../types/advertisers';
import AdvertiserService from '../../../services/Advertisers/AdvertiserService';
import LoadingWrapper from '../../common/LoadingWrapper';
import { IStatus } from '../../../types/common';

function AdvertiserDetails() {
  const [advertiser, setAdvertiser] = useState<IAdvertiserItem>(initAdItem);
  const [adContext, setAdContext] = useState<IAdvertiserContext>({
    locationsCount: 0,
    displayTimeZone: null,
  });
  let { adId } = useParams<{ adId: string }>();
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);

  useEffect(() => {
    const loadAdData = async (id: string) => {
      try {
        return await AdvertiserService.getAdvertiser(id);
      } catch {}
    };
    if (adId) {
      loadAdData(adId).then((res) => {
        if (res) {
          setAdvertiser(res);
          setAdContext({
            locationsCount: res.locationsCount || 0,
            displayTimeZone: res.displayTimeZone,
          });
          setStatus(IStatus.Success);
        }
      });
    }
  }, [adId]);

  return (
    <div>
      <AdvertiserNavbar advertiser={advertiser} />
      <LoadingWrapper status={status}>
        <Outlet context={[adContext]} />
      </LoadingWrapper>
    </div>
  );
}

export default AdvertiserDetails;
