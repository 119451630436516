import React from 'react';
import { Button, Form } from 'react-bootstrap';
import commonStyles from '../../../styles/common.module.css';
import { IFileWithUrl } from '../../../types/common';
import ClickableImage from '../../common/ClickableImage/ClickableImage';

interface IProp {
  image: IFileWithUrl | null;
  collectionChange: (values: { name: string; value: any }[]) => void;
}

function CollectionEditorImage({ image, collectionChange }: IProp) {
  const handleInputFileChange = (event: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = () =>
      collectionChange([
        {
          name: 'imageFile',
          value: { url: reader.result, file: event.target.files[0] },
        },
      ]);
  };

  const handleDeleteImageClick = () => {
    collectionChange([
      {
        name: 'imageFile',
        value: null,
      },
    ]);
  };
  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      {image === null ? (
        <Form.Group controlId="collectionImage">
          <Button variant="link" className="pb-0">
            <Form.Label className={`${commonStyles.pointer} mb-0`}>Upload title image</Form.Label>
          </Button>
          <Form.Control type="file" className="d-none" accept=".png,.jpeg,.jpg" onChange={handleInputFileChange} />
        </Form.Group>
      ) : (
        <ClickableImage
          style={{
            objectFit: 'cover',
            maxWidth: '100%',
          }}
          rounded
          width="400px"
          height="400px"
          alt="collection"
          src={image.url}
          deleteClick={handleDeleteImageClick}
        />
      )}
    </div>
  );
}

export default CollectionEditorImage;
