import React from 'react';

interface IProps {
  text?: string;
}
function ErrorContent({ text = 'We have problems' }: IProps) {
  return (
    <div className="d-flex justify-content-center m-5">
      <div>{text}</div>
    </div>
  );
}

export default ErrorContent;
