import { useState, ReactNode } from 'react';
import { Tab, Tabs, TabsProps } from 'react-bootstrap';
import { ILanguageCode } from 'types/common';
import { DEFAULT_LANGUAGE_CODE } from 'constants/l10n';

interface ILocalizationTabsProps extends TabsProps {
  defaultLanguageCode?: ILanguageCode;
  hasBorder?: boolean;
  renderTabContent: (languageCode: ILanguageCode, label: string) => ReactNode;
}

export const LocalizationTabs = ({
  renderTabContent,
  hasBorder,
  defaultLanguageCode = DEFAULT_LANGUAGE_CODE,
  ...props
}: ILocalizationTabsProps) => {
  const [activeKey, setActiveKey] = useState<ILanguageCode>(defaultLanguageCode);

  return (
    <div className={`localization-tabs-container${hasBorder ? ' border' : ''} rounded`}>
      <Tabs
        style={{ margin: '-1px' }}
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k as ILanguageCode)}
        {...props}
      >
        {Object.entries(ILanguageCode).map(([label, languageCode]) => (
          <Tab eventKey={languageCode} title={label} key={languageCode}>
            {renderTabContent(languageCode, label)}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};
