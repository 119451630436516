import React from 'react';
import { Form } from 'react-bootstrap';

interface IProps {
  publish: boolean;
  publishSwitch: () => void;
}
function PublishSwitcher({ publish, publishSwitch }: IProps) {
  return (
    <Form.Check type="switch" id="publish-switcher">
      <Form.Check.Label className="float-start" style={{ marginRight: '46px' }}>
        <b>Publish</b>
      </Form.Check.Label>
      <Form.Check.Input checked={publish} onChange={publishSwitch} />
    </Form.Check>
  );
}

export default PublishSwitcher;
