import React from 'react';
import { Button } from 'react-bootstrap';
import commonStyles from '../../styles/common.module.css';
import { BiArrowBack } from 'react-icons/bi';

const styles = {
  navigation: {
    padding: '4px',
    height: '40px',
  },
};

interface IProps {
  handleGoBack: () => void;
  text?: string;
}
function GoBack({ handleGoBack, text }: IProps) {
  return (
    <div style={styles.navigation}>
      <Button variant="link" className={commonStyles.noUnderline} size="sm" onClick={handleGoBack}>
        <BiArrowBack />
        &nbsp; {text || 'Go back'}
      </Button>
    </div>
  );
}

export default GoBack;
