import React from 'react';
import { IStatus } from '../../types/common';
import Loading from './Loading';

interface IProps {
  children: React.ReactNode;
  status: IStatus;
}
function LoadingWrapper({ status, children }: IProps) {
  return (
    <>
      {status === IStatus.Success && children}
      {status === IStatus.Loading && (
        <div className="p-1">
          <Loading />
        </div>
      )}
      {status === IStatus.Error && (
        <div className="d-flex justify-content-center p-5">
          <div>We have problems loading data...</div>
        </div>
      )}
    </>
  );
}

export default LoadingWrapper;
