import { ILanguageCode, ITableColumn } from '../../../../types/common';

function Columns(): ITableColumn[] {
  // TODO: use localization for admin content
  const defaultLanguage = ILanguageCode.English;

  return [
    {
      id: 'localizedName',
      displayName: 'Item',
      width: '30vw',
      pattern: (localizedName) => localizedName[defaultLanguage] ?? '',
    },
    {
      id: 'itemType',
      displayName: 'Type',
      width: '10vw',
    },
    {
      id: 'sponsorshipType',
      displayName: 'Ownership',
      width: '10vw',
    },
    {
      id: 'startsOn',
      displayName: 'Starts on',
      width: '10vw',
    },
    {
      id: 'expiresOn',
      displayName: 'Expires on',
      width: '10vw',
    },
  ];
}

export default Columns;
