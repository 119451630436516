import React, { useEffect, useState } from 'react';
import { IUserEditor, UserRole, userRolesStorage, UserRoleType } from '../../../types/userManagement';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import styles from '../UserManagement.module.css';
import FormInputLimited from '../../common/FormInputLimited';
import { IAdvertiserItemShort } from '../../../types/advertisers';
import AdvertiserService from '../../../services/Advertisers/AdvertiserService';
import AdvertiserMultiselect from './AdvertiserMultiselect';
import TagBlocks from '../../common/Tags/TagBlocks';

interface IProp {
  user: IUserEditor;
  userChange: (name: string, value: any) => void;
  errorValues: string[];
}

function UserManagementForm({ user, userChange, errorValues }: IProp) {
  const [adList, setAdList] = useState<null | IAdvertiserItemShort[]>(null);

  useEffect(() => {
    if (user.role === UserRole.AdvertisingManager && adList === null) {
      const getData = async () => {
        try {
          const newList = await AdvertiserService.getAllAdvertisers();
          setAdList(newList);
          if (user.availableAdvertisers) {
            const newAvAd = user.availableAdvertisers.map((ad) => {
              const name = newList.find((adList) => adList.id === ad.id)?.name || '';
              return { ...ad, name };
            });
            userChange('availableAdvertisers', newAvAd);
          }
        } catch {}
      };
      getData();
    }
  }, [user.role, user.availableAdvertisers, adList, userChange]);

  const onFormControlChange = (event: any) => {
    userChange(event.target.name, event.target.value);
  };

  const switcherChange = (event: any) => {
    userChange(event.target.name, !event.target.checked);
  };

  const dropdownChange = (event: any, role: string) => {
    event.preventDefault();
    userChange('role', role);
  };

  const onAdChange = (selectedAds: IAdvertiserItemShort[]) => {
    userChange('availableAdvertisers', selectedAds);
  };

  const onAdDeleteClick = ({ id }: IAdvertiserItemShort) => {
    const index = user.availableAdvertisers.findIndex((selAd) => selAd.id === id);
    user.availableAdvertisers.splice(index, 1);
    userChange('availableAdvertisers', [...user.availableAdvertisers]);
  };

  return (
    <div>
      <Form style={{ maxWidth: '632px' }}>
        <FormInputLimited
          isInvalid={errorValues.includes('name')}
          data-test-id="user-form-name"
          label="Name"
          onChange={onFormControlChange}
          lengthLimit={50}
          value={user.name}
          type="text"
          name="name"
        />
        <Form.Group className="mb-4">
          <Form.Label>Email</Form.Label>
          <Form.Control
            isInvalid={errorValues.includes('email')}
            data-test-id="user-form-email"
            onChange={onFormControlChange}
            name="email"
            type="email"
            value={user.email}
          />
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Access Level</Form.Label>
              <DropdownButton
                data-test-id="user-form-role"
                variant="light"
                title={userRolesStorage[user.role as keyof UserRoleType].name}
                className={styles.dropdownRole}
              >
                {Object.keys(UserRole).map((role) => (
                  <Dropdown.Item as={'button'} key={role} onClick={(e) => dropdownChange(e, role)}>
                    {userRolesStorage[role as keyof UserRoleType].name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
          </Col>
          {user.id && (
            <Col className="d-flex align-items-center">
              <Form.Group className="mt-3">
                <Form.Check type="switch" id="active-switcher">
                  <Form.Check.Label className={user.suspended ? '' : 'fw-bold'}>Active</Form.Check.Label>
                  <Form.Check.Input
                    data-test-id="user-form-active"
                    name="suspended"
                    checked={!user.suspended}
                    onChange={switcherChange}
                  />
                </Form.Check>
              </Form.Group>
            </Col>
          )}
        </Row>
        {user.role === UserRole.AdvertisingManager && (
          <div className="mt-3">
            {adList !== null && (
              <div>
                <AdvertiserMultiselect
                  adList={adList}
                  selected={user.availableAdvertisers}
                  onSelectChange={onAdChange}
                  errored={errorValues.includes('availableAdvertisers')}
                />
                <div data-test-id="user-form-ad-selected">
                  <TagBlocks
                    tags={user.availableAdvertisers}
                    onDeleteClick={onAdDeleteClick}
                    getDisplayValue={(ad: IAdvertiserItemShort) => ad.name}
                    getKeyValue={(ad: IAdvertiserItemShort) => ad.id}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Form>
    </div>
  );
}

export default UserManagementForm;
