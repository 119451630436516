import React, { useEffect, useState } from 'react';
import ObjectsService from '../../../services/Objects/ObjectsService';
import { IObject } from '../../../types/objects';
import ObjectsListSimple from '../../Objects/ObjectsListSimple/ObjectsListSimple';

interface IProps {
  scheduleId?: string | null;
}
function VisibilityScheduleAssignedObjects({ scheduleId }: IProps) {
  const [objects, setObjects] = useState<IObject[]>([]);
  useEffect(() => {
    const loadData = async (scheduleId: string) => {
      return await ObjectsService.getObjects(0, 1000, '', null, scheduleId || null);
    };

    if (scheduleId) {
      loadData(scheduleId).then((res) => {
        if (res) {
          setObjects(res);
        }
      });
    }
  }, [scheduleId]);

  if (!scheduleId) return <div></div>;

  return <ObjectsListSimple objects={objects} />;
}

export default VisibilityScheduleAssignedObjects;
