import constants from '../../constants';
import axios from 'axios';
import { IAdvertiserItem, IAdvertiserItemShort, IAdvertiserListItem } from '../../types/advertisers';

interface IGetAdParams {
  skip: number;
  limit: number;
  SearchText?: string;
}

let controller: AbortController | null;

class AdvertiserService {
  getAllAdvertisers = () => {
    const url = `${constants.API_ADMIN_AD}/GetAdvertisers`;
    return axios.get(url).then((res): IAdvertiserItemShort[] => res.data.map((d: any) => ({ name: d.name, id: d.id })));
  };

  getAdvertisers = (skip?: number, limit?: number, text?: string) => {
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    const url = `${constants.API_ADMIN_AD}/GetAdvertisers`;

    const params: IGetAdParams = { skip: skip || 0, limit: limit || 10000 };
    if (text) {
      params.SearchText = text;
    }

    return axios.get(url, { params, signal: controller.signal }).then((res): IAdvertiserListItem[] => res?.data ?? []);
  };

  getAdvertiser = (id: string) => {
    const url = `${constants.API_ADMIN_AD}/GetAdvertiser?id=${id}`;

    return axios.get(url).then((res): IAdvertiserListItem => res?.data ?? []);
  };

  createAdvertiser = (advertiser: IAdvertiserItem) => {
    const url = `${constants.API_ADMIN_AD}/CreateAdvertiser`;
    const createPayload = {
      name: advertiser.name,
      countryId: advertiser.country?.id || '',
      regionId: advertiser.region?.id,
      placeId: advertiser.place?.id,
      websiteUrl: advertiser.websiteUrl,
      iconUrl: advertiser.iconUrl || '',
      extraImageUrl1: advertiser.extraImageUrl1 || '',
      extraImageUrl2: advertiser.extraImageUrl2 || '',
      extraImageUrl3: advertiser.extraImageUrl3 || '',
      description: advertiser.description || '',
      displayTimeZone: advertiser.displayTimeZone,
      defaultCountries: advertiser.defaultCountries || [],
      isDefault: advertiser.isDefault,
    };

    return axios.post(url, createPayload).then((res) => res.data);
  };

  updateAdvertiser = (advertiser: IAdvertiserItem) => {
    const url = `${constants.API_ADMIN_AD}/UpdateAdvertiser`;
    const updatePayload = {
      id: advertiser.id,
      name: advertiser.name,
      description: advertiser.description || '',
      countryId: advertiser.country?.id || '',
      regionId: advertiser.region?.id,
      placeId: advertiser.place?.id,
      websiteUrl: advertiser.websiteUrl,
      iconUrl: advertiser.iconUrl || '',
      extraImageUrl1: advertiser.extraImageUrl1 || '',
      extraImageUrl2: advertiser.extraImageUrl2 || '',
      extraImageUrl3: advertiser.extraImageUrl3 || '',
      isArchived: advertiser.isArchived,
      displayTimeZone: advertiser.displayTimeZone,
      defaultCountries: advertiser.defaultCountries || [],
      isDefault: advertiser.isDefault,
    };

    return axios.post(url, updatePayload).then((res) => res.data);
  };
}

export default new AdvertiserService();
