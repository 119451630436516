import React from 'react';
import { ICouponEditForm } from '../../../../types/coupons';
import { Col, Container, Form, Row } from 'react-bootstrap';
import styles from '../../advertisers.module.css';
import FormInputLimited from '../../../common/FormInputLimited';
import moment from 'moment';
import ImagePicker from '../../../common/ImagePicker/ImagePicker';
import ButtonEditor from '../../../common/ButtonEditor/ButtonEditor';
import MarkdownSupportComment from '../../../common/MarkdownSupportComment';

interface IProp {
  coupon: ICouponEditForm;
  tz: string;
  couponChange: (name: string, value: any) => void;
  errorValues: string[];
}

function CouponEditorForm({ coupon, couponChange, tz, errorValues }: IProp) {
  const onFormControlChange = (event: any) => {
    couponChange(event.target.name, event.target.value);
  };

  const onDateChanged = (evt: any) => {
    // create new value out of new date and previous time
    const newDate = moment(evt.target.value).tz(tz, true).valueOf();
    couponChange(evt.target.name, newDate);
  };

  const startDate = moment.tz(coupon.startOn, tz).format('yyyy-MM-DD');
  const endDate = moment.tz(coupon.endOn, tz).format('yyyy-MM-DD');

  return (
    <div data-test-id="coupon-editor-form">
      <Form>
        <Container>
          <Row>
            <Col>
              <FormInputLimited
                required={true}
                isInvalid={errorValues.includes('name')}
                data-test-id="coupon-form-name"
                label="Coupon campaign name"
                onChange={onFormControlChange}
                lengthLimit={80}
                value={coupon.name}
                type="text"
                name="name"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="mb-0">Show on</Form.Label>
              <Form.Control value={startDate} onChange={onDateChanged} type="date" name="startOn" />
            </Col>
            <Col>
              <Form.Label className="mb-0">Unavailable on</Form.Label>
              <Form.Control value={endDate} onChange={onDateChanged} name="endOn" type="date" />
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="text-muted">Advertizer time zone</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4 className="mt-3">Teaser</h4>
              <div className="text-muted fst-italic">
                Users will see this information until finish collection or collect trophy
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start">
              <ImagePicker
                caption="Upload teaser image"
                hasError={errorValues.includes('teaserImagePath')}
                imageUrl={coupon.teaserImagePath}
                height={226}
                width={350}
                imageResize={true}
                imageRecommendedHeight={678}
                imageRecommendedWidth={1050}
                supportJpg={true}
                supportPng={false}
                name="teaserImagePath"
                valueChange={couponChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInputLimited
                data-test-id="coupon-form-teaser-title"
                label="Title"
                required={true}
                onChange={onFormControlChange}
                lengthLimit={80}
                value={coupon.teaserTitle}
                isInvalid={errorValues.includes('teaserTitle')}
                name="teaserTitle"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <FormInputLimited
                data-test-id="coupon-form-teaser-description"
                label="Description"
                required={true}
                onChange={onFormControlChange}
                lengthLimit={2000}
                value={coupon.teaserDescription}
                name="teaserDescription"
                as="textarea"
                rows={4}
                isInvalid={errorValues.includes('teaserDescription')}
                comment={MarkdownSupportComment()}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonEditor
                link={coupon.teaserButton}
                layout="horizontal"
                testDataIdPrefix="campaign-teaser"
                isInvalid={errorValues.includes('teaserButton')}
                valueChange={(v) => couponChange('teaserButton', v)}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <h4 className={styles.sectionTitle}>Coupon</h4>
              <div className="text-muted fst-italic">
                Users will see this information after finish collection or collect trophy
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start">
              <ImagePicker
                caption="Upload coupon image"
                hasError={errorValues.includes('couponImagePath')}
                imageUrl={coupon.couponImagePath}
                height={226}
                width={350}
                imageResize={true}
                imageRecommendedHeight={678}
                imageRecommendedWidth={1050}
                supportJpg={true}
                supportPng={false}
                name="couponImagePath"
                valueChange={couponChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInputLimited
                data-test-id="coupon-form-coupon-title"
                label="Title"
                required={true}
                onChange={onFormControlChange}
                lengthLimit={80}
                value={coupon.couponTitle}
                isInvalid={errorValues.includes('couponTitle')}
                name="couponTitle"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <FormInputLimited
                data-test-id="coupon-form-coupon-description"
                label="Description"
                required={true}
                onChange={onFormControlChange}
                lengthLimit={2000}
                value={coupon.couponDescription}
                name="couponDescription"
                as="textarea"
                isInvalid={errorValues.includes('couponDescription')}
                comment={MarkdownSupportComment()}
                rows={4}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonEditor
                link={coupon.couponButton}
                layout="horizontal"
                testDataIdPrefix="campaign-coupon"
                isInvalid={errorValues.includes('couponButton')}
                valueChange={(v) => couponChange('couponButton', v)}
              />
              <div>
                <span className="text-muted">
                  Optional: add [jwt] into the URL. Example: https://example.com/?token=[jwt]
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default CouponEditorForm;
