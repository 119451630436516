import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import styles from './Tags.module.css';
import TagBlocks from './TagBlocks';
import ObjectAdminService from '../../../services/Objects/ObjectAdminService';
import { debounce } from 'lodash';
import Loading from '../Loading';
import commonStyles from '../../../styles/common.module.css';

interface IProp {
  tags: string[];
  onDeleteTag: Function;
  onAddTag: Function;
}
function ObjectCollectionTags({ tags, onDeleteTag, onAddTag }: IProp) {
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchString) {
      setLoading(true);
      ObjectAdminService.getTags(searchString)
        .then((res) => {
          setSuggestedTags(res);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [searchString]);

  const debouncedSearch = debounce((nextValue: string) => {
    setSearchString(nextValue.trim());
  }, 800);

  const handleChange = (e: any) => {
    debouncedSearch(e.target.value);
  };

  const onAddClick = () => {
    onAddTag(searchString);
  };

  const tagClick = (e: any, tag: string) => {
    e.preventDefault();
    const index = tags.indexOf(tag);
    index !== -1 ? onDeleteTag(tag, index) : onAddTag(tag);
  };

  const isChecked = (tag: string) => tags.includes(tag);

  const renderItem = (tag: string) => (
    <Form.Check id={`tag-${tag}`} className="mb-2 mt-2 pe-none">
      <Form.Check.Input checked={isChecked(tag)} readOnly />
      <Form.Check.Label>{tag}</Form.Check.Label>
    </Form.Check>
  );

  return (
    <div className="mb-3">
      <Form.Group className="mb-3">
        <Form.Label>Tags</Form.Label>
        <TagBlocks tags={tags} onDeleteClick={onDeleteTag} />
        <DropdownButton
          autoClose="outside"
          data-test-id="editor-tags"
          variant="light"
          title="Add tag"
          className={styles.dropdownTags}
        >
          <Dropdown.Header className="w-100" style={{ height: 54 }}>
            <InputGroup>
              <Form.Control
                maxLength={40}
                data-test-id="add-tag-input"
                placeholder="Tag"
                defaultValue={searchString}
                onChange={handleChange}
              />
              <Button
                data-test-id="add-tag-button"
                variant="secondary"
                disabled={!searchString.length}
                onClick={onAddClick}
              >
                Add
              </Button>
            </InputGroup>
          </Dropdown.Header>
          {searchString ? (
            loading ? (
              <Dropdown.ItemText>
                <Loading />
              </Dropdown.ItemText>
            ) : suggestedTags.length ? (
              <div style={{ height: 236 }} className="overflow-auto">
                {suggestedTags.map((tag) => (
                  <Dropdown.Item
                    className={commonStyles.pointer}
                    as={'div'}
                    key={tag}
                    onClick={(e) => tagClick(e, tag)}
                  >
                    {renderItem(tag)}
                  </Dropdown.Item>
                ))}
              </div>
            ) : (
              <Dropdown.ItemText className="text-muted small">No suggestions found...</Dropdown.ItemText>
            )
          ) : (
            <Dropdown.ItemText className="text-muted small">Start typing to see suggestions...</Dropdown.ItemText>
          )}
        </DropdownButton>
      </Form.Group>
    </div>
  );
}

export default ObjectCollectionTags;
