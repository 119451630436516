import constants from '../constants';
import axios from 'axios';
import { IDictionaryName } from '../types/common';

class GeonamesService {
  getAllCountries = (): Promise<IDictionaryName[]> => {
    const url = `${constants.API_ADMIN_GEONAMES}/GetAllCountries`;
    return axios.get(url).then((res) => res.data);
  };

  getRegionsByCountry = (countryId: string): Promise<IDictionaryName[]> => {
    const url = `${constants.API_ADMIN_GEONAMES}/GetRegionsByCountry?countryId=${countryId}`;
    return axios.get(url).then((res) => res.data);
  };

  getPlaceByRegion = (regionId: string): Promise<IDictionaryName[]> => {
    const url = `${constants.API_ADMIN_GEONAMES}/GetPlaceByRegion?regionId=${regionId}`;
    return axios.get(url).then((res) => res.data);
  };

  getNearestPlace = async (lat: number, lon: number) => {
    return await this.getNearestPlaces(lat, lon).then((res) => ({
      countryId: res[0] ? res[0].countryId : null,
      countryName: res[0] ? res[0].countryName : null,
      regionId: res[0] ? res[0].regionId : null,
      regionName: res[0] ? res[0].regionName : null,
      placeId: res[0] ? res[0].id : null,
      placeName: res[0] ? res[0].name : null,
    }));
  };

  getNearestPlaces = (lat: number, lon: number, limit: number = 1) => {
    const url = `${constants.API_ADMIN_GEONAMES}/GetNearestPlaces?lat=${lat}&lon=${lon}&limit=${limit}`;
    return axios.get(url).then((res) => res.data);
  };
}

export default new GeonamesService();
