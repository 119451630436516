import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ICouponCampaignDisplay } from '../../../../types/coupons';
import styles from '../../../common/ObjectCollection/ObjectCollection.module.css';
import { ILink } from '../../../../types/common';

interface IProp {
  coupon: ICouponCampaignDisplay | null;
}
function CouponDisplayFormInfo({ coupon }: IProp) {
  const renderMobileScreen = (imageUrl: string | null, title: string, description: string, button: ILink | null) => {
    return (
      <div style={{ maxWidth: '375px', margin: 'auto' }}>
        {' '}
        {/* to simulate the width of a mobile screen */}
        <Card>
          {imageUrl && <Card.Img variant="top" src={imageUrl} />}
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
            {button && (
              <Button variant="primary" href={button.url}>
                {button.title}
              </Button>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  };

  if (coupon === null) {
    return <div>Coupon not found</div>;
  }

  return (
    <div>
      <div className={styles.textBlock}>
        <h6>Coupon campaign name</h6>
        <div data-test-id="coupon-info-name">{coupon.name}</div>
      </div>

      <div className={styles.textBlock}>
        <h6>Status</h6>
        <div data-test-id="coupon-info-name">{coupon.status}</div>
      </div>

      <Row>
        <Col>
          <div className={styles.textBlock}>
            <h6>Show on</h6>
            <div data-test-id="coupon-info-startOn">{coupon.startDisplayOn}</div>
          </div>
        </Col>
        <Col>
          <div className={styles.textBlock}>
            <h6>Unavailable on</h6>
            <div data-test-id="coupon-info-endOn">{coupon.endDisplayOn}</div>
          </div>
        </Col>
      </Row>

      <Container>
        <Row>
          <Col xs={12} md={6}>
            <h4>Teaser</h4>
            {renderMobileScreen(
              coupon.teaserImagePath,
              coupon.teaserTitle,
              coupon.teaserDescription,
              coupon.teaserButton,
            )}
          </Col>
          <Col xs={12} md={6}>
            <h4>Coupon</h4>
            {renderMobileScreen(
              coupon.couponImagePath,
              coupon.couponTitle,
              coupon.couponDescription,
              coupon.couponButton,
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CouponDisplayFormInfo;
