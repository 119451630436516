import React, { useEffect, useState } from 'react';
import styles from 'styles/details.module.css';
import { IStatus } from 'types/common';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { IAdvertiserItem, initAdItem } from 'types/advertisers';
import AdvertiserService from 'services/Advertisers/AdvertiserService';
import AdvertiserEditorForm from './AdvertiserEditorForm';
import LoadingWrapper from 'components/common/LoadingWrapper';
import { AlertType } from 'types/alerts';
import AlertsService from 'store/services/AlertsService';
import ModalInfoService from 'store/services/ModalInfoService';
import { useExitConfirmation } from 'hooks/useExitConfirmation';

function AdvertiserEditor() {
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [ad, setAd] = useState<IAdvertiserItem>(initAdItem);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  let { adId } = useParams<{ adId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const loadAdData = async (id: string) => {
      try {
        return await AdvertiserService.getAdvertiser(id);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    if (adId) {
      loadAdData(adId).then((res) => {
        if (res) {
          setAd(res);
          setStatus(IStatus.Success);
        }
      });
    } else {
      setStatus(IStatus.Success);
    }
  }, [adId]);

  const handleAdChange = (name: string, value: any) => {
    setHasChanges(true);
    errorValues.includes(name) && setErrorValues((prevState) => prevState.filter((er) => er !== name));
    setAd((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSaveClick = () => {
    setErrorValues([]);
    let possible = true;
    if (ad.name.length === 0) {
      AlertsService.addAlert('Title field is empty', AlertType.WARNING);
      setErrorValues((prevState) => [...prevState, 'name']);
      possible = false;
    }
    if (!ad.country) {
      AlertsService.addAlert('Country is not set', AlertType.WARNING);
      setErrorValues((prevState) => [...prevState, 'country']);
      possible = false;
    }
    if (!possible) return;
    ModalInfoService.showModalInfoLoading();
    if (adId) {
      AdvertiserService.updateAdvertiser(ad).then(() => {
        ModalInfoService.closeModalInfo();
        handleBackToAdInfo(ad.id);
      });
    } else {
      AdvertiserService.createAdvertiser(ad).then((res) => {
        if (res) {
          ModalInfoService.closeModalInfo();
          handleBackToAdInfo(res.id);
        }
      });
    }
  };

  const handleBackToList = () => {
    navigate(`/advertisers`);
  };

  const handleBackToAdInfo = (id: string) => {
    navigate(`/advertisers/${id}/coupons`);
  };

  const onCancelClick = useExitConfirmation({
    shouldConfirm: hasChanges,
    onExit: ad.id ? () => handleBackToAdInfo(ad.id) : handleBackToList,
  });

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-start ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <div className={styles.w50EditorContainer}>
            <AdvertiserEditorForm ad={ad} adChange={handleAdChange} errorValues={errorValues} />
          </div>
        </LoadingWrapper>
      </div>
      <div className={`${styles.buttonContainer} d-flex justify-content-between`}>
        <Button variant="light" size="sm" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={onSaveClick}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default AdvertiserEditor;
