import { UserRole } from './userManagement';

export interface IJwt {
  nbf: number;
  exp: number;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': UserRole;
}
export interface IAuth {
  isAuth: boolean | null;
  role: UserRole | null;
  email: string;
}

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SET_EMAIL = 'SET_EMAIL',
}

interface IAuthEmailAction {
  type: AuthActionTypes.SET_EMAIL;
  payload: { email: string };
}
interface IAuthLoginAction {
  type: AuthActionTypes.LOGIN;
  payload: { role: UserRole };
}

interface IAuthLogoutAction {
  type: AuthActionTypes.LOGOUT;
}
export type AuthActions = IAuthLoginAction | IAuthLogoutAction | IAuthEmailAction;
