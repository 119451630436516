import { AlertActionTypes, AlertType } from '../../types/alerts';
import { store } from '../index';

class AlertsService {
  addAlert = (text: string, type?: AlertType, delay?: number) => {
    store.dispatch({
      type: AlertActionTypes.ADD_ALERT,
      payload: { text, type, delay },
    });
  };

  deleteAlert = (id: string) => {
    store.dispatch({ type: AlertActionTypes.DELETE_ALERT, payload: id });
  };
}

export default new AlertsService();
