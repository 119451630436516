import * as uuid from 'uuid';
import { AlertActionTypes, IAlertAction, IAlerts } from '../../types/alerts';

const initialState: IAlerts = {
  alerts: [],
};

export const alertsReducer = (state = initialState, action: IAlertAction): IAlerts => {
  switch (action.type) {
    case AlertActionTypes.ADD_ALERT: {
      state.alerts.push({
        text: action.payload.text,
        type: action.payload.type,
        delay: action.payload.delay,
        id: uuid.v4(),
      });
      return { ...state, alerts: [...state.alerts] };
    }
    case AlertActionTypes.DELETE_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter((t) => t.id !== action.payload),
      };
    }
    default:
      return state;
  }
};
