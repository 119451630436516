import constants from '../constants';
import axios from 'axios';
import { IConversationTopic } from '../types/conversations';

class ConversationsService {
  getConversationTopic = (id: string): Promise<IConversationTopic | null> => {
    const url = `${constants.API_ADMIN_CONVERSATIONS_ENDPOINT}/GetConversation?topicId=${id}`;

    return axios.get(url).then((res) => res.data);
  };

  deleteComment = async (id: string) => {
    const url = `${constants.API_ADMIN_CONVERSATIONS_ENDPOINT}/DeteleComment?commentId=${id}`;

    try {
      return await axios.delete(url).then();
    } catch {
      return false;
    }
  };
}

export default new ConversationsService();
