import React from 'react';
import { Form } from 'react-bootstrap';
import MapObjectLookup from '../../common/MapObjectLookup';

interface CollectionConditionsProps {
  objectId: string;
  maxCollections?: number | null;
  question?: string | null;
  answer?: string | null;
  requiredObjectId?: string | null;
  onChange: (data: {
    maxCollections?: number | null;
    question?: string | null;
    answer?: string | null;
    requiredObjectId?: string | null;
  }) => void;
}

const CollectionConditionsEditor: React.FC<CollectionConditionsProps> = ({
  objectId,
  maxCollections,
  question,
  answer,
  requiredObjectId,
  onChange,
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    var dataToSend: any = { maxCollections, question, answer, requiredObjectId };

    var key = e.target.name;
    var value: any = e.target.value;

    if (key === 'maxCollections') {
      value = parseInt(value);
    }

    dataToSend[key] = value;
    onChange(dataToSend);
  };

  return (
    <>
      <Form.Group controlId="maxCollections" className="mb-3">
        <Form.Label>Max trophy collections</Form.Label>
        <Form.Control type="number" name="maxCollections" value={maxCollections || ''} onChange={handleOnChange} />

        <Form.Text className="text-muted">
          Maximum number of times an object can be collected. When the limit is reached, the object will be
          automatically archived.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="question">
        <Form.Label>Question</Form.Label>
        <Form.Control type="text" name="question" maxLength={200} value={question || ''} onChange={handleOnChange} />

        <Form.Text className="text-muted">
          This question will be asked when a user tries to collect an object. If it is empty, but an answer is provided,
          a default question will be asked. Only default question will be localized.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="answer" className="mb-4">
        <Form.Label>Answer</Form.Label>
        <Form.Control type="text" name="answer" maxLength={50} value={answer || ''} onChange={handleOnChange} />
        <Form.Text className="text-muted">
          An answer a user should give to collect an object. If empty, the object will be collected without a question
          as usual.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="requiredObjectId" className="mb-3">
        <Form.Label>
          Conditional trophy
          <small className="text-muted ps-3">
            Search for an object by name or paste URL (copy it from a browser address bar).
          </small>
        </Form.Label>
        {/* Using filter to prevent adding same object as a conditional trophy */}
        <MapObjectLookup
          name="requiredObjectId"
          value={requiredObjectId || ''}
          onChange={handleOnChange}
          filter={(option) => (option as any).id !== objectId}
        />
        <Form.Text className="text-muted">
          If value is set, the object can be collected only if the required object is collected first.
        </Form.Text>
      </Form.Group>
    </>
  );
};

export default CollectionConditionsEditor;
