import React from 'react';
import styles from './Tags.module.css';
import commonStyles from '../../../styles/common.module.css';
import { BiX } from 'react-icons/bi';

interface IProp {
  tags: any[];
  getDisplayValue?: Function;
  getKeyValue?: Function;
  onDeleteClick: Function;
}

function TagBlocks({ tags, getDisplayValue, getKeyValue, onDeleteClick }: IProp) {
  return (
    <div data-test-id="tag-blocks">
      {tags.map((tag, index) => {
        const value = getDisplayValue ? getDisplayValue(tag) : tag;
        const key = getKeyValue ? getKeyValue(tag) : tag + index;
        return (
          <div key={key} className={styles.blockItem}>
            {value}
            <BiX
              onClick={() => {
                onDeleteClick(tag, index);
              }}
              className={commonStyles.pointer}
            />
          </div>
        );
      })}
    </div>
  );
}

export default TagBlocks;
