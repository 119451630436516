import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useSearchParams } from 'react-router-dom';
import styles from './Search.module.css';
import SearchInfo from './SearchInfo';
import { BiX } from 'react-icons/bi';

interface IProps {
  searchInfo?: JSX.Element;
}
function Search({ searchInfo }: IProps) {
  const search = useTypedSelector((state) => state.navbar.searchString);
  const [defaultSearch] = useState<string>(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchInput = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleSearchChange = (nextValue: string) => {
    searchParams.set('search', nextValue);
    setSearchParams(searchParams);
    dispatch({ type: 'SET_SEARCH', payload: nextValue });
  };

  const debouncedSearch = debounce(handleSearchChange, 800);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch, dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => debouncedSearch(event.target.value);

  const onResetClick = () => {
    if (searchInput.current) {
      searchInput.current.value = '';
    }
    handleSearchChange('');
  };

  return (
    <div className={`${styles.searchContainer} d-flex  align-items-center`}>
      <div className={styles.searchInputGroup}>
        <Form.Control
          size="sm"
          ref={searchInput}
          data-test-id="nav-search"
          placeholder="Search"
          defaultValue={defaultSearch}
          onChange={handleChange}
        />
        {search.length > 0 && (
          <div className={styles.searchClear} onClick={onResetClick}>
            <BiX />
          </div>
        )}
      </div>
      {searchInfo && <SearchInfo popoverBody={searchInfo} />}
    </div>
  );
}

export default Search;
