import axios from 'axios';
import ModalInfoService from 'store/services/ModalInfoService';
import { ILanguageCode } from '../types/common';
import constants from '../constants';

class TranslationService {
  translateText = async (content: string, sourceLang: ILanguageCode, targetLang: ILanguageCode): Promise<string> => {
    try {
      const url = `${constants.API_ADMIN_TRANSLATION}/Translate`;
      var translateData = {
        content,
        sourceLang,
        targetLang,
      };

      const text = await axios.post(url, translateData).then((res) => res.data);

      if (!text) {
        ModalInfoService.showModalInfoError(`We have problems translating text from ${sourceLang} to ${targetLang}`);
        return '';
      }

      return text;
    } catch (error) {
      ModalInfoService.showModalInfoError(
        `We have problems translating text from ${sourceLang} to ${targetLang}: ${error.message}`,
      );
      return '';
    }
  };
}

export default new TranslationService();
