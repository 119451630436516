import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter';

import * as THREE from 'three';

const dracoDecoderPath = 'https://www.gstatic.com/draco/versioned/decoders/1.5.6/';

export interface IValidationResult {
  errors: string[];
  isAnimated: boolean;
  totalPolygons: number;
}

class Model3DLib {
  handleLoadModelFile = async (file: File, isAnimated = false): Promise<string | undefined> => {
    if (isAnimated) {
      return URL.createObjectURL(file);
    }
    const gltf = await this.modelFileToGltf(file);
    if (gltf) {
      const newScene = this.doNormalization(gltf.scene, 1);
      return this.sceneToObjectURL(newScene);
    }
    return;
  };

  handleLoadIOSModelFile = async (file: File): Promise<string | undefined> => {
    const gltf = await this.modelFileToGltf(file);
    if (gltf) {
      const newScene = this.doNormalization(gltf.scene, 1);
      return this.prepareUSDZ(newScene);
    }
    return;
  };

  validateModel = async (file: File): Promise<IValidationResult> => {
    let totalPolygons = 0;
    let errors = [];
    let isAnimated = false;
    try {
      const model = await this.modelFileToGltf(file);

      model.scene.traverse((object) => {
        if (object instanceof THREE.Mesh) {
          const geometry = object.geometry;
          if (geometry.index) {
            totalPolygons += geometry.index.count / 3; // Indexed geometry
          } else {
            totalPolygons += geometry.attributes.position.count / 3; // Non-indexed geometry
          }
        }
      });

      if (totalPolygons >= 300000) {
        errors.push(`Total number of model's polygons must be less than 300 000. Total Polygons: ${totalPolygons}.`);
      }

      isAnimated = model.animations.length > 0;
    } catch (e) {
      if (e.message?.indexOf("setting 'isBone'") !== -1) {
        errors.push(`Unable to process 3D model: invalid model.`);
      } else {
        errors.push(`Unable to process 3D model: ${e.message}.`);
      }
    }

    return {
      errors,
      isAnimated,
      totalPolygons,
    };
  };

  prepareUSDZ = async (model: THREE.Object3D): Promise<string> => {
    if (model == null) {
      return '';
    }

    const exporter = new USDZExporter();
    model.updateWorldMatrix(false, true);
    const arraybuffer = await exporter.parseAsync(model);

    const blob = new Blob([arraybuffer], {
      type: 'model/vnd.usdz+zip',
    });

    const url = URL.createObjectURL(blob);

    return url;
  };

  modelFileToGltf = async (file: File): Promise<GLTF | undefined> => {
    return new Promise((res, rej) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(dracoDecoderPath);

      const loader = new GLTFLoader();
      loader.setDRACOLoader(dracoLoader);

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (reader.result) {
          loader.load(
            reader.result.toString(),
            (gltf) => {
              res(gltf);
            },
            undefined,
            rej,
          );
        }
      };
    });
  };

  searchForNormalizationLayer = (threeObject: THREE.Object3D): THREE.Object3D | false => {
    if (threeObject.children?.[0]?.name === 'CS-NormalizationLayer') {
      return threeObject;
    }
    if (threeObject.children && threeObject.children[0]) {
      return this.searchForNormalizationLayer(threeObject.children[0]);
    }
    return false;
  };

  doNormalization = (sc: THREE.Object3D, finalSize: number): THREE.Object3D => {
    let scene = sc.clone(true);
    const wasNormalizedBefore = this.searchForNormalizationLayer(scene);

    if (wasNormalizedBefore) {
      scene = wasNormalizedBefore;
    } else {
      const allChildren = [...scene.children];
      scene.children = [new THREE.Object3D()];
      scene.children[0].name = 'CS-NormalizationLayer';
      scene.children[0].children = allChildren;

      const bbox = new THREE.Box3().setFromObject(scene);
      const size = bbox.getSize(new THREE.Vector3());
      const scaleValue = finalSize / Math.max(size.x, size.y, size.z);
      scene.children[0].scale.multiplyScalar(scaleValue);

      const cent = bbox.getCenter(new THREE.Vector3());
      scene.children[0].position.copy(cent).multiplyScalar(-scaleValue);
    }
    return scene;
  };

  sceneToObjectURL = async (scene: THREE.Object3D): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      const exporter = new GLTFExporter();
      const options = {
        trs: true,
        binary: true,
        forceIndices: true,
      };
      exporter.parse(
        scene,
        (gltf) => {
          resolve(gltf instanceof ArrayBuffer ? this.arrayBufferToUrl(gltf) : this.jsonToUrl(gltf));
        },
        reject,
        options,
      );
    });
  };

  arrayBufferToUrl(buffer: ArrayBuffer): string {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    return URL.createObjectURL(blob);
  }

  jsonToUrl(json: any): string {
    const blob = new Blob([JSON.stringify(json)], { type: 'text/plain' });
    return URL.createObjectURL(blob);
  }

  applyRotationToFileUrl = async (url: string, rotation: number): Promise<string | undefined> => {
    return new Promise((resolve, reject) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(dracoDecoderPath);

      const loader = new GLTFLoader();
      loader.setDRACOLoader(dracoLoader);

      loader.load(
        url,
        (gltf) => {
          const newScene = this.setRotationToScene(gltf.scene, rotation);
          resolve(this.sceneToObjectURL(newScene));
        },
        undefined,
        reject,
      );
    });
  };

  setRotationToScene = (sc: THREE.Object3D, rotation: number): THREE.Object3D => {
    let scene = sc.clone(true);
    const wasNormalizedBefore = this.searchForNormalizationLayer(scene);
    if (wasNormalizedBefore) {
      scene = wasNormalizedBefore;
    } else {
      const allChildren = [...scene.children];
      scene.children = [new THREE.Object3D()];
      scene.children[0].name = 'CS-NormalizationLayer';
      scene.children[0].children = allChildren;
    }
    scene.rotation.y += this.deg2rad(rotation);
    return scene;
  };

  deg2rad = (degrees: number) => degrees * (Math.PI / 180);
}

export default new Model3DLib();
