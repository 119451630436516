import { Form } from 'react-bootstrap';
import { IAdUserForm } from '../../../../../types/advertisers';
import FormInputLimited from '../../../../common/FormInputLimited';

interface IProp {
  user: IAdUserForm;
  userChange: (name: string, value: any) => void;
  errorValues: string[];
}

function AdUserEditorForm({ user, userChange, errorValues }: IProp) {
  const onFormControlChange = (event: any) => {
    userChange(event.target.name, event.target.value);
  };

  return (
    <div>
      <Form style={{ maxWidth: '632px' }}>
        <FormInputLimited
          isInvalid={errorValues.includes('name')}
          data-test-id="user-form-name"
          label="Name"
          onChange={onFormControlChange}
          lengthLimit={50}
          value={user.name}
          type="text"
          name="name"
        />
        <Form.Group className="mb-4">
          <Form.Label>Email</Form.Label>
          <Form.Control
            isInvalid={errorValues.includes('email')}
            data-test-id="user-form-email"
            onChange={onFormControlChange}
            name="email"
            type="email"
            value={user.email}
          />
        </Form.Group>
      </Form>
    </div>
  );
}

export default AdUserEditorForm;
