import { IObjCollectionsAction, IObjectCollections, ObjColActionTypes } from '../../types/objectRedux';

const initState: IObjectCollections = {
  collections: [],
  id: '',
  wasChanged: false,
};

export const objectReducer = (
  state: IObjectCollections = initState,
  action: IObjCollectionsAction,
): IObjectCollections => {
  switch (action.type) {
    case ObjColActionTypes.SET_OBJ_COLLECTIONS: {
      return {
        ...state,
        collections: action.payload.collections,
        wasChanged: action.payload.wasChanged,
      };
    }
    case ObjColActionTypes.SET_OBJ_ID: {
      return { ...state, id: action.payload };
    }
    case ObjColActionTypes.CLEAR_OBJ_STORE: {
      return { ...initState };
    }
    case ObjColActionTypes.CLEAR_OBJ_DATA: {
      return { ...initState, id: state.id };
    }
    default:
      return state;
  }
};
