import { IComponentCellProps, ILanguageCode, ITableColumn } from '../../../types/common';
import React from 'react';
import { ICollectionState } from '../../../types/collections';
import styles from '../../../styles/common.module.css';
import CSImage from '../CSImage';

function Columns(extraWidth: number = 0): ITableColumn[] {
  // TODO: use localization for admin content
  const defaultLanguage = ILanguageCode.English;

  return [
    {
      id: 'iconUrl',
      displayName: '',
      width: '24px',
      component: ImageCell,
    },
    {
      id: 'localizedName',
      displayName: 'Title',
      width: `calc(100vw - 1050px - ${extraWidth}px)`,
      minWidth: '150px',
      pattern: (localizedName) => localizedName[defaultLanguage] ?? '',
    },
    {
      id: 'country',
      displayName: 'Country',
      width: '150px',
      pattern: (v) => (v ? v.displayName : ''),
    },
    {
      id: 'region',
      displayName: 'Region',
      width: '150px',
      pattern: (v) => (v ? v.displayName : ''),
    },
    {
      id: 'place',
      displayName: 'Place',
      width: '150px',
      pattern: (v) => (v ? v.displayName : ''),
    },
    {
      id: 'tags',
      displayName: 'Tags',
      width: '150px',
      pattern: (v) => (v ? v.join(', ') : ''),
    },
    {
      id: 'objectsCount',
      displayName: 'Number of objects',
      width: '150px',
      pattern: (v) => v || 0,
    },
    {
      id: 'status',
      displayName: 'Status',
      width: '100px',
      component: StatusCell,
    },
  ];
}

function ImageCell(props: IComponentCellProps) {
  return <CSImage alt="object" width="24px" src={props.value} />;
}

function StatusCell(props: IComponentCellProps) {
  let color = '#E9ECEF';
  let textColor = '#212529';
  if (!props.value) {
    return <span></span>;
  }
  switch (props.value) {
    case ICollectionState.Published: {
      color = '#28A745';
      textColor = '#FFFFFF';
      break;
    }
    case ICollectionState.Draft: {
      color = '#6B7AFF';
      textColor = '#FFFFFF';
      break;
    }
    default:
      break;
  }
  return (
    <div className={styles.tag} style={{ backgroundColor: color, color: textColor }}>
      {props.value}
    </div>
  );
}

export default Columns;
