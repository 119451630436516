import React, { useEffect, useRef } from 'react';
import Map, { Marker, MapRef } from 'react-map-gl';
import commonStyles from '../../styles/common.module.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import constants from '../../constants';

const MAPBOX_TOKEN = constants.MAPBOX_TOKEN;

interface IProps {
  id: string;
  lon: number;
  lat: number;
  name?: string;
  zoom?: number;
}

function MapWithMarker({ lon, lat, zoom = 3 }: IProps) {
  const mapRef = useRef<MapRef | null>(null);

  useEffect(() => {
    mapRef.current?.flyTo({ center: [lon, lat], zoom: zoom });
  }, [lon, lat, zoom]);

  return (
    <Map
      ref={mapRef}
      initialViewState={{
        latitude: lat,
        longitude: lon,
        zoom: zoom,
      }}
      style={{ width: '100%', height: '100%' }}
      mapStyle="mapbox://styles/mapbox/light-v10?optimize=true"
      mapboxAccessToken={MAPBOX_TOKEN}
    >
      {!(lon === 0 && lat === 0) && (
        <Marker longitude={lon} latitude={lat}>
          <div className={commonStyles.marker} />
        </Marker>
      )}
    </Map>
  );
}

export default MapWithMarker;
