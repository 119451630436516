import React, { useEffect, useState } from 'react';
import { AsyncTypeahead, TypeaheadRef } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { IObject } from '../../types/objects';
import ObjectsService from '../../services/Objects/ObjectsService';
import ObjectAdminService from '../../services/Objects/ObjectAdminService';

interface IProp {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filter?: (option: Option) => boolean;
  typeaheadRef?: React.RefObject<TypeaheadRef>;
}

const MapObjectLookup: React.FC<IProp> = ({ name, value, onChange, filter, typeaheadRef }) => {
  const defaultFilter = () => true;

  const [isLoading, setIsLoading] = useState(true);
  const [selectedObject, setSelectedObject] = useState<IObject>();
  const [options, setOptions] = useState<Option[]>([]);

  const setObjectById = (objectId: string) => {
    ObjectAdminService.getObjectAdmin(objectId).then((res) => {
      if (res) {
        setOptions([res]);

        // This is required to pre-populate lookup control
        setSelectedObject(res);
      } else {
        setSelectedObject(undefined);
      }

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (value) {
      setObjectById(value);
    } else {
      setSelectedObject(undefined);
      setIsLoading(false);
    }
  }, [value]);

  const extractObjectId = (input: string | null) => {
    if (!input) {
      return null;
    }

    // Extract the object ID from the URL
    const match = input.match(/objects\/(obj-\w+)/);

    if (match && match[1]) {
      return match[1];
    }

    // Return null if no object ID is found
    return null;
  };

  const handleOnChange = (selected: Option[]) => {
    const event = {
      target: {
        name: name,
        value: selected.length ? (selected[0] as IObject)?.id : null,
      },
    };

    setSelectedObject(selected.length ? (selected[0] as IObject) : undefined);

    // Small hack to make it compatible with existing code. It's good to refactor it in the future.
    onChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  const handleSearch = (query: string) => {
    // If current input is a full object url, load the object by id
    const objectId = extractObjectId(query);
    if (objectId) {
      setObjectById(objectId);
    } else {
      setIsLoading(true);

      ObjectsService.getObjects(0, 30, query).then((items) => {
        setOptions(items);
        setIsLoading(false);
      });
    }
  };

  return (
    <AsyncTypeahead
      clearButton
      ref={typeaheadRef}
      style={{ width: '100%' }}
      selected={selectedObject ? [selectedObject] : []}
      filterBy={filter ?? defaultFilter}
      id={name}
      isLoading={isLoading}
      labelKey="internalName"
      minLength={1}
      onSearch={handleSearch}
      onChange={handleOnChange}
      options={options}
      placeholder="Search for an object..."
      renderMenuItemChildren={(option) => (
        <>
          <img
            alt={(option as IObject).internalName}
            src={(option as IObject).iconUrl}
            style={{
              height: '24px',
              marginRight: '10px',
              width: '24px',
            }}
          />
          <span>{(option as IObject).internalName}</span>
        </>
      )}
    />
  );
};

export type LookupRef = TypeaheadRef;
export default MapObjectLookup;
