import GeoDropdowns from './GeoDropdowns';
import { Container, Form, Stack } from 'react-bootstrap';
import { IObjectAdmin, CollectionDistance, getCollectionDistanceLabel } from '../../../types/objects';
import Images from '../../common/ObjectCollection/Images';
import { IFileWithUrl } from '../../../types/common';
import FormInputLimited from '../../common/FormInputLimited';
import ObjectCollectionTags from '../../common/Tags/ObjectCollectionTags';
import styles from './AdminEditorForm.module.css';

interface IProps {
  objectChange: (values: { name: string; value: any }[]) => void;
  object: IObjectAdmin;
}

function AdminEditorForm({ object, objectChange }: IProps) {
  const onFormControlChange = (event: any) => {
    objectChange([{ name: event.target.name, value: event.target.value }]);
  };

  const handleDeleteTag = (tag: string, index: number) => {
    object.tags.splice(index, 1);
    objectChange([{ name: 'tags', value: [...object.tags] }]);
  };

  const handleAddTag = (tag: string) => {
    if (!object.tags.includes(tag)) {
      objectChange([{ name: 'tags', value: [...object.tags, tag] }]);
    }
  };

  const handleAddImage = (fileUrls: IFileWithUrl[]) => {
    objectChange([{ name: 'adminPictures', value: object.adminPictures.concat(fileUrls) }]);
  };

  const handleDeleteImage = (index: number) => {
    object.adminPictures.splice(index, 1);
    objectChange([{ name: 'adminPictures', value: object.adminPictures }]);
  };

  const handleGeoChange = (values: { name: string; value: any }[]) => {
    objectChange(values);
  };

  const onAnouncementChange = (event: any) => {
    objectChange([{ name: event.target.name, value: event.target.value === 'true' }]);
  };

  const onCollectionDistanceChange = (event: any) => {
    objectChange([{ name: event.target.name, value: event.target.value }]);
  };

  return (
    <div className="mb-3">
      <Form>
        <h2>Editing '{object.internalName}'</h2>
        <Container fluid={true}>
          <div className={styles.formElementContainer}>
            <Form.Label>Geography</Form.Label>
            <GeoDropdowns
              country={object.country}
              region={object.region}
              place={object.place}
              geoChange={handleGeoChange}
            />
          </div>

          <div className={styles.formElementContainer}>
            <Form.Group className="mb-3">
              <Form.Label>Collection distance</Form.Label>
              <Form.Select
                name="collectionDistance"
                size="sm"
                className="w-50"
                value={object.collectionDistance || CollectionDistance.Default}
                onChange={onCollectionDistanceChange}
              >
                {Object.values(CollectionDistance).map((distance) => (
                  <option key={distance} value={distance}>
                    {getCollectionDistanceLabel(distance)}
                  </option>
                ))}
              </Form.Select>
              <Form.Text className="text-muted">
                Actual distance in meters is configured via Firebase remote config.
              </Form.Text>
            </Form.Group>
          </div>

          <div className={styles.formElementContainer}>
            <ObjectCollectionTags tags={object.tags} onDeleteTag={handleDeleteTag} onAddTag={handleAddTag} />
          </div>

          <div className={`${styles.formElementContainer} w-50`}>
            <FormInputLimited
              data-test-id="obj-editor-notes"
              label="Notes"
              onChange={onFormControlChange}
              lengthLimit={2000}
              value={object.notes}
              name="notes"
              as="textarea"
              rows={6}
            />
          </div>

          <div className={styles.formElementContainer}>
            <Images
              id="adminPictures"
              images={object.adminPictures}
              onAddImage={handleAddImage}
              onDeleteImage={handleDeleteImage}
            />
          </div>

          <Form.Group className="mb-3">
            <Form.Label>Announcements</Form.Label>
            <Stack gap={3} direction="horizontal" style={{ minHeight: '38px' }}>
              <Form.Check
                type="radio"
                label="Send"
                value="true"
                name="sendAnnouncements"
                checked={object.sendAnnouncements}
                onChange={onAnouncementChange}
              />
              <Form.Check
                type="radio"
                label="Suppress"
                value="false"
                name="sendAnnouncements"
                checked={!object.sendAnnouncements}
                onChange={onAnouncementChange}
              />
            </Stack>
            <Form.Text className="text-muted">
              Controls if notifications will be sent upon object publication.
            </Form.Text>
          </Form.Group>
        </Container>
      </Form>
    </div>
  );
}

export default AdminEditorForm;
