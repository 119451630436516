import { Route } from 'react-router-dom';
import PrivateRoute from '../common/PrivateRoute';
import Wrapper from '../common/Wrapper';
import { navigation, navigationPages } from '../../types/navigation';
import React from 'react';
import VisibilitySchedules from '../VisibilitySchedules/VisibilitySchedules';
import { UserRole } from '../../types/userManagement';
import VisibilitySchedulesEditor from '../VisibilitySchedules/VisibilitySchedulesEditor/VisibilitySchedulesEditor';

const VisibilitySchedulesRoutes = [
  <Route
    key="/visibilitySchedules"
    path="/visibilitySchedules"
    element={
      <PrivateRoute roles={[UserRole.Admin, UserRole.Editor]}>
        <Wrapper {...navigation[navigationPages.visibilitySchedules]}>
          <VisibilitySchedules />
        </Wrapper>
      </PrivateRoute>
    }
  />,
  <Route
    key="/visibilitySchedules/new"
    path="/visibilitySchedules/new"
    element={
      <PrivateRoute roles={[UserRole.Admin, UserRole.Editor]}>
        <VisibilitySchedulesEditor />
      </PrivateRoute>
    }
  />,
  <Route
    key="/visibilitySchedules/:scheduleId"
    path="/visibilitySchedules/:scheduleId"
    element={
      <PrivateRoute roles={[UserRole.Admin, UserRole.Editor]}>
        <VisibilitySchedulesEditor />
      </PrivateRoute>
    }
  />,
];

export default VisibilitySchedulesRoutes;
