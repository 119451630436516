import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

interface IProps {
  children: any;
  text: string;
  [propName: string]: any;
}

function CSTooltip({ children, text, ...props }: IProps) {
  return text ? (
    <OverlayTrigger {...props} overlay={<Tooltip>{text}</Tooltip>}>
      {children}
    </OverlayTrigger>
  ) : (
    children
  );
}

export default CSTooltip;
