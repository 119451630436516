import React from 'react';
import Columns from '../../common/ObjectCollection/CollectionsTableColumns';
import CSTable from '../../common/CSTable/CSTable';
import CollectionsService from '../../../services/Collections/CollectionsService';
import { useNavigate } from 'react-router-dom';

function CollectionsTable() {
  const columns = Columns();
  const navigate = useNavigate();

  const onRowClick = (id: string) => {
    navigate(`${id}`);
  };

  return (
    <CSTable
      dataTestId="collections-list"
      columns={columns}
      loadDataFunction={CollectionsService.getCollections}
      onRowClick={onRowClick}
    />
  );
}

export default CollectionsTable;
