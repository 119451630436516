import React, { useEffect, useState } from 'react';
import { IStatus } from 'types/common';
import { useNavigate, useParams } from 'react-router-dom';
import styles from 'styles/details.module.css';
import { Button } from 'react-bootstrap';
import { showModal } from 'store/action-creators/modal';
import { useDispatch } from 'react-redux';
import { ILocation, initLocation } from 'types/advertisers';
import LocationsService from 'services/Advertisers/LocationsService';
import LocationEditorForm from './LocationEditorForm';
import LocationsEditorMap from './LocationsEditorMap';
import { AlertType } from 'types/alerts';
import HelperService from 'services/HelperService';
import CSTooltip from 'components/common/CSTooltip';
import LoadingWrapper from 'components/common/LoadingWrapper';
import ModalInfoService from 'store/services/ModalInfoService';
import AlertsService from 'store/services/AlertsService';
import PrivateAccess from 'components/common/PrivateAccess';
import { UserRole } from 'types/userManagement';
import { useTypedSelector } from 'hooks/useTypedSelector';
import LocationEditorFormInfo from './LocationEditorFormInfo';
import constants from 'constants/index';
import { useExitConfirmation } from 'hooks/useExitConfirmation';

function LocationEditor() {
  const userRole = useTypedSelector((store) => store.auth.role);
  const [location, setLocation] = useState<ILocation>(initLocation);
  const [status, setStatus] = useState<IStatus>(IStatus.Loading);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [geoString, setGeoString] = useState<string | null>(null);
  const [errorValues, setErrorValues] = useState<string[]>([]);
  let { adId } = useParams<{ adId: string }>();
  let { locationId } = useParams<{ locationId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadAdData = async (id: string) => {
      try {
        return await LocationsService.getLocation(id);
      } catch {
        setStatus(IStatus.Error);
      }
    };
    if (locationId) {
      loadAdData(locationId).then((res) => {
        if (res) {
          setLocation(res);
          setGeoString(res.coordinates);
          setStatus(IStatus.Success);
        }
      });
    } else {
      if (adId) setLocation({ ...initLocation, advertiserId: adId });
      setGeoString(initLocation.coordinates);
      setStatus(IStatus.Success);
    }
  }, [locationId, adId]);

  const handleLocationChange = (name: string, value: any) => {
    setHasChanges(true);
    errorValues.includes(name) && setErrorValues((prevState) => prevState.filter((er) => er !== name));
    setLocation((prevState) => (prevState ? { ...prevState, [name]: value } : initLocation));
  };

  const handleGeoSet = () => {
    setGeoString(location.coordinates);
  };

  const onSaveClick = async () => {
    if (await possibleToSave(location)) {
      ModalInfoService.showModalInfoLoading();
      (locationId ? LocationsService.updateLocation(location) : LocationsService.createLocation(location)).then(
        (res) => {
          if (res) {
            ModalInfoService.closeModalInfo();
            handleBackToAdInfo();
          } else {
            ModalInfoService.showModalInfoError('We have problems saving location');
          }
        },
      );
    }
  };

  const possibleToSave = async (location: ILocation): Promise<boolean> => {
    setErrorValues([]);
    let possible: boolean = true;
    if (!location.name) {
      saveProblemAlert('Customer facing name should be set');
      setErrorValues((prevState) => [...prevState, 'name']);
      possible = false;
    }
    if (!location.address) {
      saveProblemAlert('Customer facing address should be set');
      setErrorValues((prevState) => [...prevState, 'address']);
      possible = false;
    }
    if (location.link && !location.link.match(constants.URL_REGEXP)) {
      saveProblemAlert('Link is not valid');
      setErrorValues((prevState) => [...prevState, 'link']);
      possible = false;
    }
    if (!location.coordinates || !HelperService.isGeoStringValid(location.coordinates, false)) {
      setErrorValues((prevState) => [...prevState, 'coordinates']);
      saveProblemAlert('Coordinates are not valid');
      possible = false;
    }

    return possible;
  };

  const saveProblemAlert = (text: string) => {
    AlertsService.addAlert(text, AlertType.WARNING);
  };

  const handleBackToAdInfo = () => {
    navigate(`/advertisers/${adId}/locations`);
  };

  const onCancelClick = useExitConfirmation({ shouldConfirm: hasChanges, onExit: handleBackToAdInfo });

  const onDeleteClick = async () => {
    dispatch(
      showModal({
        title: 'Delete',
        text: 'This will delete the location. Proceed?',
        primaryAction: handleDelete,
        primaryText: 'Delete location',
        secondaryText: 'Cancel',
      }),
    );
  };

  const handleDelete = async () => {
    if (locationId && (await LocationsService.deleteLocation(locationId))) handleBackToAdInfo();
  };

  return (
    <div className={styles.editorContainer}>
      <div className={`d-flex justify-content-center ${styles.editorContent}`}>
        <LoadingWrapper status={status}>
          <>
            <div className={styles.w50EditorContainer}>
              {userRole === UserRole.Reader ? (
                <LocationEditorFormInfo location={location} />
              ) : (
                <LocationEditorForm
                  location={location}
                  locationChange={handleLocationChange}
                  geoSet={handleGeoSet}
                  errorValues={errorValues}
                />
              )}
            </div>
            <div className="w-50" data-test-id="location-editor-map">
              {geoString !== null && <LocationsEditorMap geoString={geoString} />}
            </div>
          </>
        </LoadingWrapper>
      </div>
      <div className={`${styles.buttonContainer} d-flex justify-content-between`}>
        <div>
          <Button data-test-id="location-editor-cancel" variant="light" size="sm" onClick={onCancelClick}>
            Cancel
          </Button>
        </div>
        <PrivateAccess roles={[UserRole.Admin, UserRole.Editor, UserRole.AdvertisingManager]}>
          {locationId && (
            <CSTooltip
              text={location.dealsCount ? 'Cannot delete the location as it is used in existing deals' : ''}
              placement="top"
            >
              <div>
                <Button
                  data-test-id="location-editor-delete"
                  disabled={!!location.dealsCount}
                  variant="danger"
                  size="sm"
                  onClick={onDeleteClick}
                >
                  Delete
                </Button>
              </div>
            </CSTooltip>
          )}
          <div>
            <Button data-test-id="location-editor-save" variant="primary" size="sm" onClick={onSaveClick}>
              Save
            </Button>
          </div>
        </PrivateAccess>
      </div>
    </div>
  );
}

export default LocationEditor;
